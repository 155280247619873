import React, { useState } from 'react';
import FunctionalButton from "../../../common/FunctionalButton";
import { VerticalAlignBottomOutlined } from '@ant-design/icons';

const TableColumns = ({setVisible, visible, setVisibleData, downloadInvoice, reGeneratedInvoice, isLightTheme}) => {

    const showDetails = (info) => {
        setVisible(true)
        setVisibleData(info)
    };

    const table_columns = [
        {
            title: 'Invoice #',
            dataIndex: 'invoice',
            key: 'invoice',
            width: '150px',
            sorter: (a, b) => a.invoice.localeCompare(b.invoice)
        },
        {
            title: 'Tenant Name',
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            sorter: (a, b) => a.tenant_name.localeCompare(b.tenant_name)
        },
        {
            title: 'Lot',
            dataIndex: 'lot',
            key: 'lot',
            width: '200px',
            sorter: (a, b) => a.lot.localeCompare(b.lot)
        },
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
            width: '200px'
        },
        {
            title: 'Amount ( RM )',
            dataIndex: 'amount',
            key: 'amount',
            width: '150px',
            sorter: (a, b) => a.amount.localeCompare(b.amount)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '150px',
            filters: [
                {
                    text: 'Completed',
                    value: 'Completed'
                },
                {
                  text: 'Processing',
                  value: 'InProgress'
                },
                {
                  text: 'Issued',
                  value: 'Issued'
                }
            ],
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            render: (info) =>{
                if(info == 'InProgress'){
                    return 'Processing'
                } else {
                    return info
                }
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '250px',
            render: (info, record) => {
                return (
                    <div style={{display:'flex'}}>
                        <FunctionalButton 
                            disabled={record.status != 'Completed' ? true : false} 
                            buttonClickFunction={()=>{showDetails(info)}} 
                            custom_button_style={{
                                display: record.status == 'Completed' ? 'block' : 'none', 
                                marginRight:'10px', 
                                width:'200px',
                                background:isLightTheme? "#FFFFFF":"#141414",
                                color: isLightTheme? "#000000":"#ffffff",
                                borderColor: isLightTheme? "#D9D9D9":"#424242",
                                boxShadow: "unset"
                            }} 
                            children='Details'
                        />
                        <FunctionalButton 
                            trigger_loading={true} 
                            disabled={record.status != 'Issues' ? true : false} 
                            buttonClickFunction={()=> reGeneratedInvoice(info)} 
                            custom_button_style={{
                                display: record.status != 'Completed' ? 'block' : 'none', 
                                marginRight:'10px', 
                                width:'200px',
                                background:isLightTheme? "#FFFFFF":"#141414",
                                color: isLightTheme? "#000000":"#ffffff",
                                borderColor: isLightTheme? "#D9D9D9":"#424242",
                                boxShadow: "unset"
                            }} 
                            children='Re-Generate'
                        />
                        <FunctionalButton 
                            disabled={record.status != 'Completed' ? true : false} 
                            buttonClickFunction={()=>{downloadInvoice(info)}} 
                            custom_button_style={{
                                width:'30px',
                                background:isLightTheme? "#FFFFFF":"#141414",
                                color: isLightTheme? "#000000":"#ffffff",
                                borderColor: isLightTheme? "#D9D9D9":"#424242",
                                boxShadow: "unset"
                            }} 
                            children={<VerticalAlignBottomOutlined />}
                        />
                    </div>
                )
            }
        }
    ];

    return table_columns;
};

export default TableColumns;
