import { Button, Divider, Flex, Form } from "antd";
import CustomFormItems from "./CustomFormItems";
import { useEffect } from "react";
import FunctionalButton from "./FunctionalButton";

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};

export default function CustomForm({
  form,
  formProperties,
  handleFinish,
  handleCancel,
  visibleData,
  cancelButtonText = "Cancel",
  confirmButtonText = "Save",
  loading,
}) {

  const onFinish = (value) => {
    if (visibleData) {
      handleFinish(value, visibleData["key"]);
    } else {
      handleFinish(value);
    }
  };

  useEffect(() => {
    if (visibleData) {
      form.setFieldsValue(visibleData);
    }
  }, [visibleData]);

  return (
    <Form {...layout} form={form} labelAlign="left" onFinish={onFinish}>
      <CustomFormItems
        form_properties={formProperties}
        form_item_style={{ marginTop: "10px" }}
      />
      <Divider />
      <Flex
        gap="middle"
        justify="flex-end"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Form.Item>
          <Button
            onClick={handleCancel}
            style={{ background: "#303030", width: "100px" }}
          >
            {cancelButtonText}
          </Button>
        </Form.Item>
        <Form.Item>
          <FunctionalButton
            buttonClickFunction={() => {}}
            custom_button_style={{
              background: "#303030",
              width: "140px",
            }}
            htmlType={true}
            children={confirmButtonText}
            trigger_loading={loading}
          />
        </Form.Item>
      </Flex>
    </Form>
  );
}
