import { Flex } from "antd";
import FunctionalButton from "../../../../common/FunctionalButton";

const TableColumns = ({  setVisibleNotifyData, setVisibleNotify, setEditVisibleData, setEditVisibleModal }) => {
  const showDetails = (info) => {
    setEditVisibleModal(true);
    setEditVisibleData(info);
  };

  const remove_lot = (info) => {
    setVisibleNotify(true);
    setVisibleNotifyData({
      'title': 'Delete Lot',
      'type': 'delete_lot',
      'content': 
        <div style={{ margin: '0px' }}>
          <h3 style={{ margin: '0px', fontWeight:'400', paddingBottom:'10px', fontSize:'17px', borderBottom:'1px solid #303030'}}>Are you sure you want to delete the following lot?</h3>
          <h3 style={{ margin: '0px', fontWeight:'400', marginTop:'10px'}}><strong>Lot: </strong> {info.lot_id}</h3>
        </div>,
      'key': info.key
    });
  };


  const table_columns = [
    {
      title: "Lot ID",
      dataIndex: "lot_id",
      key: "lot_id",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (info, record) => {
        return (
          <Flex gap="middle">
            <FunctionalButton
              buttonClickFunction={() => showDetails(record)}
              custom_button_style={{ width: "150px" }}
              children="Edit"
            />
            <FunctionalButton
              disabled={record.disabled}
              buttonClickFunction={() => {
                remove_lot(record)
              }}             
              custom_button_style={{ width: "150px", background: record.disabled ? "#282828" : 'red' }}
              children="Delete"
            />
          </Flex>
        );
      },
    },
  ];

  return table_columns;
};

export default TableColumns;
