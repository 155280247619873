import { Row, Col } from "antd";
import { ThunderboltFilled } from "@ant-design/icons";
import StatisticCard from "../common/StatisticCard";
import { trendIconType } from "../../functions/common/trendIconType";

export default function StatisticInfo({
    statisticData
    ,isStatisticCardLoading
}) {

    return (
        <Row gutter={[12,12]}>
            {
                statisticData.statistic.map((data, index) => {
                    console.log(data)
                    return (
                        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                            <StatisticCard
                                key={index}
                                isLoading={isStatisticCardLoading}
                                title={data?.title}
                                title_icon={<ThunderboltFilled />}
                                container_name={`pv-statistic-card-${index}`}
                                chartData={data.chart_data}
                                isShowChart={true}
                                chart_color={data.chart_color}
                                main_content_list={[
                                    <h1 style={{margin: 0}}>{data.data?.current?? 'N/A'} {data.data?.current != null ? data.data?.unit : ''}</h1>
                                ]}
                                sub_content_list={[
                                    trendIconType(data.data?.trend),
                                    <div style={{display: data.data?.previous == null ? 'none' : 'block'}}>Last: { data.data?.previous?? ' - '} {data.data?.unit} ({data.data?.percentage?? ' - '}%)</div>
                                ]}
                            />
                        </Col>
                    )
                })
            }
            </Row>
    );
}