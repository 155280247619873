import { useState, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetPendingInvoices() {
    const [fetchWrapper] = useFetch();
    const [pendingInvoices, setPendingInvoices] = useState([])

    const getPendingInvoices = useCallback((filter) => {
        fetchWrapper({
            endpoint_url: "invoice/getPendingInvoices",
            params: filter,
            onSuccess: (fetch_output) => {
                setPendingInvoices(fetch_output.pending_invoices)
            },
        });
    }, [fetchWrapper]);

    return { getPendingInvoices, pendingInvoices };
}

export default useGetPendingInvoices;
