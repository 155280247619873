import { Form } from "antd";
import { IndexContext } from "../../../../context/IndexContext";
import { useEffect, useState, useContext } from "react";
import CustomCard from "../../../common/CustomCard";
import CustomTabs from "../../../common/CustomTabs";
import CustomModal from "../../../common/CustomModal";
import ActiveSitesTable from "./active_sites/ActiveSitesTable";
import InactiveSitesTable from "./inactive_sites/InactiveSitesTable";
import FunctionalButton from "../../../common/FunctionalButton";
import SitesManagementForm from "./SitesManagementForm";

import useDeleteSite from "../../../../functions/Settings/site_management/deleteSite";

export default function SitesManagementCard({
  inactiveSitesLoading,
  activeSitesLoading,
  activeSites,
  inactiveSites,
  tariffOption,
  insertSite,
  insertLoading,
  updateSite,
  updateLoading,
  setSiteLoading
}) {
  const [siteManagementInsertForm] = Form.useForm();
  const [insertVisivle, setInsertVisible] = useState(false);

  const [siteManagementEditForm] = Form.useForm();
  const [editVisible, setEditVisible] = useState(false);
  const [visibleData, setVisibleData] = useState({});
  const { messageApi } = useContext(IndexContext);

  const [sitesManagementQuantity, setSitesManagementQuantity] = useState({
    active_sites: 0,
    inactive_sites: 0,
  });

  function validateSiteInfo(object) {
    var target = true
    // Create an array of error messages for each empty field
    const errors = Object.entries(object)
        .filter(([key, value]) => value === undefined || value === null || value === '')
        .map(([key]) => {
          target = false
          messageApi.open({
            type: "error",
            content: `Field '${key}' cannot be empty`,
          });         
        }
      );
    
      return target
  }

  const handleInsertConfirm = (value) => {
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        insertSite(value).then((data) => {
          if('error' in data){
            console.log('')
          } else {
            handleInsertCancel();
            setSiteLoading(true)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  };
  const handleInsertCancel = () => setInsertVisible(false);

  const handleEditConfirm = (value, key) => {
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        updateSite(value, key).then((data) => {
          if('error' in data){
            console.log('yes')
          } else {
            handleEditCancel();
            setSiteLoading(true)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  };

  const handleEditCancel = () => setEditVisible(false);

  const { deleteSite } = useDeleteSite()
  const [visibleNotify, setVisibleNotify] = useState(false)
  const [visibleNotifyData, setVisibleNotifyData] = useState({
    'title': '',
    'content': '',
    'type': '',
    'key': ''
  })

  const confirmNotify = () => {
    return new Promise((resolve, reject) => {
      deleteSite(visibleNotifyData['key']).then((data) => {
        if('error' in data){
          console.log('')
        } else {
          cancelNotify();
          setSiteLoading(true)
        }
        resolve();
      }).catch((err)=>{
        resolve(err);
      }
    )}
    )
  }

  const cancelNotify = () => setVisibleNotify(false)

  useEffect(() => {
    setSitesManagementQuantity((sitesManagementQuantity) => ({
      ...sitesManagementQuantity,
      active_sites: activeSites.length,
    }));
  }, [activeSites]);

  useEffect(() => {
    setSitesManagementQuantity((sitesManagementQuantity) => ({
      ...sitesManagementQuantity,
      inactive_sites: inactiveSites.length,
    }));
  }, [inactiveSites]);

  return (
    <>
      <CustomCard
        title="Sites Management"
        extra={
          <FunctionalButton
            buttonClickFunction={() => setInsertVisible(true)}
            custom_button_style={{ background: "#141414", width: "200px" }}
            children="Insert New Sites"
          />
        }
        card_style={{ height: "100%" }}
        title_style={{ background: "#303030", padding: "8px 12px" }}
        children_style={{ padding: "0px" }}
        children={
          <CustomTabs
            tab_style={{ background: "#1D1D1D" }}
            tab_children_styles={{
              background: "#141414",
              padding: "0px",
              height: "100%",
            }}
            tabs={[
              {
                title:
                  "Active Sites ( " +
                  sitesManagementQuantity["active_sites"] +
                  " )",
                key: "1",
                content: (
                  <ActiveSitesTable
                    activeSitesLoading={activeSitesLoading}
                    activeSites={activeSites}
                    setVisible={setEditVisible}
                    visible={editVisible}
                    setVisibleData={setVisibleData}
                  />
                ),
              },
              {
                title:
                  "Inactive Sites ( " +
                  sitesManagementQuantity["inactive_sites"] +
                  " )",
                key: "2",
                content: (
                  <InactiveSitesTable
                    setVisibleNotify={setVisibleNotify}
                    setVisibleNotifyData={setVisibleNotifyData}
                    inactiveSitesLoading={inactiveSitesLoading}
                    inactiveSites={inactiveSites}
                    setVisible={setEditVisible}
                    visible={editVisible}
                    setVisibleData={setVisibleData}
                  />
                ),
              },
            ]}
          />
        }
      />
      <CustomModal
        title={
          <h4 style={{margin:'0px', fontWeight:'400'}}>{visibleNotifyData.title}</h4>
        }
        visible={visibleNotify}
        content={visibleNotifyData.content}
        content_style={{
          padding: '16px 16px',
          borderBottom: '1px solid #303030'
        }}
        footer={
          <div>
            <FunctionalButton
              buttonClickFunction={() => cancelNotify()}
              custom_button_style={{ width: "150px", marginRight:'10px' }}
              children="Cancel"
            />
            <FunctionalButton
              buttonClickFunction={() => confirmNotify()}
              custom_button_style={{ width: "150px" }}
              children="Confirm"
            />
          </div>
        }
      />
      <SitesManagementForm
        form={siteManagementInsertForm}
        visible={insertVisivle}
        modalTitle={"Insert Sites"}
        handleFinish={handleInsertConfirm}
        handleCancel={handleInsertCancel}
        tariffOption={tariffOption}
        loading={insertLoading}
      />

      <SitesManagementForm
        form={siteManagementEditForm}
        visible={editVisible}
        modalTitle={"Edit Sites"}
        handleFinish={handleEditConfirm}
        handleCancel={handleEditCancel}
        visibleData={visibleData}
        tariffOption={tariffOption}
        loading={updateLoading}
      />
    </>
  );
}
