import { Flex, Radio, Select, Row, Col } from "antd";
import { useState, useEffect, useRef, useContext } from "react";
import { IndexContext } from '../../../context/IndexContext';
import CustomCard from "../../common/CustomCard";
import "../../../styles/dashboard/DashboardPage.css"
import MeterTrendingChart from "./MeterTrendingChart";
import DeviceListTabs from "./DeviceListTabs";
import useFetch from "../../../hooks/useFetch";

const radio_options = [
    {
        label: 'Day',
        value: 'Day',
    },
    {
        label: 'Month',
        value: 'Month',
    },
    {
        label: 'Year',
        value: 'Year',
    },
]

export default function DeviceListCard({
    deviceListData
    ,isDeviceListTabsLoading
    ,isShowMeterTrendingChart
    ,dateRangeSelection
    ,setIsShowMeterTrendingChart
    ,selectedDevice
    ,setSelectedDevice
}) {
    const tableContainerRef = useRef();
    const subTitleRef = useRef();
    const { isLightTheme } = useContext(IndexContext);
    const [fetchWrapper] = useFetch();

    const [height, setHeight] = useState("unset");
    const [tableHeightOffset, setTableHeightOffset] = useState(155);
    const [trendingHeightOffset, setTrendingHeightOffset] = useState(0);
    const [subtitleMinHeight, setSubtitleMinHeight] = useState(0);
    const [parameterSelection, setParameterSelection] = useState();
    const [isMeterTrendingChartLoading, setIsMeterTrendingChartLoading] = useState(false);
    const [trendingData, setTrendingData] = useState({});
    const [parameterList, setParameterList] = useState([]);
    const [groupBy, setGroupBy] = useState();
    const [trendingInterval, setTrendingInterval] = useState();

    function getParameterList(){
        fetchWrapper({
            endpoint_url: "dashboard/getParameterList",
            method: "GET",
            set_content_type_header: false,
            onSuccess: (fetch_output) => {
              if("parameter" in fetch_output){
                setParameterList(fetch_output.parameter)
              }
            },
          })
    }

    function getTrendingData(){
        fetchWrapper({
            endpoint_url: "dashboard/getTrending", 
            params: {
                ...dateRangeSelection, 
                logger_name: selectedDevice.logger_name,
                parameter: parameterSelection,
                group_by: groupBy
            },
            onSuccess: (fetch_output) => {
                if(!("error" in fetch_output)){
                    setTrendingData(fetch_output)
                    setIsMeterTrendingChartLoading(false)
                    setIsShowMeterTrendingChart(true)
                }else{
                    setIsMeterTrendingChartLoading(false)
                    setIsShowMeterTrendingChart(false)
                }
            }
        })
    }

    function containsValue(obj, target) {
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Recursively search in the nested object
                if (containsValue(obj[key], target)) {
                    return true;
                }
            } else if (obj[key] === target) {
                return true;
            }
        }
        return false;
    }

    useEffect(()=>{

        getParameterList()
        setGroupBy('Month')

        function handleResize() {
            const newHeight = window.innerWidth >= 1200 ? "unset" : '400px';
            const newTableHeightOffset = window.innerWidth >= 1200 ? 155:165;
            const newTrendingHeightOffset = subTitleRef?.current?.offsetHeight - 49;
            const newSubtitleMinHeight = window.innerWidth >= 1200 ? "unset" : `calc(${newHeight} - ${newTrendingHeightOffset}px)`
            setHeight(newHeight); 
            setTableHeightOffset(newTableHeightOffset)
            setTrendingHeightOffset(newTrendingHeightOffset)
            setSubtitleMinHeight(newSubtitleMinHeight)
        }

        handleResize(); //Set initial height
        window.addEventListener('resize', handleResize);

        return ()=> {
            window.removeEventListener('resize', handleResize);
            // clearInterval(trendingInterval);
        }
    }, [])

    useEffect(()=>{
        if(Object.keys(deviceListData).length > 0 && deviceListData?.data.length >0){
            let stop_bit = false
            // Check is there any device selected, or does selected device in the device list
            if(selectedDevice == null || containsValue(deviceListData, selectedDevice.device_name) == false){
                //Check which one have trending data and then set it as default
                for(const device_group of deviceListData.data){
                    for(const data of device_group.table_data){
                        if(data.total_consumption != null){
                            stop_bit = true
                            if(selectedDevice.device_name != data.device_name && selectedDevice.logger_name != data.logger_name){
                                setSelectedDevice({
                                    'device_name': data.device_name,
                                    'logger_name': data.logger_name
                                })
                            }
                            break;
                        }
                    }
                    if(stop_bit){
                        break;
                    }
                }
            }
        }
    },[deviceListData])

    useEffect(()=>{
        if(parameterList.length > 0){
            setParameterSelection(parameterList[0].Key)
        }
    },[parameterList])

    useEffect(()=>{
        if(Object.keys(selectedDevice).length > 0 && parameterSelection != null && groupBy != null){
            // if(trendingInterval){
            //     clearInterval(trendingInterval);
            // }

            //Get Trend
            setIsMeterTrendingChartLoading(true)
            getTrendingData()
        }
    },[selectedDevice, parameterSelection, groupBy])

    return (
        <div
            style={{height: '100%'}}
            ref={tableContainerRef}>
                <CustomCard
                    card_style={{ height: "100%" }}
                    card_body_style={{height: "calc(100% - 46px)"}}
                    children_style={{height: "calc(50% - 24.5px)", padding: 0, minHeight: height}}
                    sub_titles_wrapper_style={{height: `calc(50% + 24.5px)`}}
                    sub_title_style={{
                        borderRadius: 0, 
                        borderTop:isLightTheme? "1px solid #F0F0F0":"1px solid #303030", 
                    }}
                    sub_title_children_style={{height: `calc(100% - 49px - ${trendingHeightOffset*2}px)`, minHeight: subtitleMinHeight}}
                    title={"Device Lists"}
                    children={
                        <DeviceListTabs
                            devicesData={deviceListData}
                            tableContainerRef={tableContainerRef}
                            tableHeightOffset={tableHeightOffset}
                            selectedDevice={selectedDevice}
                            setSelectedDevice={setSelectedDevice}
                            isDeviceListTabsLoading={isDeviceListTabsLoading}
                        />
                    }
                    subtitles={
                        [
                            {
                                title_ref: subTitleRef,
                                title_style: {width: "100%"},
                                title:  <Row justify="space-between" align="middle" gutter={[0,10]} style={{width: "100%"}}>
                                            <Col xs={24} sm={24} md={8} lg={9} xl={6} xxl={12}>
                                                {`Trending (${selectedDevice?.device_name?? '-'})`}
                                            </Col>
                                            <Col xs={24} sm={24} md={16} lg={15} xl={16} xxl={12}>
                                                <Row justify="end" align="middle" gutter={[10,10]}>
                                                    <Col>
                                                        <Flex gap={"small"}>
                                                            Parameter: 
                                                            <Select
                                                                size="small"
                                                                style={{width: "150px"}}
                                                                value={parameterSelection}
                                                                options={parameterList.map((x)=>{return{value: x.Key, label: x.Name}})}
                                                                onChange={(value)=>{setParameterSelection(value)}}
                                                            />
                                                        </Flex>
                                                    </Col>
                                                    <Col>
                                                        <Radio.Group 
                                                            options={radio_options} 
                                                            value={groupBy}
                                                            onChange={(value)=>{setGroupBy(value.target.value)}}
                                                            optionType="button" 
                                                            size="small"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>,
                                children:   <MeterTrendingChart 
                                                containerName="meter-trending" 
                                                data={trendingData?.data} 
                                                parameterSelection={parameterSelection} 
                                                isLoading={isMeterTrendingChartLoading}
                                                isShowChart={isShowMeterTrendingChart}
                                            />
                            }
                        ]
                    }
                />
        </div>
    );
}