import { Form } from "antd";
import { IndexContext } from "../../../../context/IndexContext";
import { useEffect, useState, useContext } from "react";
import CustomCard from "../../../common/CustomCard";
import CustomTabs from "../../../common/CustomTabs";
import CustomModal from "../../../common/CustomModal";
import ActiveLotsTable from "./active_lots/ActiveLotsTable";
import InactiveLotsTable from "./inactive_lots/InactiveLotsTable";
import UnconfiguredLotsTable from "./uncofigured_lots/UncofiguredLotsTable";
import InsertLotModal from "./InsertLotModal";
import PairingMeterModal from "./pairingMeterModal";
import PairingTenantModal from "./PairingTenantModal";
import MoveOutModal from "./MoveOutModal";
import FunctionalButton from "../../../common/FunctionalButton";

import useGetActiveLots from "../../../../functions/Settings/lot_management/getActiveLots";
import useGetVacantLots from "../../../../functions/Settings/lot_management/getVacantLots";
import useGetUnconfiguredLots from "../../../../functions/Settings/lot_management/getUnconfiguredLots";
import useInsertLot from "../../../../functions/Settings/lot_management/insertLot";
import useUpdateLot from "../../../../functions/Settings/lot_management/updateLot";

import useGetVacantLotOptions from "../../../../functions/Settings/lot_management/getVacantLotOptions";
import useGetVacantMeterOptions from "../../../../functions/Settings/lot_management/getVacantMeterOptions";

import useGetVacantTenantOption from "../../../../functions/Settings/lot_management/getVacantTenantOption";
import useUpdateTenantPairing from "../../../../functions/Settings/lot_management/updateTenantPairing";
import useUpdateUnTenantPairing from "../../../../functions/Settings/lot_management/updateUnTenantPairing";
import useUpdatePairingMeter from "../../../../functions/Settings/lot_management/updatePairingMeter";
import useUpdateMoveOut from "../../../../functions/Settings/lot_management/updateMoveOut";
import useUpdateMoveOutCancel from "../../../../functions/Settings/lot_management/updateMoveOutCancel";
import useUpdateRemoveTenant from "../../../../functions/Settings/lot_management/updateRemoveTenant";
import useUpdateRemoveLot from "../../../../functions/Settings/lot_management/updateRemoveLot";

export default function LotsManagementCard({refresh, setRefresh, filter, siteOption}) {  
  const { messageApi } = useContext(IndexContext);

  // const [lotsManagementInsertForm] = Form.useForm();
  const [lotSettingForm] = Form.useForm();
  const [MoveOutSettingForm] = Form.useForm();
  const [PairingTenantSettingForm] = Form.useForm();
  const [editLotSettingForm] = Form.useForm();
  const [pairingMeterSettingForm] = Form.useForm();
  
  const [insertVisible, setInsertVisible] = useState(false);
  const [visibleData, setVisibleData] = useState({ lot_id_data: [] });
  const [insertVisibleModal, setInsertVisibleModal] = useState(false)

  const [editVisibleModal, setEditVisibleModal] = useState(false)
  const [editVisibleData, setEditVisibleData] = useState({})
  
  const [pairingTenantVisibleModal, setPairingTenantVisibleModal] = useState(false)
  const [pairingTenantVisibleData, setPairingTenantVisibleData] = useState({})

  // Lot Management
  const { getActiveLots, activeLots, activeLotsLoading } = useGetActiveLots();
  const { getVacantLots, vacantLots, vacantLotsLoading } = useGetVacantLots()
  const { getUnconfiguredLots, unconfiguredLots, unconfiguredLoading } = useGetUnconfiguredLots();
  const { insertLot } = useInsertLot();
  const { updateLot } = useUpdateLot();
  
  // Tenant Management
  const { getVacantTenantOption, vacantTenantOption } = useGetVacantTenantOption();
  const { updateTenantPairing } = useUpdateTenantPairing();
  const { updateUnTenantPairing } = useUpdateUnTenantPairing();
  const { updateRemoveTenant } = useUpdateRemoveTenant();
  const { updateRemoveLot } = useUpdateRemoveLot();
  

  const [lotsManagementQuantity, setLotsManagementQuantity] = useState({
    active_lots: 3,
    inactive_lots: 3,
    unconfigured_lots: 3,
  });

  const [activeLotsData, setActiveLotsData] = useState([]);
  const [inacitveLotsData, setInactiveLotsData] = useState([]);
  const [unconfiguredLotsData, setUnconfiguredLotsData] = useState([]);

  function validateSiteInfo(object) {
    var target = true
    // Create an array of error messages for each empty field
    const errors = Object.entries(object)
        .filter(([key, value]) => value === undefined || value === null || value === '')
        .map(([key]) => {
          target = false
          messageApi.open({
            type: "error",
            content: `Field '${key}' cannot be empty`,
          });         
        }
      );
    
      return target
  }

  // Insert New Lot
  const handleInsertLotConfirm = (value, key) => {
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        insertLot(value, filter).then((data) => {
          if('error' in data){
            console.log('')
          } else {
            setLotsManagementQuantity(lotsManagementQuantity => ({
              ...lotsManagementQuantity,
              unconfigured_lots: 0,
            }));
            setUnconfiguredLotsData([])
            getVacantLotOptions(filter)
            handleInsertLotCancel();
            getUnconfiguredLots(filter)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  }

  const handleInsertLotCancel = () => setInsertVisibleModal(false);

  // Edit New Lot
  const handleEditLotConfirm = (value, key) => {
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        updateLot(value, key).then((data) => {
          if('error' in data){
            console.log('')
          } else {
            setLotsManagementQuantity(lotsManagementQuantity => ({
              ...lotsManagementQuantity,
              unconfigured_lots: 0,
            }));
            setUnconfiguredLotsData([])
            handleEditLotCancel();
            getUnconfiguredLots(filter)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  }
  
  const handleEditLotCancel = () => setEditVisibleModal(false);
  
  
  // Pairing Tenant 

  const handlePairingTenantConfirm = (value, key) => {
    console.log(value)
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        updateTenantPairing(value, pairingTenantVisibleData['key']).then((data) => {
          if('error' in data){
            console.log('')
          } else {
            setLotsManagementQuantity(lotsManagementQuantity => ({
              ...lotsManagementQuantity,
              active_lots: 0,
              inactive_lots: 0
            }));
            setInactiveLotsData([])
            setActiveLotsData([])
            handlePairingTenantCancel();
            getVacantLots(filter)
            getVacantTenantOption(filter)
            getActiveLots(filter, setRefresh)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  }
  
  const handlePairingTenantCancel = () => setPairingTenantVisibleModal(false);


  //  Move Out Early
  const [moveOutModal, setMoveOutModal] = useState(false)
  const [moveOutData, setMoveOutData] = useState({})
  const { updateMoveOut } = useUpdateMoveOut();
  const { updateMoveOutCancel } = useUpdateMoveOutCancel();

    const handleMoveOutConfirm = (value, key) => {
      if(validateSiteInfo(value)){
        return new Promise((resolve, reject) => {
          updateMoveOut(value, moveOutData['key']).then((data) => {
            if('error' in data){
              console.log('')
            } else {
              setLotsManagementQuantity(lotsManagementQuantity => ({
                ...lotsManagementQuantity,
                active_lots: 0,
              }));
              setActiveLotsData([])
              handleMoveOutCancel();
              getActiveLots(filter, setRefresh)
            }
            resolve();
          }).catch((err)=>{
            resolve(err);
          }
        )}
        )
      }
    }
    
    const handleMoveOutCancel = () => setMoveOutModal(false);

  // Pairing Meter 
  const [updatePairingMeterModal, setUpdatePairingMeterModal] = useState(false)
  const { getVacantLotOptions, vacantLotOptions } = useGetVacantLotOptions();
  const { getVacantMeterOptions, vacantMeterOptions } = useGetVacantMeterOptions();
  const { updatePairingMeter } = useUpdatePairingMeter();

  const handlePairingMeterConfirm = (value, key) => {
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        updatePairingMeter(value, filter).then((data) => {
          if('error' in data){
            console.log('')
          } else {
            setLotsManagementQuantity(lotsManagementQuantity => ({
              ...lotsManagementQuantity,
              unconfigured_lots: 0,
              inactive_lots: 0
            }));
            setInactiveLotsData([])
            setUnconfiguredLotsData([])
            handlePairingMeterCancel();
            getUnconfiguredLots(filter)
            getVacantLotOptions(filter)
            getVacantMeterOptions(filter)
            getVacantLots(filter)
            getVacantTenantOption(filter)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  }
  
  const handlePairingMeterCancel = () => setUpdatePairingMeterModal(false);

  const [visibleNotify, setVisibleNotify] = useState(false)
  const [visibleNotifyData, setVisibleNotifyData] = useState({
    'title': '',
    'content': '',
    'type': '',
    'key': ''
  })

  const confirmNotify = () => {
    if(visibleNotifyData.type == 'pairing_tenant'){
      return new Promise((resolve, reject) => {
        updateUnTenantPairing(visibleNotifyData['key']).then((data) => {
          if('error' in data){
            console.log('')
          } else {
            setInactiveLotsData([])
            setUnconfiguredLotsData([])
            cancelNotify();
            setLotsManagementQuantity(lotsManagementQuantity => ({
              ...lotsManagementQuantity,
              inactive_lots: 0,
              unconfigured_lots: 0
            }));
            getUnconfiguredLots(filter)
            getVacantLots(filter)
            getVacantTenantOption(filter)
            getVacantLotOptions(filter)            
            getVacantMeterOptions(filter)            
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    } else if(visibleNotifyData.type == 'move_out_cancel'){
      return new Promise((resolve, reject) => {
        updateMoveOutCancel(visibleNotifyData['key']).then((data) => {
          if('error' in data){
            console.log('')
          } else {
            setActiveLotsData([])
            setLotsManagementQuantity(lotsManagementQuantity => ({
              ...lotsManagementQuantity,
              active_lots: 0,
            }));
            cancelNotify();
            getActiveLots(filter, setRefresh)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    } else if (visibleNotifyData.type == 'unpairing_tenant'){
      return new Promise((resolve, reject) => {
        updateRemoveTenant(visibleNotifyData['key']).then((data) => {
          if('error' in data){
            console.log('')
          } else {
            setLotsManagementQuantity(lotsManagementQuantity => ({
              ...lotsManagementQuantity,
              active_lots: 0,
              inactive_lots: 0
            }));
            setActiveLotsData([])
            setInactiveLotsData([])
            cancelNotify();
            getVacantLots(filter)
            getActiveLots(filter, setRefresh)
            getVacantTenantOption(filter)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    } else if (visibleNotifyData.type == 'delete_lot'){
      return new Promise((resolve, reject) => {
        updateRemoveLot(visibleNotifyData['key']).then((data) => {
          if('error' in data){
            console.log('')
          } else {
            setLotsManagementQuantity(lotsManagementQuantity => ({
              ...lotsManagementQuantity,
              unconfigured_lots: 0
            }));
            cancelNotify();
            getVacantLotOptions(filter)            
            setUnconfiguredLotsData([])
            getUnconfiguredLots(filter)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }  }

  const cancelNotify = () => setVisibleNotify(false);

  useEffect(()=>{
    if(editVisibleModal == false){
      lotSettingForm.resetFields();
    }
    },[editVisibleModal])


    useEffect(()=>{
      if(insertVisibleModal == false){
        MoveOutSettingForm.resetFields();
      }
    },[ insertVisibleModal])
  
    useEffect(()=>{
      if(pairingTenantVisibleModal == false){
        PairingTenantSettingForm.resetFields();
      }
    },[ pairingTenantVisibleModal])
  
    useEffect(()=>{
      if(editVisibleModal == false ){
        editLotSettingForm.resetFields();
      }
    },[ editVisibleModal])
  
    useEffect(()=>{
      if(updatePairingMeterModal == false){
        pairingMeterSettingForm.resetFields();
      }
    },[updatePairingMeterModal])
  
  useEffect(() => {
    if (editVisibleData) {
      lotSettingForm.setFieldsValue(editVisibleData);
    }
  }, [editVisibleData]);

  useEffect(()=>{
    console.log(unconfiguredLots)
    console.log('testing')
    setLotsManagementQuantity(lotsManagementQuantity => ({
      ...lotsManagementQuantity,
      unconfigured_lots: unconfiguredLots.length
    }));
    setUnconfiguredLotsData(unconfiguredLots)
  },[unconfiguredLots])

  useEffect(()=>{
    setLotsManagementQuantity(lotsManagementQuantity => ({
      ...lotsManagementQuantity,
      inactive_lots: vacantLots.length
    }));
    setInactiveLotsData(vacantLots)
  },[vacantLots])

  useEffect(()=>{
    setLotsManagementQuantity(lotsManagementQuantity => ({
      ...lotsManagementQuantity,
      active_lots: activeLots.length
    }));
    setActiveLotsData(activeLots)
  },[activeLots])

  useEffect(()=>{
    setLotsManagementQuantity({
      active_lots: 0,
      inactive_lots: 0,
      unconfigured_lots: 0,
    })
    // setActiveLotsData([])
    // setInactiveLotsData([])
    // setUnconfiguredLotsData([])
    getActiveLots(filter, setRefresh)
    getVacantLots(filter)
    getVacantTenantOption(filter)
    getVacantLotOptions(filter)
    getUnconfiguredLots(filter)
    getVacantMeterOptions(filter)
  },[refresh])

  return (
    <>
      <CustomCard
        title="Lots Management"
        extra={
          <div style={{display:'flex'}}>
            <FunctionalButton
              buttonClickFunction={() => setUpdatePairingMeterModal(true)}
              custom_button_style={{ background: "#141414", width: "200px" , marginRight:'10px'}}
              children="Pairing Meter"
            />
            <FunctionalButton
              buttonClickFunction={() => setInsertVisibleModal(true)}
              custom_button_style={{ background: "#141414", width: "200px" }}
              children="Insert New Lots"
            />
          </div>
        }
        card_style={{ height: "100%" }}
        title_style={{ background: "#303030", padding: "8px 12px" }}
        children_style={{ padding: "0px" }}
        children={
          <CustomTabs
            tab_style={{ background: "#1D1D1D" }}
            tab_children_styles={{
              background: "#141414",
              padding: "0px",
              height: "100%",
            }}
            tabs={[
              {
                title:
                  "Active Lots ( " +
                  lotsManagementQuantity["active_lots"] +
                  " )",
                key: "1",
                content: <ActiveLotsTable setVisibleNotifyData={setVisibleNotifyData} setVisibleNotify={setVisibleNotify} setMoveOutModal={setMoveOutModal} setMoveOutData={setMoveOutData} activeLotsLoading={activeLotsLoading} activeLotsData={activeLotsData} />,
              },
              {
                title:
                  "Vacant Lots ( " +
                  lotsManagementQuantity["inactive_lots"] +
                  " )",
                key: "2",
                content: (
                  <InactiveLotsTable setVisibleNotifyData={setVisibleNotifyData} setVisibleNotify={setVisibleNotify} setPairingTenantVisibleData={setPairingTenantVisibleData} setPairingTenantVisibleModal={setPairingTenantVisibleModal} vacantLotsLoading={vacantLotsLoading} inactiveLotsData={inacitveLotsData} />
                ),
              },
              {
                title:
                  "Unconfigured Lots ( " +
                  lotsManagementQuantity["unconfigured_lots"] +
                  " )",
                key: "3",
                content: (
                  <UnconfiguredLotsTable
                    setVisibleNotify={setVisibleNotify}
                    setVisibleNotifyData={setVisibleNotifyData}
                    setEditVisibleModal={setEditVisibleModal}
                    setEditVisibleData={setEditVisibleData}
                    unconfiguredLotsData={unconfiguredLotsData}
                    unconfiguredLoading={unconfiguredLoading}
                  />
                ),
              },
            ]}
          />
        }
      />
      <MoveOutModal
        form={MoveOutSettingForm}
        visible={moveOutModal}
        modalTitle={"Move Out Setting"}
        handleFinish={handleMoveOutConfirm}
        handleCancel={handleMoveOutCancel}
      />
      <PairingTenantModal
        vacantTenantOption={vacantTenantOption}
        form={PairingTenantSettingForm}
        visible={pairingTenantVisibleModal}
        modalTitle={"Pairing Tenant"}
        handleFinish={handlePairingTenantConfirm}
        handleCancel={handlePairingTenantCancel}
        visibleData={visibleData}
      />

      <InsertLotModal
        siteOption={siteOption}
        form={lotSettingForm}
        visible={insertVisibleModal}
        modalTitle={"Insert Lot"}
        handleFinish={handleInsertLotConfirm}
        handleCancel={handleInsertLotCancel}
        visibleData={visibleData}
      />
      <InsertLotModal
        siteOption={siteOption}
        form={editLotSettingForm}
        visible={editVisibleModal}
        visibleData={editVisibleData}
        modalTitle={"Edit Lot"}
        handleFinish={handleEditLotConfirm}
        handleCancel={handleEditLotCancel}
      />
      <PairingMeterModal
        form={pairingMeterSettingForm}
        visible={updatePairingMeterModal}
        modalTitle={"Pairing Meter"}
        handleFinish={handlePairingMeterConfirm}
        handleCancel={handlePairingMeterCancel}
        vacantLotOptions={vacantLotOptions}
        vacantMeterOptions={vacantMeterOptions}
      />
      <CustomModal
        title={
          <h4 style={{margin:'0px', fontWeight:'400'}}>{visibleNotifyData.title}</h4>
        }
        visible={visibleNotify}
        content={visibleNotifyData.content}
        content_style={{
          padding: '16px 16px',
          borderBottom: '1px solid #303030'
        }}
        footer={
          <div>
            <FunctionalButton
              buttonClickFunction={() => cancelNotify()}
              custom_button_style={{ width: "150px", marginRight:'10px' }}
              children="Cancel"
            />
            <FunctionalButton
              buttonClickFunction={() => confirmNotify()}
              custom_button_style={{ width: "150px" }}
              children="Confirm"
            />
          </div>
        }
      />
    </>
  );
}
