import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetTariffOption() {
  const [fetchWrapper] = useFetch();
  const [tariffOption, setTariffOption] = useState([]);

  const getTariffOption = useCallback(() => {
    fetchWrapper({
      method: "GET",
      endpoint_url: "settings/getTariffOption",
      set_content_type_header: false,
      onSuccess: (fetch_output) => {
        setTariffOption(fetch_output.tariff_option);
      },
    });
  }, [fetchWrapper]);

  return { getTariffOption, tariffOption };
}

export default useGetTariffOption;
