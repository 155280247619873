import { Flex, Row, Col, Skeleton } from "antd"
import { useContext, useEffect, useState } from 'react';
import { IndexContext } from '../../../context/IndexContext';
import CustomCard from "../../common/CustomCard";
import CustomLegend from "../CustomLegend";
import PieChart from "./PieChart";

export default function EnergyConsumptionDistributionCard({
    energyConsumptionDistributionData
    ,isEnergyConsumptionDistributionCardLoading
    ,displayUnit
}) {
    const { isLightTheme } = useContext(IndexContext);
    const [height, setHeight] = useState("calc(100% - 46px)")

    useEffect(()=>{

        function handleResize() {
            const newHeight = window.innerWidth >= 1200 ? "calc(100% - 46px)" : '400px';
            setHeight(newHeight); 
        }

        handleResize(); //Set initial height
        window.addEventListener('resize', handleResize);

        return ()=> window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <CustomCard
            card_style={{height: "100%"}}
            card_body_style={{height: height}}
            children_style={{height: "50.8%", padding: 0}}
            sub_title_style={{display: "none", height: 0}}  
            sub_title_children_style={{height: "100%",display: "grid", borderTop: '1px', borderTopColor:isLightTheme? "#F0F0F0":"#303030", borderTopStyle: "solid"}}
            sub_titles_wrapper_style={{height: "49.2%"}}
            title={"Energy Consumption Distribution"}
            children={
                <PieChart
                    containerName={"energy-consumption-distribution-pie-chart"}
                    data={isEnergyConsumptionDistributionCardLoading? []:energyConsumptionDistributionData}
                    unit={displayUnit}
                />
            }
            subtitles={
                [
                    {
                        children:   <Skeleton loading={isEnergyConsumptionDistributionCardLoading} paragraph={{rows: 3}} active>
                                        <Row gutter={[12, 12]} style={{overflow: "auto", height: "100%"}}>
                                            {
                                                energyConsumptionDistributionData?.map((data)=>{
                                                    return (
                                                        <Col span={24}>
                                                            <Flex align="center" gap={"small"}>
                                                                <CustomLegend color={data?.color}/>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        {data?.display_name}: {data?.total_consumption_str?? '-'} {displayUnit} ({data?.percentage?? '-'} %)
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        Price: RM {data?.total_price?? 0}
                                                                    </Col>
                                                                </Row>
                                                            </Flex>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Skeleton>      
                    }
                ]
            }
        />
    );
}