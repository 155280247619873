import DataTable from "../../../../common/DataTable";
import table_column from "./TableColumn";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useState, useEffect } from "react";

export default function CheckInvoiceTypeTable({
  invoiceTypeData,
  setVisible,
  setVisibleData,
  setVisibleNotifyData, 
  setVisibleNotify
}) {
  const columns = table_column({ setVisibleNotifyData, setVisibleNotify, setVisible, setVisibleData });
  
  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);

  useEffect(() => {
      const updateAvailableHeight = () => {
          setAvailableHeight(window.innerHeight - 280);
      };

      updateAvailableHeight();
      window.addEventListener('resize', updateAvailableHeight);
      return () => {
          window.removeEventListener('resize', updateAvailableHeight);
      };

  }, []);
  
  return (
    <>
      {
        invoiceTypeData.length == 0 ? 
        <div style={{height:availableHeight, display:'flex', justifyContent:'center', alignItems:'center'}}>
          <Spin
            wrapperClassName="device-list-loading"
            indicator={<LoadingOutlined spin />}
            spinning={invoiceTypeData.length == 0 ? true : false}
            size="large"
          ></Spin>        
        </div>
        : 
        <DataTable
          temp_table_height={620}
          pagination_style={{ margin: "10px" }}
          table_column={columns}
          table_data={invoiceTypeData}
        />
      }
    </>
  );
}
