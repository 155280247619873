import { Typography } from "antd";

const { Text } = Typography;

const TableColumns = (filters) => {
  const table_columns = [
    {
      title: "Tenant Name",
      dataIndex: "tenant_name",
      key: "tenant_name",
      ellipsis: true,
      sorter: (a, b) => a.tenant_name.localeCompare(b.tenant_name),
    },
    {
      title: "PIC Name",
      dataIndex: "pic_name",
      key: "pic_name",
      ellipsis: true,
      sorter: (a, b) => a.pic_name.localeCompare(b.pic_name)
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => a.phone.localeCompare(b.phone)
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
      sorter: (a, b) => a.account_number.localeCompare(b.account_number)
    },
    {
      title: "Tariff Type",
      dataIndex: "tariff_type",
      key: "tariff_type",
      filters: filters?.tariff_type.map((x)=> {return{text: x, value: x}}),
      onFilter: (value, record) => record.tariff_type.indexOf(value) === 0,
    },
    {
      title: "Rent Status",
      dataIndex: "rent_status",
      key: "rent_status",
      render: (info) => {
        return info == "Ongoing Lease" ? (
          <Text type="success">{info}</Text>
        ) : (
          info == "Past Tenant" ? (
            <Text type="warning">{info}</Text>
          ) : (
            <Text>{info}</Text>
          )
        );
      },
      filters: filters?.rent_status.map((x)=> {return{text: x, value: x}}),
      onFilter: (value, record) => record.rent_status.indexOf(value) === 0,
    },
    {
      title: "Rent Start Time",
      dataIndex: "rent_start_time",
      key: "rent_start_time",
      ellipsis: true,
      sorter: (a, b) => a.rent_start_time.localeCompare(b.rent_start_time)
    },
  ];

  return table_columns;
};

export default TableColumns;
