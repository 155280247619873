import { Col, Row } from "antd";
import SitesManagementCard from "./right_card_components/sites_management/SitesManagementCard";
import { useEffect, useState } from "react";
import LotsManagementCard from "./right_card_components/lots_management/LotsManagementCard";
import MetersManagementCard from "./right_card_components/meters_management/MetersManagementCard";
import TenantsManagementCard from "./right_card_components/tenants_management/TenantsManagementCard";
import CheckMeterPairingCard from "./right_card_components/check_meter_paring/CheckMeterPairingCard";
import TariffsManagementCard from "./right_card_components/tariffs_management/TariffsManagementCard";
import CheckInvoiceTypeCard from "./right_card_components/check_invoice_type/CheckInvoiceTypeCard";
import useGetActiveSites from "../../functions/Settings/site_management/getActiveSites";
import useGetInactiveSites from "../../functions/Settings/site_management/getInactiveSites";
import useGetTariffOption from "../../functions/Settings/site_management/getTariffOption";
import useGetSiteOption from "../../functions/Settings/site_management/getSiteOption";
import useInsertSite from "../../functions/Settings/site_management/insertSite";
import useUpdateSite from "../../functions/Settings/site_management/updateSite";

function RightCard({ settingTree, refresh, setRefresh, filter }) {
  const [height, setHeight] = useState("auto");

  const { getActiveSites, activeSites, setActiveSites, activeSitesLoading } = useGetActiveSites();
  const { getInactiveSites, inactiveSites, setInactiveSites, inactiveSitesLoading } = useGetInactiveSites();
  const { getTariffOption, tariffOption } = useGetTariffOption();
  const { getSiteOption, siteOption } = useGetSiteOption();
  const { insertSite, insertSiteLoading } = useInsertSite();
  const { updateSite, updateSiteLoading } = useUpdateSite();

  const [siteLoading, setSiteLoading] = useState(true)

  const contentList = {
    site_management: (
      <SitesManagementCard
        setSiteLoading={setSiteLoading}
        inactiveSitesLoading={inactiveSitesLoading}
        activeSitesLoading={activeSitesLoading}
        activeSites={activeSites}
        inactiveSites={inactiveSites}
        tariffOption={tariffOption}
        insertSite={insertSite}
        insertLoading={insertSiteLoading}
        updateSite={updateSite}
        updateLoading={updateSiteLoading}
      />
    ),
    lots_management: <LotsManagementCard refresh={refresh} setRefresh={setRefresh} filter={filter} siteOption={siteOption} />,
    meters_management: <MetersManagementCard refresh={refresh} setRefresh={setRefresh} filter={filter}/>,
    // check_meter_pairing: <CheckMeterPairingCard />,
    tenants_management: <TenantsManagementCard refresh={refresh} setRefresh={setRefresh} filter={filter} tariffOption={tariffOption} siteOption={siteOption} />,
    tariffs_management: <TariffsManagementCard setRefresh={setRefresh} refresh={refresh} />,
    check_invoice_type: <CheckInvoiceTypeCard setRefresh={setRefresh} refresh={refresh}/>,
  };

  useEffect(() => {
    function handleResize() {
      const newHeight = window.innerWidth >= 1200 ? "calc(100%)" : "auto";
      setHeight(newHeight);
    }

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if(siteLoading == true){
      setRefresh(true)
      setInactiveSites([])
      setActiveSites([])
      getActiveSites(setRefresh, setSiteLoading);
      getInactiveSites();
      getTariffOption();
      getSiteOption()
    } else {
      console.log(settingTree[0])
      if(settingTree[0] == 'site_management'){
        setRefresh(false)
      }
    }
  }, [siteLoading, refresh]);
  
  
  
  return (
    <Row style={{ height: "100%" }}>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        style={{ height: height }}
      >
        {contentList[settingTree[0]]}
      </Col>
    </Row>
  );
}

export default RightCard;
