import { useEffect, useContext } from "react";
import { Form, Row, Col, Button, Card, DatePicker } from "antd"
import dayjs from "dayjs";
import useFetch from "../../hooks/useFetch"
import { IndexContext } from "../../context/IndexContext";
import FunctionalButton from "../common/FunctionalButton";

const { RangePicker } = DatePicker;

export default function SubFilterCard({
    dateRangeSelection
    ,setDateRangeSelection
    ,filter
    ,setIsSummaryCardLoading
    ,setIsEnergyConsumptionDistributionCardLoading
    ,setIsDeviceListTabsLoading
    ,setIsShowMeterTrendingChart
    ,selectedDevice
    ,setSelectedDevice
}) {
    const [form] = Form.useForm()
    const [fetchWrapper] = useFetch();
    const { messageApi } = useContext(IndexContext);
    const { isLightTheme } = useContext(IndexContext);
    

    const onSubmit = (values) => {
        if(values.date_range != null){
            setIsSummaryCardLoading(true)
            setIsEnergyConsumptionDistributionCardLoading(true)
            setIsDeviceListTabsLoading(true)
            setSelectedDevice({})
            setIsShowMeterTrendingChart(false)
            
            setDateRangeSelection({
                start_datetime: dayjs(values.date_range[0]).format('YYYY/MM/DD'),
                end_datetime: dayjs(values.date_range[1]).format('YYYY/MM/DD'),
            })
        }   
    }   

    const onSubmitFailed = () => {

    }

    const onDownloadSummaryDataClicked = () => {
        const filename = `SummaryData_${dayjs().format("YYYYMMDDTHHmmss")}.xlsx`;

        return new Promise((resolve, reject) => {
            fetchWrapper({
                endpoint_url: 'dashboard/downloadSummaryData',
                params: {...filter, ...dateRangeSelection},
                json_output: false,
                onSuccess: (response) => {
                  resolve(true)
                  // returned a json response.
                  if(response.headers.get("content-type") === "application/json"){
                      response.json().then((data) => {
                          if (Object.keys(data).includes('error')){
                              // has issue with the download file
                              messageApi.open({
                                  type: "error",
                                  content: data.error
                              })
                          }
                          else{
                              messageApi.open({
                                  type: "error",
                                  content: "Failed to download Summary Data."
                              })
                          }
                      })
                  }
                  else{
                      // no issue with the response. returned a spreadsheet
                      response.blob().then((blob) => {
                          const url = window.URL.createObjectURL(blob);
                          const a = document.createElement('a');
                          a.href = url;
                          a.download = filename;
                          document.body.appendChild(a);
                          a.click();
                          a.remove();
                          window.URL.revokeObjectURL(url);
                      })
                  }
      
                }
              })
        });
    }

    const onDownloadMeterListClicked = () => {
        const filename = `DeviceList_${dayjs().format("YYYYMMDDTHHmmss")}.xlsx`;

        return new Promise((resolve, reject) => {
            fetchWrapper({
                endpoint_url: 'dashboard/downloadMeterList',
                params: {...filter, ...dateRangeSelection},
                json_output: false,
                onSuccess: (response) => {
                  resolve(true)
                  // returned a json response.
                  if(response.headers.get("content-type") === "application/json"){
                      response.json().then((data) => {
                          if (Object.keys(data).includes('error')){
                              // has issue with the download file
                              messageApi.open({
                                  type: "error",
                                  content: data.error
                              })
                          }
                          else{
                              messageApi.open({
                                  type: "error",
                                  content: "Failed to download Device List."
                              })
                          }
                      })
                  }
                  else{
                      // no issue with the response. returned a spreadsheet
                      response.blob().then((blob) => {
                          const url = window.URL.createObjectURL(blob);
                          const a = document.createElement('a');
                          a.href = url;
                          a.download = filename;
                          document.body.appendChild(a);
                          a.click();
                          a.remove();
                          window.URL.revokeObjectURL(url);
                      })
                  }
      
                }
              })
        });
    }

    const onDownloadTrendingDataClicked = () => {
        const filename = `${selectedDevice?.device_name}_${dayjs().format("YYYYMMDDTHHmmss")}.xlsx`;

        return new Promise((resolve, reject) => {
            fetchWrapper({
                endpoint_url: 'dashboard/downloadTrending',
                params: {...dateRangeSelection, ...selectedDevice},
                json_output: false,
                onSuccess: (response) => {
                  resolve(true)
                  // returned a json response.
                  if(response.headers.get("content-type") === "application/json"){
                      response.json().then((data) => {
                          if (Object.keys(data).includes('error')){
                              // has issue with the download file
                              messageApi.open({
                                  type: "error",
                                  content: data.error
                              })
                          }
                          else{
                              messageApi.open({
                                  type: "error",
                                  content: `Failed to download ${selectedDevice?.device_name} trending data.`
                              })
                          }
                      })
                  }
                  else{
                      // no issue with the response. returned a spreadsheet
                      response.blob().then((blob) => {
                          const url = window.URL.createObjectURL(blob);
                          const a = document.createElement('a');
                          a.href = url;
                          a.download = filename;
                          document.body.appendChild(a);
                          a.click();
                          a.remove();
                          window.URL.revokeObjectURL(url);
                      })
                  }
      
                }
              })
        });
    }

    useEffect(()=>{
        setDateRangeSelection({
            start_datetime: dayjs().startOf('month').format('YYYY/MM/DD'),
            end_datetime: dayjs().startOf('day').format('YYYY/MM/DD'),
        })
    },[])

    return (
        <Card styles={{body: {padding: "12px 12px"}}}>
            <Form form={form} onFinish={onSubmit} onFinishFailed={onSubmitFailed}>
                <Row justify={"space-between"} gutter={[12, 12]}>
                    <Col xs={24} sm={14} md={11} lg={8} xl={6} xxl={5}>
                        <Form.Item
                            name={"date_range"}
                            label={"Date Range"}
                            initialValue={[
                                dayjs().startOf('month'),
                                dayjs().startOf('day')
                            ]}
                        >
                            <RangePicker
                                format={"YYYY/MM/DD"}
                            />        
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={10} md={13} lg={3} xl={1} xxl={2}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" >Search</Button>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={13} xl={17} xxl={17}>
                        <Row justify="end" align="center" gutter={[12,12]}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={5} xxl={5}>
                                <FunctionalButton
                                    buttonClickFunction={onDownloadSummaryDataClicked}
                                    trigger_loading={true}
                                    type={'default'}
                                    custom_button_style={{
                                        backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                                        border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                                    }}
                                >
                                    Download Summary Data
                                </FunctionalButton>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={5} xxl={5}>
                                <FunctionalButton
                                    buttonClickFunction={onDownloadMeterListClicked}
                                    trigger_loading={true}
                                    type={'default'}
                                    custom_button_style={{
                                        backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                                        border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                                    }}
                                >
                                    Download Device List
                                </FunctionalButton>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={5} xxl={5}>
                                <FunctionalButton
                                    buttonClickFunction={onDownloadTrendingDataClicked}
                                    trigger_loading={true}
                                    type={'default'}
                                    custom_button_style={{
                                        backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                                        border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                                    }}
                                    disabled={Object.keys(selectedDevice).length == 0}
                                >
                                    Download Trending Data
                                </FunctionalButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}