import { useContext, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

 

function useDownloadAllInvoice() {
    const [fetchWrapper] = useFetch();
    const { messageApi } = useContext(IndexContext);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        return `${year}-${month}-${day}-${hours}:${minutes}:${seconds}`;
    }   

    const downloadAllInvoice = useCallback((info) => {
        return new Promise((resolve, reject) => {
        fetchWrapper({
            endpoint_url: "invoice/downloadAllInvoice",
            params: {
                invoice_id_list: info
            },
            json_output: false,
            onSuccess: (response) => {
                if (response.headers.get("content-type") === "application/json") {
                    response.json().then((data) => {
                        if (data.error) {
                            messageApi.open({
                                type: "error",
                                content: data.error || "Failed to download file"
                            });
                        } else {
                            messageApi.open({
                                type: "error",
                                content: "Failed to download file."
                            });
                        }
                        reject(response)
                    });
                } else {
                    response.blob().then((blob) => {
                        console.log(new Date)
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'invoice_file_' + formatDate(new Date) + '.zip'; // Ensure the file has the right extension
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    });
                    resolve('Success')
                }
            },
            onResponseError: (err) => {
                messageApi.open({
                    type: "error",
                    content: "Failed to download file"
                });
                reject(err)
            }
        });
        });
    }, [fetchWrapper, messageApi]);

    return { downloadAllInvoice };
}

export default useDownloadAllInvoice;
