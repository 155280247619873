import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetInactiveSites() {
  const [fetchWrapper] = useFetch();
  const [inactiveSites, setInactiveSites] = useState([]);
  const [inactiveSitesLoading, setInactiveSitesLoading] = useState(false)

  const getInactiveSites = useCallback(() => {
    setInactiveSitesLoading(true)
    fetchWrapper({
      method: "GET",
      endpoint_url: "settings/getInactiveSites",
      set_content_type_header: false,
      onSuccess: (fetch_output) => {
        setInactiveSites(fetch_output.inactive_site);
        setInactiveSitesLoading(false)
      },
    });
  }, [fetchWrapper]);

  return { getInactiveSites, inactiveSites, setInactiveSites, inactiveSitesLoading };
}

export default useGetInactiveSites;
