
const TableColumns = ({
}) => {

    const table_columns = [
        {
            title: 'Invoice ID',
            dataIndex: 'invoice_id',
            key: 'invoice_id',
            width: '150px'
        },
        {
            title: 'Tenant Name',
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            width: '150px'
        },
        {
            title: 'Billing Month',
            dataIndex: 'billing_month',
            key: 'billing_month',
            width: '150px'
        },
        {
            title: 'Start Period',
            dataIndex: 'start_period',
            key: 'start_period',
            width: '150px'
        },
        {
            title: 'End Period',
            dataIndex: 'end_period',
            key: 'end_period',
            width: '150px'
        }
    ];

    return table_columns;
};

export default TableColumns;
