import { Row, Col } from 'antd';
import React, { useRef, useEffect, useState, useContext } from 'react';
import CustomCard from "../common/CustomCard";
import CustomTabs from "../common/CustomTabs";
import StatisticInfo from "./right_card_components/statistic_info/StatisticInfo"
import AutoGeneratedInvoicesTable from "./right_card_components/auto_generated_invoices/AutoGeneratedInvoicesTable"
import ManualInvoicesTable from "./right_card_components/manual_invoices/ManualInvoicesTable"
import MissingDataInvoicesTable from "./right_card_components/missing_data_invoices/MissingDataInvoicesTable"
import PendingInvoicesTable from "./right_card_components/pending_invoices/PendingInvoicesTable"
import FunctionalButton from "../common/FunctionalButton";
import { IndexContext } from '../../context/IndexContext';

// Get Invoice Table Data
import useGetAutoGeneratedInvoices from "../../functions/Invoice/invoice_generation_func/getAutoGeneratedInvoices";
import useGetManualGeneratedInvoices from "../../functions/Invoice/invoice_generation_func/getManualGeneratedInvoices";
import useGetPendingInvoices from "../../functions/Invoice/invoice_generation_func/getPendingInvoices";
import useGetMissingDataInvoices from "../../functions/Invoice/invoice_generation_func/getMissingDataInvoices";

import useDownloadAllInvoice from "../../functions/Invoice/invoice_generation_func/downloadAllInvoice"

function RightCard({
  invoiceFilter, setInvoiceFilter,  refresh, setRefresh, filter
}) {
  const [height, setHeight] = useState('auto');
  const [selectedRows, setSelectedRows] = useState([])
  const [invoiceGenerateDetailsQuantity, setInvoiceGenerateDetailsQuantity] = useState(
    {
      auto_generated_invoices_quantity: 0,
      manual_invoices_quantity: 0,
      pending_invoices_quantity: 0,
      missing_data_invoices_quantity: 0,
    }
  )
  const { isLightTheme } = useContext(IndexContext);
  
  const {getAutoGeneratedInvoices, autoGeneratedInvoices} = useGetAutoGeneratedInvoices();
  const {getManualGeneratedInvoices, manualGeneratedInvoices} = useGetManualGeneratedInvoices();
  const {getPendingInvoices, pendingInvoices} = useGetPendingInvoices();
  const {getMissingDataInvoices, missingDataInvoices} = useGetMissingDataInvoices();
  const {downloadAllInvoice} = useDownloadAllInvoice();


  useEffect(()=>{
    setInvoiceGenerateDetailsQuantity(invoiceGenerateDetailsQuantity => ({
        ...invoiceGenerateDetailsQuantity,
        auto_generated_invoices_quantity: autoGeneratedInvoices.length
    }));
  },[autoGeneratedInvoices])

  useEffect(()=>{
    setInvoiceGenerateDetailsQuantity(invoiceGenerateDetailsQuantity => ({
        ...invoiceGenerateDetailsQuantity,
        manual_invoices_quantity: manualGeneratedInvoices.length
    }));
  },[manualGeneratedInvoices])

  useEffect(()=>{
    setInvoiceGenerateDetailsQuantity(invoiceGenerateDetailsQuantity => ({
        ...invoiceGenerateDetailsQuantity,
        pending_invoices_quantity: pendingInvoices.length
    }));
  },[pendingInvoices])

  useEffect(()=>{
    setInvoiceGenerateDetailsQuantity(invoiceGenerateDetailsQuantity => ({
        ...invoiceGenerateDetailsQuantity,
        missing_data_invoices_quantity: missingDataInvoices.length
    }));
  },[missingDataInvoices])


  useEffect(()=>{
    getAutoGeneratedInvoices(filter)
    getManualGeneratedInvoices(filter)
    getPendingInvoices(filter)
    getMissingDataInvoices(filter)
  },[refresh, filter])

  useEffect(() => {
    function handleResize() {
      const newHeight = window.innerWidth >= 1200 ? 'calc(100% - 158px)' : 'auto';
      setHeight(newHeight);
    }

    handleResize(); // Set initial height
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function downloadSelectedFunction(){
    return new Promise((resolve, reject) => {
      downloadAllInvoice(selectedRows).then(()=>{
          resolve()
          setRefresh(true)
      })
      .catch(reject);
  });
  }

  return (
      <>
        <Row style={{height:'100%'}}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{paddingBottom:'10px'}}>
            <StatisticInfo refresh={refresh} filter={filter}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{height: height}}>
            <CustomCard
                title='Invoive Generation'
                extra={
                  <FunctionalButton 
                    disabled={selectedRows.length == 0 ? true : false} 
                    buttonClickFunction={downloadSelectedFunction} 
                    custom_button_style={{
                      background:'#141414', 
                      width:'300px',
                      backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                      border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                    }} 
                    children='Download Selected Invoices'
                    type={'default'}
                  />
                }
                card_style={{height:'100%'}}
                title_style={{padding:'8px 12px'}}
                children_style={{ padding:'0px'}}
                children={
                    <CustomTabs
                      tab_style={{background:isLightTheme? '#F3F3F3':'#1D1D1D'}}
                      tab_children_styles={{padding:'0px', height:'100%'}}
                      tabs={
                        [
                          { 
                            title: 'Auto-Generated Invoices ( ' + invoiceGenerateDetailsQuantity['auto_generated_invoices_quantity'] + ' )', 
                            key: '1',
                            content: <AutoGeneratedInvoicesTable autoGeneratedInvoices={autoGeneratedInvoices} setSelectedRows={setSelectedRows} tableRowSelection={true} />
                          },
                          { 
                            title: 'Manual Invoices ( ' + invoiceGenerateDetailsQuantity['manual_invoices_quantity'] + ' )',
                            key: '2',
                            content: <ManualInvoicesTable setRefresh={setRefresh} manualGeneratedInvoices={manualGeneratedInvoices} setSelectedRows={setSelectedRows} tableRowSelection={true} />
                          },
                          { 
                            title: 'Pending Invoices ( ' + invoiceGenerateDetailsQuantity['pending_invoices_quantity'] + ' )',
                            key: '3',
                            content: <PendingInvoicesTable setRefresh={setRefresh} invoiceFilter={invoiceFilter} pendingInvoices={pendingInvoices} setSelectedRows={setSelectedRows} tableRowSelection={false} />
                          },
                          { 
                            title: 'Missing Data Invoices ( ' + invoiceGenerateDetailsQuantity['missing_data_invoices_quantity'] + ' )',
                            key: '4',
                            content: <MissingDataInvoicesTable setRefresh={setRefresh} invoiceFilter={invoiceFilter} missingDataInvoices={missingDataInvoices} setSelectedRows={setSelectedRows} tableRowSelection={false} />
                          },
                        ]
                      }
                    />
                }
            />
          </Col>
        </Row>
      </>
    )
}

export default RightCard;
