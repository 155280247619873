import { Form } from "antd";
import { IndexContext } from "../../../../context/IndexContext";
import { useState, useEffect, useContext } from "react";
import CheckInvoiceTypeTable from "./table/CheckInvoiceTypeTable";
import CheckInvoiceTypeForm from "./CheckInvoiceTypeForm";
import CustomCard from "../../../common/CustomCard";
import CustomModal from "../../../common/CustomModal";
import FunctionalButton from "../../../common/FunctionalButton";

import useGetInvoiceType from "../../../../functions/Settings/invoice_type_management/getInvoiceTypes";
import useInsertInvoiceType from "../../../../functions/Settings/invoice_type_management/insertInvoiceType";
import useUpdateInvoiceType from "../../../../functions/Settings/invoice_type_management/updateInvoiceType";
import useDeleteInvoiceType from "../../../../functions/Settings/invoice_type_management/deleteInvoiceType";

export default function CheckInvoiceTypeCard({setRefresh, refresh}) {
  const [invoiceTypeInsertForm] = Form.useForm();
  const [insertVisible, setInsertVisible] = useState(false);
  const { messageApi } = useContext(IndexContext);

  const [invoiceTypeEditForm] = Form.useForm();
  const [editVisible, setEditVisible] = useState(false);
  const [visibleData, setVisibleData] = useState({});
  const [invoiceTypeQuantity, setInvoiceTypeQuantity] = useState(3);

  const [invoiceTypeData, setInvoiceTypeData] = useState([]);

  const { getInvoiceType, invoiceType  } = useGetInvoiceType();
  const { insertInvoiceType, insertInvoiceTypeLoading  } = useInsertInvoiceType();
  const { updateInvoiceType, updateInvoiceTypeLoading } = useUpdateInvoiceType();

  function validateSiteInfo(object) {
    var target = true
    // Create an array of error messages for each empty field
    const errors = Object.entries(object)
        .filter(([key, value]) => value === undefined || value === null || value === '')
        .map(([key]) => {
          target = false
          messageApi.open({
            type: "error",
            content: `Field '${key}' cannot be empty`,
          });         
        }
      );
    
      return target
  }


  const handleInsertConfirm = (value) => {
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        insertInvoiceType(value).then((data) => {
          if('error' in data){
            console.log(data.error)
          } else {
            handleInsertCancel();
            getInvoiceType(setRefresh)
            setInvoiceTypeData([])
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  };

  const handleInsertCancel = () => setInsertVisible(false);

  const handleEditConfirm = (value, key) => {
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        updateInvoiceType(value, key).then((data) => {
          if('error' in data){
            console.log(data.error)
          } else {
            handleEditCancel();
            getInvoiceType(setRefresh)
            setInvoiceTypeData([])
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  };
  const handleEditCancel = () => setEditVisible(false);


  const { deleteInvoiceType } = useDeleteInvoiceType()
  const [visibleNotify, setVisibleNotify] = useState(false)
  const [visibleNotifyData, setVisibleNotifyData] = useState({
    'title': '',
    'content': '',
    'type': '',
    'key': ''
  })

  const confirmNotify = () => {
    return new Promise((resolve, reject) => {
      deleteInvoiceType(visibleNotifyData['key']).then((data) => {
        if('error' in data){
          console.log('')
        } else {
          cancelNotify();
          getInvoiceType(setRefresh)
          setInvoiceTypeData([])
        }
        resolve();
      }).catch((err)=>{
        resolve(err);
      }
    )}
    )
  }

  const cancelNotify = () => setVisibleNotify(false)

  useEffect(()=>{
    setInvoiceTypeQuantity(invoiceType.length)
    setInvoiceTypeData(invoiceType)
  },[invoiceType])

  useEffect(()=>{
    if(refresh == true){
      getInvoiceType(setRefresh)
    }
  },[refresh])

  useEffect(()=>{
    setRefresh(true)
  },[])

  return (
    <>
      <CustomCard
        title={"Check Invoice Type ( " + invoiceTypeQuantity + " )"}
        extra={
          <FunctionalButton
            buttonClickFunction={() => setInsertVisible(true)}
            custom_button_style={{ background: "#141414", width: "200px" }}
            children="Insert New Invoice Type"
          />
        }
        card_style={{ height: "100%" }}
        title_style={{ background: "#303030", padding: "8px 12px" }}
        children_style={{ padding: "0px" }}
        children={
          <CheckInvoiceTypeTable
            setVisibleNotify={setVisibleNotify}
            setVisibleNotifyData={setVisibleNotifyData}
            invoiceTypeData={invoiceTypeData}
            setVisible={setEditVisible}
            visible={editVisible}
            setVisibleData={setVisibleData}
          />
        }
      />
      <CheckInvoiceTypeForm
        form={invoiceTypeInsertForm}
        visible={insertVisible}
        modalTitle={"Insert Invoice Type"}
        handleFinish={handleInsertConfirm}
        handleCancel={handleInsertCancel}
      />

      <CheckInvoiceTypeForm
        form={invoiceTypeEditForm}
        visible={editVisible}
        modalTitle={"Edit Invoice Type"}
        handleFinish={handleEditConfirm}
        handleCancel={handleEditCancel}
        visibleData={visibleData}
      />

      <CustomModal
        title={
          <h4 style={{margin:'0px', fontWeight:'400'}}>{visibleNotifyData.title}</h4>
        }
        visible={visibleNotify}
        content={visibleNotifyData.content}
        content_style={{
          padding: '16px 16px',
          borderBottom: '1px solid #303030'
        }}
        footer={
          <div>
            <FunctionalButton
              buttonClickFunction={() => cancelNotify()}
              custom_button_style={{ width: "150px", marginRight:'10px' }}
              children="Cancel"
            />
            <FunctionalButton
              buttonClickFunction={() => confirmNotify()}
              custom_button_style={{ width: "150px" }}
              children="Confirm"
            />
          </div>
        }
      />
    </>
  );
}
