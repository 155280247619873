import dayjs from "dayjs";
import DataTable from "../common/DataTable";
import useFetch from "../../hooks/useFetch";
import { useEffect, useState } from "react";
import FunctionalButton from "../common/FunctionalButton";
import DeviceAlarmAcknowledgement from "./DeviceAlarmAcknowledgement";
import ConsumptionAlarmAcknowledgement from "./ConsumptionAlarmAcknowledgement";

export default function ConsumptionAlarmTab({
  site = null,
  tenant_list = [],
  month,
}) {
  const [fetchWrapper] = useFetch();
  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAlarm, setSelectedAlarm] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const table_columns = [
    // {
    //   title: "Site",
    //   dataIndex: "site_name",
    //   key: "site_name",
    // },
    {
      title: "Tenant",
      dataIndex: "tenant_name",
      key: "tenant_name",
      sorter: (a, b) => a.tenant_name.localeCompare(b.tenant_name)
    },
    {
      title: "Meter",
      dataIndex: "meter_name",
      key: "meter_name",
      sorter: (a, b) => a.meter_name.localeCompare(b.meter_name)
    },
    {
      title: "Lots",
      dataIndex: "lots",
      key: "lots",
      sorter: (a, b) => a.lots.localeCompare(b.lots)
    },
    {
      title: "Alarms",
      dataIndex: "alarm",
      key: "alarm",
    },
    {
      title: "Billing Month",
      dataIndex: "billing_month",
      key: "billing_month",
      render: (text, record) => dayjs(text).format("YYYY-MM"),
    },
    {
      title: "Monthly Consumption",
      dataIndex: "current_month_consumption",
      key: "current_month_consumption",
    },
    {
      title: "Previous Month Consumption",
      dataIndex: "last_month_consumption",
      key: "last_month_consumption",
    },
    {
      title: "Acknowledge By",
      dataIndex: "username",
      key: "username",
      render: (text, record) => record.username ?? "-",
    },
    {
      title: "Acknowledge On",
      dataIndex: "acknowledged_on",
      key: "acknowledged_on",
      render: (text, record) =>
        record.acknowledged_on == null
          ? "-"
          : dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <div style={{ display: "flex" }}>
            <FunctionalButton
              buttonClickFunction={() => {
                setSelectedAlarm(record);
                setOpenModal(true);
              }}
              type="primary"
              custom_button_style={{ marginRight: "10px", width: "150px" }}
              children={record.acknowledged_on != null ? "View" : "Acknowledge"}
            />
          </div>
        );
      },
    },
  ];

  function getConsumptionAlarm() {
    fetchWrapper({
      endpoint_url: "alarm/getConsumptionAlarm",
      params: {
        site_id: site,
        tenant_list: tenant_list,
        month: month,
      },
      onSuccess: (fetch_output) => {
        setData(fetch_output.alarm_list);
      },
    });
  }

  useEffect(() => {
    getConsumptionAlarm();
  }, [site, tenant_list, month]);

  useEffect(() => {
    if (refresh === true) {
      getConsumptionAlarm();
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <>
      <DataTable
        table_column={table_columns}
        table_data={data}
        refresh={refresh}
      ></DataTable>
      <ConsumptionAlarmAcknowledgement
        selectedAlarm={selectedAlarm}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setRefresh={setRefresh}
      ></ConsumptionAlarmAcknowledgement>
    </>
  );
}
