import "../../styles/login_pages/button.css";
import "../../styles/login_pages/card.css";
import { Card, Image, Layout, Button, Form, Input, Space, theme } from "antd";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import siteLogoWhite from "../../assets/site_logo_white.png"
import siteLogoBlack from "../../assets/site_logo_black.jpeg"
import ParticleEffect from "../../components/login/ParticleEffect";
import { IndexContext } from "../../context/IndexContext";
import useFetch from "../../hooks/useFetch";
import getBrowserFingerprint from "get-browser-fingerprint";

export default function LoginPage() {
  let navigate = useNavigate(); //for navigating
  let [login_processing, setLoginProcessing] = useState(false);

  // browser fingerprint
  const fingerprint = getBrowserFingerprint();

  // load index context
  const { messageApi, isLightTheme } = useContext(IndexContext);

  const [fetchWrapper] = useFetch();

  // load the design token
  const { token } = theme.useToken();

  const onFinish = (values) => {
    login(values);
  };

  const onFinishFailed = (errorInfo) => {
    setLoginProcessing(false);
    console.log("Failed:", errorInfo);
  };

  const showLoading = () => {
    setLoginProcessing(true);
  };

  function getAuthenticationState() {
    fetchWrapper({
      endpoint_url: "user_management/checkUser",

      onSuccess: (response) => {
        navigate("/dashboard", { replace: true });
      },

      onResponseError: () => {
        navigate("/login", { replace: true });
      },

      onFetchError: () => {
        navigate("/login", { replace: true });
      },
    });
  }

  function login(user_inputs) {
    fetchWrapper({
      endpoint_url: "user_management/loginUser",
      params: {
        username: user_inputs.username,
        password: user_inputs.password,
        fingerprint: fingerprint, 
      },
      onSuccess: (fetch_output) => {
        if (fetch_output.login_status === true) {
          // login success
          messageApi.open({
            type: "success",
            content: "Login Success! Redirecting...",
          });

          setTimeout(() => {
            setLoginProcessing(false);
            navigate("/dashboard", { replace: true }); //replace this so back wont go back to login page
          }, 2000);
        } else {
          // incorrect credential
          messageApi.open({
            type: "warning",
            content: "Incorrect username or password.",
          });
          setLoginProcessing(false);
        }
      },
    });
  }

  useEffect(()=>{
    getAuthenticationState()
  },[])

  return (
    <div>
      <Layout style={{ height: "100vh" }}>
        <Layout.Content
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ParticleEffect particle_color={token.colorText} />
          <Card
            id='login-card'
            style={{ width: "80%", maxWidth: "400px", padding: "1rem 1.5rem" }}
          >
            <Form
              name="basic"
              layout="vertical"
              requiredMark={false}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              loading={login_processing}
              onSubmitCapture={showLoading}
            >
              <Space size={"large"} direction={"vertical"}>
                <div style={{ textAlign: "center"}}>
                  <Image
                    src={isLightTheme ? siteLogoBlack : siteLogoWhite}
                    preview={false}
                    width={"100%"}
                  ></Image>
                </div>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                <Form.Item style={{ paddingBottom: "0px" }}>
                  <Button
                    id="login-button"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={login_processing}
                    style={{ width: "100%" }}
                  >
                    Log In
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Card>
        </Layout.Content>
      </Layout>
    </div>
  );
}
