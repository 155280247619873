import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetTariffs() {
  const [fetchWrapper] = useFetch();
  const [tariffs, setTariffs] = useState([]);

  const getTariffs = useCallback((setRefresh) => {
    fetchWrapper({
      method: "GET",
      endpoint_url: "settings/getTariffs",
      set_content_type_header: false,
      onSuccess: (fetch_output) => {
        setTariffs(fetch_output.tariffs);
        setRefresh(false)
      },
    });
  }, [fetchWrapper]);

  return { getTariffs, tariffs };
}

export default useGetTariffs;
