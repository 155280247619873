import { Flex } from "antd";
import FunctionalButton from "../../../../common/FunctionalButton";

const TableColumns = ({ setVisibleNotifyData, setVisibleNotify, setVisible, setVisibleData }) => {
  const showDetails = (info) => {
    setVisible(true);
    setVisibleData(info);
  };

  const remove_tenant = (info) => {
    setVisibleNotify(true);
    setVisibleNotifyData({
      'title': 'Delete Tariff',
      'type': 'delete_tariff',
      'content': 
        <div style={{ margin: '0px' }}>
          <h3 style={{ margin: '0px', fontWeight:'400', paddingBottom:'10px', fontSize:'17px', borderBottom:'1px solid #303030'}}>Are you sure you want to delete the following tenant?</h3>
          <h3 style={{ margin: '0px', fontWeight:'400', marginTop:'10px'}}><strong>Tenant Name: </strong> {info.tenant_name}</h3>
        </div>,
      'key': info.key
    });
  };

  const table_columns = [
    {
      title: "Tenant Name",
      dataIndex: "tenant_name",
      key: "tenant_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Tariff",
      dataIndex: "tariff",
      key: "tariff",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "200px",
      render: (info, record) => {
        return (
          <Flex gap="middle">
            <FunctionalButton
              buttonClickFunction={() => {
                showDetails(record);
              }}
              custom_button_style={{ width: "150px" }}
              children="Edit"
            />
            <FunctionalButton
              disabled={record.disabled}
              buttonClickFunction={() => {
                remove_tenant(record);
              }}
              custom_button_style={{ width: "150px", background: record.disabled ? "#282828" : 'red' }}
              children="Delete"
            />
          </Flex>
        );
      },
    },
  ];

  return table_columns;
};

export default TableColumns;
