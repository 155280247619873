import { useState, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetAutoGeneratedInvoices() {
    const [fetchWrapper] = useFetch();
    const [autoGeneratedInvoices, setAutoGeneratedInvoices] = useState([])

    const getAutoGeneratedInvoices = useCallback((filter) => {
        fetchWrapper({
            endpoint_url: "invoice/getAutoGeneratedInvoices",
            params: filter,
            onSuccess: (fetch_output) => {
                setAutoGeneratedInvoices(fetch_output.auto_generated_invoice)
            },
        });
    }, [fetchWrapper]);

    return { getAutoGeneratedInvoices, autoGeneratedInvoices };
}

export default useGetAutoGeneratedInvoices;
