import { RiseOutlined, FallOutlined } from "@ant-design/icons";

export function trendIconType(type){
    switch (type) {
      case 1:
        return (
          <RiseOutlined style={{color: "green", fontSize: "1.3rem"}}/>
        );
      case 2:
        return (
          <RiseOutlined style={{color: "red", fontSize: "1.3rem"}}/>
        );
      case 3:
        return (
          <FallOutlined style={{color: "green", fontSize: "1.3rem"}}/>
        );
      case 4:
        return (
          <FallOutlined style={{color: "red", fontSize: "1.3rem"}}/>
        );
      default:
        break;
    }
  }