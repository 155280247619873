import { useState, useCallback, useContext } from "react";
import { IndexContext } from "../../../context/IndexContext";
import useFetch from "../../../hooks/useFetch";

function useUpdateInvoiceIdSettings() {
    const [fetchWrapper] = useFetch();
    const { messageApi } = useContext(IndexContext);

    const updateInvoiceIdSettings = useCallback((invoice_settings, setRefresh) => {
        fetchWrapper({
            endpoint_url: "invoice/updateInvoiceIdSettings",
            params: invoice_settings,
            onSuccess: (fetch_output) => {
                if('error' in fetch_output){
                    messageApi.open({
                        type: "error",
                        content: fetch_output['error'],
                    });        
                } else {
                    messageApi.open({
                        type: "success",
                        content: "Updated Invoice ID Settings Successfully",
                    });        
                    setRefresh(true)
                }
            },
            onError: (error) => {
                messageApi.open({
                    type: "error",
                    content:
                      "Fas",
                });        
            },
        });
    }, [fetchWrapper]);

    return { updateInvoiceIdSettings};
}

export default useUpdateInvoiceIdSettings;
