
import { Row, Col } from 'antd';
import { useState, useEffect } from "react";
import table_column from "./TableColumn"
import DataTable from "../../../common/DataTable";
import CustomModal from "../../../common/CustomModal";
import FunctionalButton from "../../../common/FunctionalButton";
import FailedUploadingInvoicesTable from "../../left_card_components/manual_invoice_progress/failed_uploading_manual_invoice/FailedUploadingInvoicesTable"

export default function ManualInvoicesProgressTable({
    manualInvoiceProgress
}) {
    const [visible, setVisible] = useState(false);
    const [visibleData, setVisibleData] = useState({});

    // const {downloadInvoice} = useDownloadInvoice();
    const columns = table_column({ 
        setVisible, visible, setVisibleData
    });

    const handleCancel = () => {
        setVisible(false);
    };
  

    useEffect(()=>{
        console.log(visibleData)
    },[visibleData])

    return (
        <>
            <DataTable temp_table_height={440} pagination_style={{ margin:'10px' }} tableRowSelection={false} table_column={columns} table_data={manualInvoiceProgress}/>
            <CustomModal
                visible={visible}
                width={1400}
                onCancel={handleCancel}
                title={
                    <h4 style={{ margin: '0px', fontWeight: '500' }}>Failed Uploading Manual Invoice</h4>
                }
                title_style={{
                    background: '#303030'
                }}
                modal_style={{
                    marginTop: ''
                }}
                content={
                    <div style={{height:'525px'}}>
                        <FailedUploadingInvoicesTable visibleData={visibleData}/>
                    </div>
                }
                content_style={{
                    padding: '0px 12px'
                }}
                footer_style={{
                    padding: '10px 10px',
                }}
                footer={
                    <div style={{ display: 'flex' }}>
                        <FunctionalButton
                            buttonClickFunction={handleCancel}
                            custom_button_style={{ marginRight: '10px', width: '100px', padding: '3px 5px', marginRight: '6px' }}
                        >
                            Close
                        </FunctionalButton>
                    </div>
                }
            />
        </>
    )
}

