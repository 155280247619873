import { useEffect, useState } from "react";
import LeftCard from "../../components/invoice/LeftCard";
import RightCard from "../../components/invoice/RightCard";
import InvoiceFilterCard from "../../components/invoice/InvoiceFilterCard";
import { Row, Col } from 'antd';

function InvoicePage() {
    const now = new Date();
    const [filter, setFilter] = useState({
        'site_id': sessionStorage.getItem('site_id') == undefined ? 1 : parseInt(sessionStorage.getItem('site_id')),
        'year': now.getFullYear(),
        'month': now.getMonth() + 1
    });
    const [availableHeight, setAvailableHeight] = useState(window.innerHeight);
    const [invoiceFilter, setInvoiceFilter] = useState([])
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        const updateAvailableHeight = () => {
            setAvailableHeight(window.innerHeight - 150);
        };

        updateAvailableHeight();
        window.addEventListener('resize', updateAvailableHeight);
        return () => {
            window.removeEventListener('resize', updateAvailableHeight);
        };

    }, []);
    

    useEffect(() => {
        setInterval(() => {
            setRefresh(true)
        }, 30000)
    }, []);

    return (
        <>    
            <Row style={{height:'100%'}}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{paddingBottom:'10px'}}>
                    <InvoiceFilterCard filter={filter} setFilter={setFilter}/>
                </Col>
                <Row gutter={[12, 0]} style={{height:availableHeight}}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={7} xxl={6} style={{height:'auto'}}>
                        <LeftCard filter={filter} refresh={refresh} setRefresh={setRefresh} invoiceFilter={invoiceFilter} setInvoiceFilter={setInvoiceFilter}/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16} xl={17} xxl={18} style={{height:'auto'}}>
                        <RightCard filter={filter} refresh={refresh} setRefresh={setRefresh} invoiceFilter={invoiceFilter} setInvoiceFilter={setInvoiceFilter}/>
                    </Col>
                </Row>
            </Row>
        </>
    );
}

export default InvoicePage;
