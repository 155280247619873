import dayjs from "dayjs";
import { useEffect, useState } from "react";
import FilterCard from "../common/FilterCard_V2";
import useFetch from "../../hooks/useFetch";

function SettingsFilterCard({filter, setFilter, loading}) {
    const [fetchWrapper] = useFetch();

    const [siteList, setSiteList] = useState([]);
    const [filterProperties, setFilterProperties] = useState([]);

    function getSite(){
        fetchWrapper({
          endpoint_url: "site_management/getFilterSite",
          params: {
            'non_device': true
          } ,
          onSuccess: (fetch_output) => {
            if("site_filter_list" in fetch_output){
              setSiteList(fetch_output.site_filter_list)
            }
          },
        })
    }

    function filterFormSubmitFailed(values) {
    
    }

    function filterFormSubmit(values) {
        setFilter({
          site_id: values.site_selection,
        })
    }


    // useEffects
    useEffect(() => {
        getSite()
    }, []);

    useEffect(() => {
        setFilterProperties([
        {
            type: 'select',
            name: 'site_selection',
            label: 'Sites',
            options: siteList.map((x)=>{return{value: x.ID, label: x.LongName}}),
            defaultValue: sessionStorage.getItem('site_id') == undefined ? siteList[0]?.ID : parseInt(sessionStorage.getItem('site_id')),
        }
        ])
    }, [siteList])
    
    return (
        <FilterCard
            filter_properties={filterProperties}
            onSubmit={filterFormSubmit}
            onSubmitFailed={filterFormSubmitFailed}
            show_search_button={true}
            is_inline_layout={true}
            button_loading={loading}
        />
    )
}

export default SettingsFilterCard;
