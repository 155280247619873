
import { Row, Col } from 'antd';
import { useState, useEffect } from "react";
import DataTable from "../../../../common/DataTable";
import table_column from "./TableColumn"

export default function ManualInvoicesProgressTable({
    visibleData
}) {

    const columns = table_column({ });

    return (
        <>
            <DataTable temp_table_height={440} pagination_style={{ margin:'10px' }} tableRowSelection={false} table_column={columns} table_data={visibleData}/>
        </>
    )
}

