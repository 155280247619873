import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetSiteOption() {
  const [fetchWrapper] = useFetch();
  const [siteOption, setSiteOption] = useState([]);

  const getSiteOption = useCallback(() => {
    fetchWrapper({
      method: "GET",
      endpoint_url: "settings/getSiteOption",
      set_content_type_header: false,
      onSuccess: (fetch_output) => {
        setSiteOption(fetch_output.site_option);
      },
    });
  }, [fetchWrapper]);

  return { getSiteOption, siteOption };
}

export default useGetSiteOption;
