import {
  Row,
  Col,
  Select,
  Form,
  Typography,
  Cascader,
  DatePicker,
  Button,
  Skeleton,
  Input,
  Card,
} from "antd";
import { validateProp } from "../../hooks/useValidate";

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

/**
 * A common filter non-collapsible card for showing filter options and submit button.
 * Currently supported form items including: cascader-select (Cascader), RangePicker, Select, multi-select, Date Picker
 *
 * @export
 * @param {{ filter_properties: object[]; onSubmit: function(form_values); onChange: function(value); onSubmitFailed?: () => void; button_loading: boolean; show_search_button: boolean; is_inline_layout:boolean }} param0
 * @param {{}} param0.filter_properties
 * @param {(arg0: form_values) => any} param0.onSubmit
 * @param {(arg0: value) => any} param0.onChange
 * @param {() => void} [param0.onSubmitFailed=()=>{}]
 * @param {*} [param0.button_loading=false]
 * @param {*} [param0.show_search_button=false]
 * @param {*} [param0.is_inline_layout=false]
 * @returns
 */
export default function FilterCard({
  filter_properties,
  onSubmit,
  onChange,
  onChangeActive = false,
  onSubmitFailed = () => {},
  show_search_button = true,
  button_loading = false,
  is_inline_layout = false,
}) {
  const [form] = Form.useForm();
  const layout = is_inline_layout
    ? null
    : {
        labelCol: {
          span: 24,
          padding: 0,
        },
        wrapperCol: {
          span: 24,
        },
      };

  return (
    <Card styles={{ body: { padding: "12px 12px" } }}>
      {filter_properties.length != 0 ? (
        <Form
          {...layout}
          form={form}
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
          style={{ maxWidth: "none" }}
        >
          <Row gutter={[12, 12]} align={"bottom"}>
            {filter_properties.map((filter_item, index) => {
              return (
                <Col
                  key={index}
                  {...(filter_item.colspan ?? {
                    xs: 24,
                    sm: 24,
                    md: 24,
                    lg: 12,
                    xl: 7,
                    xxl: 6,
                  })}
                >
                  <Form.Item
                    name={filter_item.name}
                    label={<Text strong>{filter_item.label}</Text>}
                    initialValue={filter_item.defaultValue}
                    style={{ marginBottom: 0 }}
                  >
                    {filter_item.type === "cascader-select" ? (
                      <Cascader
                        options={filter_item.options}
                        onChange={
                          show_search_button
                            ? (value) =>
                                onChange({
                                  form: form,
                                  key: filter_item.name,
                                  value: value,
                                })
                            : () => {
                                form.submit();
                              }
                        }
                      />
                    ) : filter_item.type === "datetime-range-select" ? (
                      <RangePicker
                        style={{ width: "100%" }}
                        showTime={filter_item.showTime}
                        onChange={
                          show_search_button
                            ? (dates) => {
                              if(filter_item.onchange_active == true){
                                onChange({
                                  form: form,
                                  key: filter_item.name,
                                  value: dates,
                                })
                              }
                            }
                            : () => {
                                form.submit();
                              }
                        }
                        disabledDate={(current, { from }) => {
                          if (from) {
                            return filter_item.range_limit == null
                              ? false
                              : Math.abs(current.diff(from, "days")) >=
                                  filter_item.range_limit;
                          }
                          return false;
                        }}
                        format={filter_item.format}
                      />
                    ) : filter_item.type === "select" ? (
                      <Select
                        options={filter_item.options}
                        onChange={
                          show_search_button
                            ? (value) => {
                              if(filter_item.onchange_active == true){
                                onChange({
                                  form: form,
                                  key: filter_item.name,
                                  value: value,
                                })
                              }
                            }
                            : () => {
                                form.submit();
                              }
                        }
                      />
                    ) : filter_item.type === "multi-select" ? (
                      <Select
                        options={filter_item.options}
                        mode="multiple"
                        maxCount={filter_item.maxCount}
                        maxTagCount={"responsive"}
                        onChange={
                          show_search_button
                            ? (value) => {
                                if(filter_item.onchange_active == true){
                                onChange({
                                  form: form,
                                  key: filter_item.name,
                                  value: value,
                                })
                              }
                            }
                            : () => {
                                form.submit();
                              }
                        }
                        {...filter_item.props}
                      />
                    ) : filter_item.type === "input" ? (
                      <Input
                        type={filter_item.input_type}
                        {...filter_item.props}
                      />
                    ) : filter_item.type === "datetime-select" ? (
                      <DatePicker
                        style={{ width: "100%" }}
                        showTime={filter_item.showTime}
                        onChange={
                          show_search_button
                            ? (date) => {
                              if(filter_item.onchange_active == true){
                                onChange({
                                  form: form,
                                  key: filter_item.name,
                                  value: date,
                                })
                              }
                            }
                            : () => {
                                form.submit();
                              }
                        }
                        format={filter_item.format}
                        picker={filter_item.picker}
                      />
                    ) : (
                      <Text>Invalid Type</Text>
                    )}
                  </Form.Item>
                </Col>
              );
            })}
            <Form.Item
              style={{
                display: show_search_button ? "grid" : "none",
                marginBottom: 0,
              }}
            >
              <Button type="primary" htmlType="submit" loading={button_loading}>
                Search
              </Button>
            </Form.Item>
          </Row>
        </Form>
      ) : (
        <Skeleton title={false} paragraph={{ rows: 1 }} active />
      )}
    </Card>
  );
}
