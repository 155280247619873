import FunctionalButton from "../../../../common/FunctionalButton";

const TableColumns = ({ setVisibleNotifyData, setVisibleNotify, setPairingTenantVisibleModal, setPairingTenantVisibleData }) => {

  const showPairingTenant = (info) => {
    setPairingTenantVisibleModal(true);
    setPairingTenantVisibleData(info);
  };

  const unPairingMeter = (info) => {
    setVisibleNotify(true)
    setVisibleNotifyData({
      'title': 'Unpairing Lot',
      'type': 'pairing_tenant',
      'content': 
        <div style={{ margin: '0px' }}>
          <h3 style={{ margin: '0px', fontWeight:'400', paddingBottom:'10px', fontSize:'17px', borderBottom:'1px solid #303030'}}>Are you sure you want to unpair the following lot?</h3>
          <h3 style={{ margin: '0px', fontWeight:'400', marginTop:'10px'}}><strong>Lots: </strong> {info.lot_id}</h3>
          <h3 style={{ margin: '0px', fontWeight:'400', marginTop:'5px' }}><strong>Meters: </strong> {info.meter_id}</h3>
        </div>,
      'key': info.key
    })
  }

  const table_columns = [
    {
      title: "Lot ID",
      dataIndex: "lot_id",
      key: "lot_id",
    },
    {
      title: "Meter ID",
      dataIndex: "meter_id",
      key: "meter_id",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (info, record) => {
        return (
          <div style={{display:'flex'}}>
            <FunctionalButton
              buttonClickFunction={() => showPairingTenant(record)}
              custom_button_style={{ width: "150px", marginRight:'10px' }}
              children="Pairing Tenants"
            />
            <FunctionalButton
              buttonClickFunction={() => unPairingMeter(record)}
              custom_button_style={{ width: "150px" }}
              children="Unpairing Meter"
            />
          </div>
        );
      },
    },
  ];

  return table_columns;
};

export default TableColumns;
