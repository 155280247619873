import { Button, Divider, Flex, Form, Typography } from "antd";
import CustomCard from "../../../common/CustomCard";
import CustomModal from "../../../common/CustomModal";
import CustomFormItems from "../../../common/CustomFormItems";
import FunctionalButton from "../../../common/FunctionalButton";
import { useEffect, useState } from "react";
import CustomEditTable from "../../../common/CustomEditTable";

const { Text } = Typography;

const TENANT_FORM_PROPERTIES = [
  {
    type: "select",
    name: "tenant_name",
    label: "Tenant Name",
  },
];

export default function LotsManagementForm({
  form,
  formType,
  visible,
  visibleData,
  setVisibleData,
  handleFinish,
  handleCancel,
  lotAdd = false,
}) {
  const [deleteDisable, setDeleteDisable] = useState(false);

  const onFinish = (value) => {
    const combinedValue = {
      visibleData,
      ...value,
    };
    handleFinish(combinedValue);
  };

  useEffect(() => {
    if (visibleData) {
      form.setFieldsValue(visibleData);
    }

    if ("meter_id_data" in visibleData) {
      if (visibleData["meter_id_data"].length <= 1) {
        setDeleteDisable(true);
      } else {
        setDeleteDisable(false);
      }
    }
  }, [visibleData]);

  return (
    <CustomModal
      visible={visible}
      onCancel={handleCancel}
      title={
        <h4 style={{ margin: "0px", fontWeight: "500" }}>Edit Lot Info</h4>
      }
      title_style={{
        background: "#1F1F1F",
      }}
      modal_style={{
        marginTop: "",
      }}
      content={
        <CustomCard
          title="Lot Details"
          card_style={{ height: "100%" }}
          title_style={{
            background: "#303030",
            padding: "6px 16px",
            borderRadius: "0px",
            fontSize: "16px",
            fontWeight: "400",
          }}
          children_style={{ padding: "0px", background: "#141414" }}
          children={
            <>
              <Form form={form} onFinish={onFinish}>
                <CustomEditTable
                  form={form}
                  columnType={{
                    title: "Lot ID",
                    dataIndex: "lot_id",
                    inputType: "text",
                    width: "240px",
                    editable: true,
                  }}
                  data={visibleData["lot_id_data"]}
                  setData={setVisibleData}
                  handleDataType="lot_id_data"
                  addDataType="lot_id"
                  addFunction={lotAdd}
                  addButtonText="Insert New Lot"
                  deleteFunction={lotAdd}
                />

                {formType === "Inactive" || formType === "Active" ? (
                  <>
                    <CustomEditTable
                      form={form}
                      columnType={{
                        title: "Meter ID",
                        dataIndex: "meter_id",
                        inputType: "select",
                        width: "240px",
                        options: [
                          { label: "Meter 1", value: "meter-1" },
                          { label: "Meter 2", value: "meter-2" },
                        ],
                        editable: true,
                      }}
                      data={visibleData["meter_id_data"]}
                      setData={setVisibleData}
                      handleDataType="meter_id_data"
                      addDataType="meter_id"
                      addFunction={true}
                      addButtonText="Insert New Meter"
                      deleteFunction={true}
                      deleteDisabled={deleteDisable}
                    />
                  </>
                ) : null}

                {formType == "Active" && (
                  <>
                    <div style={{ padding: "16px", background: "#222222" }}>
                      <Text style={{ fontSize: "16px" }}>Tenant Details</Text>
                    </div>
                    <CustomFormItems
                      form_properties={TENANT_FORM_PROPERTIES}
                      form_item_style={{ padding: "16px" }}
                    />
                  </>
                )}
                <Divider />
                <Flex
                  gap="middle"
                  justify="flex-end"
                  style={{ padding: "16px 20px" }}
                >
                  <Form.Item>
                    <Button
                      onClick={handleCancel}
                      style={{ background: "#303030", width: "100px" }}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <FunctionalButton
                      buttonClickFunction={() => {}}
                      custom_button_style={{
                        background: "#303030",
                        width: "120px",
                      }}
                      htmlType={true}
                      children="Save Info"
                    />
                  </Form.Item>
                </Flex>
              </Form>
            </>
          }
        />
      }
    />
  );
}
