
import { Row, Col } from 'antd';
import { useState, useEffect, useContext } from "react";
import table_column from "./TableColumn"
import DataTable from "../../../common/DataTable";
import CustomModal from "../../../common/CustomModal";
import CustomCard from "../../../common/CustomCard";
import FunctionalButton from "../../../common/FunctionalButton";
import useUpdatePendingDataInvoices from "../../../../functions/Invoice/invoice_generation_func/updatePendingDataInvoices";
import { IndexContext } from '../../../../context/IndexContext';

export default function PendingInvoicesTable({
    pendingInvoices,
    invoiceFilter,
    setRefresh
}) {
    const [visible, setVisible] = useState(false);
    const [visibleData, setVisibleData] = useState({});
    const {updatePendingDataInvoices, loading} = useUpdatePendingDataInvoices();
    const { isLightTheme } = useContext(IndexContext);

    const columns = table_column({
        setVisible,
        visible,
        setVisibleData,
        isLightTheme
    });

    useEffect(()=>{
        if(loading == false){
            setVisible(false)
        }
    },[loading])

    const generatedInvoiceFunc = () => {
        return new Promise((resolve, reject) => {
            updatePendingDataInvoices(visibleData['pending_invoice_id'], invoiceFilter).then(()=>{
                resolve()
                setRefresh(true)
            })
            .catch(reject);
        });
    };

    const handleCancel = () => {
        setVisible(false);
    };
  
    const [accountSummary, setAccountSummary] = useState()
    const [invoiceDetails, setInvoiceDetails] = useState()
    const [energyDetails, setEnergyDetails] = useState()

    useEffect(()=>{
        setAccountSummary(visibleData.account_summary)
        setInvoiceDetails(visibleData.invoice_details)
        setEnergyDetails(visibleData.energy_details)
    },[visibleData])
    
    return (
        <>
            <DataTable temp_table_height={440} table_column={columns} table_data={pendingInvoices} />
            <CustomModal
                visible={visible}
                onCancel={handleCancel}
                title={
                    <h4 style={{ margin: '0px', fontWeight: '500' }}>Confrim Invoice Details</h4>
                }
                title_style={{
                    background: '#1F1F1F'
                }}
                modal_style={{
                    marginTop: '4%'
                }}
                content={
                    <CustomCard
                        title='Account Summary'
                        card_style={{ height: '100%' }}
                        title_style={{ background: '#303030', padding: '6px 16px', borderRadius: '0px', fontSize: '16px', fontWeight: '400' }}
                        children_style={{ padding: '3px 16px', background: '#141414' }}
                        children={
                            <Row>
                                {
                                    accountSummary != null ?
                                    accountSummary.map((each_value)=>{
                                        return (
                                            <Row style={{ width: '100%', padding: '3px 0px' }}>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <h3 style={{ margin: '0px', fontWeight: '400' }}>{each_value.title} :</h3>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{each_value.value}</h3>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                    :
                                    ''
                                }
                            </Row>
                        }
                        subtitles={[
                            {
                                title: 'Invoice Details',
                                children:
                                    <Row>
                                        {
                                            invoiceDetails != null ?
                                            invoiceDetails.map((each_value)=>{
                                                return (
                                                    <Row style={{ width: '100%', padding: '3px 0px' }}>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400' }}>{each_value.title} :</h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{each_value.value}</h3>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                            :
                                            ''
                                        }
                                    </Row>
                            },
                            {
                                title: 'Energy Details',
                                children:
                                    <Row>
                                       {
                                            energyDetails != null ?
                                            energyDetails.map((each_value)=>{
                                                return (
                                                    <Row style={{ width: '100%', padding: '3px 0px' }}>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400' }}>{each_value.title} :</h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{each_value.value} {each_value.unit}</h3>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                            :
                                            ''
                                        }
                                    </Row>
                            }
                        ]}
                    />
                }
                content_style={{
                    padding: '0px'
                }}
                footer_style={{
                    padding: '10px 10px',
                }}
                footer={
                    <div style={{ display: 'flex' }}>
                        <FunctionalButton
                            buttonClickFunction={handleCancel}
                            custom_button_style={{ marginRight: '10px', width: '100px', padding: '3px 5px', marginRight: '6px' }}
                            disabled={loading}
                        >
                            Close
                        </FunctionalButton>
                        <FunctionalButton
                            trigger_loading={true}
                            buttonClickFunction={generatedInvoiceFunc}
                            custom_button_style={{ marginRight: '10px', width: '150px', padding: '3px 5px', marginRight: '6px', background: 'rgb(40, 40, 40)' }}
                        >
                            Generated Invoices
                        </FunctionalButton>
                    </div>
                }
            />
        </>
    )
}

