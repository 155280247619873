import CustomCard from "../../../common/CustomCard";
import CustomModal from "../../../common/CustomModal";
import CustomForm from "../../../common/CustomForm";
import { useEffect } from "react";

export default function SitesManagementForm({
  form,
  visible,
  modalTitle,
  handleFinish,
  handleCancel,
  visibleData,
  tariffOption,
  loading,
}) {
  const SITE_MANAGEMENT_FORM_PROPERTIES = [
    {
      type: "input",
      name: "site_name",
      label: "Site Name",
    },
    {
      type: "input",
      name: "short_name",
      label: "Short Name",
    },
    {
      type: "select",
      name: "tariff_type",
      label: "Tariff Type",
      options: tariffOption,
    },
    {
      type: "input-number",
      name: "alarm_upper_limit",
      label: "Energy Upper Limit",
    },
    {
      type: "input-number",
      name: "alarm_lower_limit",
      label: "Energy Lower Limit",
    },
  ];

  useEffect(()=>{
    if(visible == false){
      form.resetFields();
    }
  },[visible])

  return (
    <CustomModal
      visible={visible}
      onCancel={handleCancel}
      title={<h4 style={{ margin: "0px", fontWeight: "500" }}>{modalTitle}</h4>}
      title_style={{
        background: "#1F1F1F",
      }}
      modal_style={{
        marginTop: "",
      }}
      content={
        <CustomCard
          title="Site Details"
          card_style={{ height: "100%" }}
          title_style={{
            background: "#303030",
            padding: "6px 16px",
            borderRadius: "0px",
            fontSize: "16px",
            fontWeight: "400",
          }}
          children_style={{ padding: "3px 16px", background: "#141414" }}
          children={
            <CustomForm
              form={form}
              formProperties={SITE_MANAGEMENT_FORM_PROPERTIES}
              handleFinish={handleFinish}
              handleCancel={handleCancel}
              visibleData={visibleData}
              loading={loading}
            />
          }
        />
      }
    />
  );
}
