import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetActiveTenants() {
  const [fetchWrapper] = useFetch();
  const [activeTenants, setActiveTenants] = useState([]);
  const [activeTenantsLoading, setActiveTenantsLoading] = useState(false);

  const getActiveTenants = useCallback((filter, setRefresh) => {
    setActiveTenantsLoading(true)
    fetchWrapper({
      params: filter,
      endpoint_url: "settings/getActiveTenants",
      onSuccess: (fetch_output) => {
        setActiveTenants(fetch_output.active_tenants);
        setActiveTenantsLoading(false)
        setRefresh(false)
      },
    });
  }, [fetchWrapper]);

  return { getActiveTenants, activeTenants, activeTenantsLoading};
}

export default useGetActiveTenants;
