import { useState, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetMissingDataInvoices() {
    const [fetchWrapper] = useFetch();
    const [missingDataInvoices, setMissingDataInvoices] = useState([])

    const getMissingDataInvoices = useCallback((filter) => {
        fetchWrapper({
            endpoint_url: "invoice/getMissingDataInvoices",
            params: filter,
            onSuccess: (fetch_output) => {
                setMissingDataInvoices(fetch_output.missing_data_invoices)
            },
        });
    }, [fetchWrapper]);

    return { getMissingDataInvoices, missingDataInvoices };
}

export default useGetMissingDataInvoices;
