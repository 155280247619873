import { useEffect, useState, useRef } from "react";
import TenantListsFilterCard from "../../components/tenant_lists/TenantListsFilterCard";
import TenantListsTableCard from "../../components/tenant_lists/TenantListsTableCard";
import { Col, Row } from "antd";
import "../../styles/tenant_lists/TenantLists.css";

function TenantListsPage() {
  const filterContainerRef = useRef();
  const [filter, setFilter] = useState({});
  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateAvailableHeight = () => {
      setAvailableHeight(window.innerHeight - 150);
    };

    updateAvailableHeight();
    window.addEventListener("resize", updateAvailableHeight);
    return () => {
      window.removeEventListener("resize", updateAvailableHeight);
    };
  }, []);

  return (
    <>
      <Row style={{ height: "100%" }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          style={{ paddingBottom: "10px" }}
        >
          <div ref={filterContainerRef}>
            <TenantListsFilterCard filter={filter} setFilter={setFilter} />
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          style={{ height: availableHeight }}
        >
          <TenantListsTableCard
            filter={filter}
          />
        </Col>
      </Row>
    </>
  );
}

export default TenantListsPage;
