import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Row, Col, Button, message, Upload } from 'antd';
import FunctionalButton from "../../common/FunctionalButton";
import CustomModal from "../../common/CustomModal";
import CustomProgressModal from '../../common//CustomProgress'; 
import ManualInvoicesProgressTable from "../left_card_components/manual_invoice_progress/ManualInvoicesProgressTable"
import useDownloadManuallyInvoiceExcelTemplates from "../../../functions/Invoice/manually_invoice_generation/downloadManuallyInvoiceExcelTemplates";
import useUploadManuallyInvoices from "../../../functions/Invoice/manually_invoice_generation/uploadManuallyInvoices";
import useGetManualInvoiceProgress from "../../../functions/Invoice/manually_invoice_generation/getManualInvoiceProgress";
import { IndexContext } from '../../../context/IndexContext';

const { TabPane } = Tabs;

export default function ManualInvoiceGeneration({
    filter, refresh, setRefresh, invoiceFilter
}) {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [openProgressModal, setOpenProgressModal] = useState(false)
    const [doneProgress, setDoneProgress] = useState(false)
    const [visible, setVisible] = useState(false);
    const {downloadManuallyInvoiceExcelTemplates} = useDownloadManuallyInvoiceExcelTemplates();
    const {uploadManullyInvoices} = useUploadManuallyInvoices();
    const { getManualInvoiceProgress, manualInvoiceProgress } = useGetManualInvoiceProgress();
    const { isLightTheme } = useContext(IndexContext);

    const handleUpload = () => {
        setUploading(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const showManualInvoiceProgress = () => {
        setVisible(true);
    };

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            message.success('Remove file successfully.');
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
        return false;
        },
        fileList,
    };

    const uploadFile = () => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            fileList.forEach((file) => {
                formData.append('uploaded-files', file)
                formData.append('site_info', filter['site_id'])
                formData.append('invoice_type', invoiceFilter['invoice_type'])
                formData.append('month', invoiceFilter['month'])
                formData.append('year', invoiceFilter['year'])
            });
            // Call the uploadManullyInvoices function
            uploadManullyInvoices(formData).then(() => {
                setRefresh(true)
                setFileList([])
                getManualInvoiceProgress()
                resolve();
            }).catch((err)=>{
                resolve(err);
            });

        });
    }
        
    useEffect(()=>{
        if(doneProgress == true){
           setFileList([])
        }
    },[doneProgress])


    useEffect(()=>{
        if(fileList.length != 0){
            message.success('upload successfully.');
        }
    },[fileList])

    useEffect(()=>{
        getManualInvoiceProgress()
    },[refresh])

    return (
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{paddingBottom:'10px'}}>
                    <FunctionalButton
                        buttonClickFunction={showManualInvoiceProgress}
                        children={manualInvoiceProgress.length == 0 ? 'Manual Invoice Progress ( 0 )' : 'Manual Invoice Progress ' + '( ' + manualInvoiceProgress.length.toString() + ' )'}
                        custom_button_style={{
                            backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                            border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                        }}
                        type={'default'}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{paddingBottom:'10px'}}>
                    <FunctionalButton
                        buttonClickFunction={downloadManuallyInvoiceExcelTemplates}
                        children='Download Invoice Excel Template'
                        custom_button_style={{
                            backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                            border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                        }}
                        type={'default'}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{paddingBottom:'10px'}}>
                    <Upload style={{width:'100%'}} {...props}>
                        <FunctionalButton 
                            custom_button_style = {{
                                width: '100%',
                                backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                                border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                            }}
                            buttonClickFunction={handleUpload}
                            children='Upload Invoice'
                            disabled={fileList.length == 0 ? false : true}
                            type={'default'}
                        />
                    </Upload>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    {
                        fileList.length == 0 
                        ? '' :
                        <FunctionalButton 
                            custom_button_style= {{
                                background: '#037303',
                                backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                                border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                            }}
                            trigger_loading={true}
                            buttonClickFunction={uploadFile}
                            children='Upload File'
                            type={'default'}
                        />
                    }
                </Col>
            </Row>
            <CustomProgressModal 
                openProgressModal={openProgressModal}
                doneProgress={setDoneProgress}
                progress_info_list={[
                    {'item': 'Manual Invoice Quantity', 'value': '1,242'},
                    {'item': 'Completed Uploaded Invoices', 'value': '1,012'},
                    {'item': 'Remaining Invoices', 'value': '212'},
                    {'item': 'Estimate Time', 'value': '! Minutes 48 Seconds'}
                ]}
            />
            <CustomModal
                visible={visible}
                width={1400}
                onCancel={handleCancel}
                title={
                    <h4 style={{ margin: '0px', fontWeight: '500' }}>Manual Invoice Progress</h4>
                }
                title_style={{
                    background: '#303030'
                }}
                modal_style={{
                    marginTop: ''
                }}
                content={
                    <div style={{height:'525px'}}>
                        <ManualInvoicesProgressTable manualInvoiceProgress={manualInvoiceProgress}/>
                    </div>
                }
                content_style={{
                    padding: '0px 12px'
                }}
                footer_style={{
                    padding: '10px 10px',
                }}
                footer={
                    <div style={{ display: 'flex' }}>
                        <FunctionalButton
                            buttonClickFunction={handleCancel}
                            custom_button_style={{ 
                                marginRight: '10px', 
                                width: '100px', 
                                padding: '3px 5px', 
                                marginRight: '6px',
                                backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                                border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                            }}
                            type={'default'}
                        >
                            Close
                        </FunctionalButton>
                    </div>
                }
            />
        </>
    );
};
