import { Flex, Row, Col, Skeleton } from "antd"
import CustomCard from "../common/CustomCard"
import PropTypes from 'prop-types';
import { validateProp } from '../../hooks/useValidate';
import { useContext } from 'react';
import { IndexContext } from '../../context/IndexContext';

export default function DeviceHealthCard({
    data = {}
    ,isLoading = false
}) {
    const { isLightTheme } = useContext(IndexContext);

    return(
        <CustomCard
            title="Device Health"
            title_style={{padding: "6px 12px"}}
            extra={`Total Device = ${isLoading? 'N/A':data.total?? '-'}`}
            children_style={{padding: "15px", height: "100px"}}
            children={
                <Flex justify="center" align="center" style={{height: "-webkit-fill-available"}}>
                    <Row style={{height: "inherit", width: "100%"}}>
                        <Col span={8} style={{height: "inherit"}}>
                            <Skeleton loading={isLoading} paragraph={{rows: 1}} style={{paddingLeft: 10}} active>
                                <Flex vertical={true} justify="space-between" align="center" style={{height: "inherit", padding: "5px 10px 5px 0px"}}>
                                    <h2 style={{margin: 0}}>{data.normal ?? '-'}</h2>
                                    <div style={{fontWeight: "bold", fontSize: "0.85rem"}}>Normal</div>
                                </Flex>
                            </Skeleton>
                        </Col>
                        <Col span={8} style={{height: "inherit", borderLeft:isLightTheme? "2px solid black":"2px solid white", borderRight:isLightTheme? "2px solid black":"2px solid white"}}>
                            <Skeleton loading={isLoading} paragraph={{rows: 1}} style={{paddingLeft: 10}} active>
                                <Flex vertical={true} justify="space-between" align="center" style={{height: "inherit", padding: "5px 10px 5px 10px"}}>
                                    <h2 style={{margin: 0}}>{data.malfunction ?? '-'}</h2>
                                    <div style={{fontWeight: "bold", fontSize: "0.85rem"}}>Malfunction</div>
                                </Flex>
                            </Skeleton>
                        </Col>
                        <Col span={8} style={{height: "inherit"}}>
                            <Skeleton loading={isLoading} paragraph={{rows: 1}} style={{paddingLeft: 10}} active>
                                <Flex vertical={true} justify="space-between" align="center" style={{height: "inherit", padding: "5px 0px 5px 10px"}}>
                                    <h2 style={{margin: 0}}>{data.unconfigured ?? '-'}</h2>
                                    <div style={{fontWeight: "bold", fontSize: "0.85rem"}}>Unconfigured</div>
                                </Flex>
                            </Skeleton>
                        </Col>
                    </Row>
                </Flex>
            }
        />
    );
}

DeviceHealthCard.propTypes = {
    isLoading: validateProp('boolean'),
    data:  PropTypes.shape({
        normal: PropTypes.number,
        malfunction: PropTypes.number,
        unconfigured: PropTypes.number
    })
}