import { Form } from "antd";
import { IndexContext } from "../../../../context/IndexContext";
import { useEffect, useState, useContext} from "react";
import CustomModal from "../../../common/CustomModal";
import CustomCard from "../../../common/CustomCard";
import CustomTabs from "../../../common/CustomTabs";
import FunctionalButton from "../../../common/FunctionalButton";
import ActiveTenantsTable from "./active_tenants/ActiveTenantsTable";
import InactiveTenantsTable from "./inactive_tenants/InactiveTenantsTable";
import TenantsManagementForm from "./TenantsManagementForm";


import useGetActiveTenants from "../../../../functions/Settings/tenant_management/getActiveTenants";
import useGetInactiveTenants from "../../../../functions/Settings/tenant_management/getInactiveTenants";
import useInsertTenant from "../../../../functions/Settings/tenant_management/insertTenant";
import useUpdateTenant from "../../../../functions/Settings/tenant_management/updateTenant";
import useDeleteTenant from "../../../../functions/Settings/tenant_management/deleteTenant";

export default function TenantsManagementCard({
  tariffOption,
  siteOption,
  refresh,
  setRefresh,
  filter
}) {
  const [tenantManagementInsertForm] = Form.useForm();
  const [insertVisivle, setInsertVisible] = useState(false);

  const [tenantManagementEditForm] = Form.useForm();
  const [editVisible, setEditVisible] = useState(false);
  const [visibleData, setVisibleData] = useState({});
  const { messageApi } = useContext(IndexContext);

  const { getActiveTenants, activeTenants, activeTenantsLoading } = useGetActiveTenants();
  const { getInactiveTenants, inactiveTenants, inactiveTenantsLoading } = useGetInactiveTenants();
  const { insertTenant, insertTenantLoading } = useInsertTenant();
  const { updateTenant, updateTenantLoading } = useUpdateTenant();

  const [tenantsManagementQuantity, setTenantsManagementQuantity] = useState({
    active_tenants: 3,
    inactive_tenants: 1,
  });

  const [activeTenantsData, setActiveTenantsData] = useState([]);

  const [inactiveTenantsData, setInactiveTenantsData] = useState([]);

  function validateSiteInfo(object) {
    var target = true
    // Create an array of error messages for each empty field
    const errors = Object.entries(object)
        .filter(([key, value]) => value === undefined || value === null || value === '')
        .map(([key]) => {
          target = false
          messageApi.open({
            type: "error",
            content: `Field '${key}' cannot be empty`,
          });         
        }
      );
    
      return target
  }

  const handleInsertConfirm = (value) => {
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        insertTenant(value, filter).then((data) => {
          if('error' in data){
            console.log(data.error)
          } else {
            handleInsertCancel();
            setInactiveTenantsData([])
            getInactiveTenants(filter, setRefresh)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  };

  const handleInsertCancel = () => setInsertVisible(false);

  const handleEditConfirm = (value, key) => {
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        updateTenant(value, key, filter).then((data) => {
          if('error' in data){
            console.log(data.error)
          } else {
            handleEditCancel();
            // getActiveTenants()
            setInactiveTenantsData([])
            getInactiveTenants(filter, setRefresh)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  };

  const handleEditCancel = () => setEditVisible(false);

  const { deleteTenant } = useDeleteTenant()
  const [visibleNotify, setVisibleNotify] = useState(false)
  const [visibleNotifyData, setVisibleNotifyData] = useState({
    'title': '',
    'content': '',
    'type': '',
    'key': ''
  })

  const confirmNotify = () => {
    return new Promise((resolve, reject) => {
      deleteTenant(visibleNotifyData['key']).then((data) => {
        if('error' in data){
          console.log('')
        } else {
          cancelNotify();
          setInactiveTenantsData([])
          getInactiveTenants(filter, setRefresh)
        }
        resolve();
      }).catch((err)=>{
        resolve(err);
      }
    )}
    )
  }

  const cancelNotify = () => setVisibleNotify(false)

  useEffect(()=>{
    setTenantsManagementQuantity(tenantsManagementQuantity => ({
      ...tenantsManagementQuantity,
      active_tenants: activeTenants.length
    }));
    setActiveTenantsData(activeTenants)
  }, [activeTenants])

  useEffect(()=>{
    setTenantsManagementQuantity(tenantsManagementQuantity => ({
      ...tenantsManagementQuantity,
      inactive_tenants: inactiveTenants.length
    }));
    setInactiveTenantsData(inactiveTenants)
  }, [inactiveTenants])

  useEffect(()=>{
    if(refresh == true){
      setTenantsManagementQuantity({
        active_tenants: 0,
        inactive_tenants: 0,
      })
      setInactiveTenantsData([])
      setActiveTenantsData([])
      getActiveTenants(filter, setRefresh)
      getInactiveTenants(filter)
    }
  },[refresh])

  useEffect(()=>{
    setRefresh(true)
  },[])

  return (
    <>
      <CustomCard
        title="Tenants Management"
        extra={
          <FunctionalButton
            buttonClickFunction={() => setInsertVisible(true)}
            custom_button_style={{ background: "#141414", width: "200px" }}
            children="Insert New Tenants"
          />
        }
        card_style={{ height: "100%" }}
        title_style={{ background: "#303030", padding: "8px 12px" }}
        children_style={{ padding: "0px" }}
        children={
          <CustomTabs
            tab_style={{ background: "#1D1D1D" }}
            tab_children_styles={{
              background: "#141414",
              padding: "0px",
              height: "100%",
            }}
            tabs={[
              {
                title:
                  "Active Tenants ( " +
                  tenantsManagementQuantity["active_tenants"] +
                  " )",
                key: "1",
                content: (
                  <ActiveTenantsTable
                    activeTenantsData={activeTenantsData}
                    setVisible={setEditVisible}
                    visible={editVisible}
                    setVisibleData={setVisibleData}
                    activeTenantsLoading={activeTenantsLoading}
                  />
                ),
              },
              {
                title:
                  "Inactive Tenants ( " +
                  tenantsManagementQuantity["inactive_tenants"] +
                  " )",
                key: "2",
                content: (
                  <InactiveTenantsTable
                    setVisibleNotify={setVisibleNotify}
                    setVisibleNotifyData={setVisibleNotifyData}
                    inactiveTenantsData={inactiveTenantsData}
                    setVisible={setEditVisible}
                    visible={editVisible}
                    setVisibleData={setVisibleData}
                    inactiveTenantsLoading={inactiveTenantsLoading}
                  />
                ),
              },
            ]}
          />
        }
      />
      <TenantsManagementForm
        form={tenantManagementInsertForm}
        visible={insertVisivle}
        modalTitle={"Insert Tenants"}
        handleFinish={handleInsertConfirm}
        handleCancel={handleInsertCancel}
        tariffOption={tariffOption}
        siteOption={siteOption}
      />
      <TenantsManagementForm
        form={tenantManagementEditForm}
        visible={editVisible}
        modalTitle={"Edit Tenants"}
        handleFinish={handleEditConfirm}
        handleCancel={handleEditCancel}
        tariffOption={tariffOption}
        siteOption={siteOption}
        visibleData={visibleData}
      />
      <CustomModal
        title={
          <h4 style={{margin:'0px', fontWeight:'400'}}>{visibleNotifyData.title}</h4>
        }
        visible={visibleNotify}
        content={visibleNotifyData.content}
        content_style={{
          padding: '16px 16px',
          borderBottom: '1px solid #303030'
        }}
        footer={
          <div>
            <FunctionalButton
              buttonClickFunction={() => cancelNotify()}
              custom_button_style={{ width: "150px", marginRight:'10px' }}
              children="Cancel"
            />
            <FunctionalButton
              buttonClickFunction={() => confirmNotify()}
              custom_button_style={{ width: "150px" }}
              children="Confirm"
            />
          </div>
        }
      />
    </>
  );
}
