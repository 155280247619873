import React, { useState } from 'react';
import FunctionalButton from "../../../common/FunctionalButton";
import { VerticalAlignBottomOutlined } from '@ant-design/icons';

const TableColumns = ({setVisible, visible, setVisibleData, isLightTheme}) => {

    const showDetails = (info) => {
        setVisible(true)
        setVisibleData(info)
    };

    const table_columns = [
        {
            title: 'Tenant Name',
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            sorter: (a, b) => a.tenant_name.localeCompare(b.tenant_name)
        },
        {
            title: 'Lot',
            dataIndex: 'lot',
            key: 'lot',
            sorter: (a, b) => a.lot.localeCompare(b.lot)
        },
        {
            title: 'Previous Month',
            dataIndex: 'previous_month',
            key: 'previous_month'
        },
        {
            title: 'Last Online Reading (kWh)',
            dataIndex: 'last_online_reading',
            key: 'last_online_reading',
            sorter: (a, b) => a.last_online_reading.localeCompare(b.last_online_reading)
        },
        {
            title: 'Last Online (kWh)',
            dataIndex: 'last_online',
            key: 'last_online'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '200px',
            render: (info) => {
                return (
                    <div style={{display:'flex'}}>
                        <FunctionalButton 
                            buttonClickFunction={()=>{showDetails(info)}} 
                            custom_button_style={{
                                marginRight:'10px',
                                width:'150px',
                                background:isLightTheme? "#FFFFFF":"#141414",
                                color: isLightTheme? "#000000":"#ffffff",
                                borderColor: isLightTheme? "#D9D9D9":"#424242",
                                boxShadow: "unset"
                            }} 
                            children='Adjustment'
                        />
                    </div>
                )
            }
        }
    ];

    return table_columns;
};

export default TableColumns;
