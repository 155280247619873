import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import SummaryCard from './LeftCard/SummaryCard';
import EnergyConsumptionDistributionCard from './LeftCard/EnergyConsumptionDistributionCard';

export default function LeftCard({
    summaryData
    ,isSummaryCardLoading
    ,energyConsumptionDistributionData
    ,isEnergyConsumptionDistributionCardLoading
    ,displayUnit
}) {
    const [height, setHeight] = useState('auto');

    useEffect(() => {
      function handleResize() {
        const newHeight = window.innerWidth >= 1200 || window.innerHeight >= 1000 ? 'calc(100% - 220px)' : 'auto';
        setHeight(newHeight);
      }

      handleResize(); // Set initial height
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <Row style={{height: "100%"}}>
            <Col span={24} style={{paddingBottom:'10px'}}>
                <SummaryCard
                    summaryData={summaryData}
                    isSummaryCardLoading={isSummaryCardLoading}
                />
            </Col>
            <Col span={24} style={{height: height}}>
                <EnergyConsumptionDistributionCard
                    energyConsumptionDistributionData={energyConsumptionDistributionData}
                    isEnergyConsumptionDistributionCardLoading={isEnergyConsumptionDistributionCardLoading}
                    displayUnit={displayUnit}
                />
            </Col>
        </Row>
    );
} 