import FunctionalButton from "../../../../common/FunctionalButton";

const TableColumn = ({ setVisible, visible, setVisibleData }) => {
  const showDetails = (info) => {
    setVisible(true);
    setVisibleData(info);
  };

  const table_columns = [
    {
      title: "Meter ID",
      dataIndex: "device_id",
      key: "device_id",
    },
    {
      title: "Meter Type",
      dataIndex: "device_type",
      key: "device_type",
    },
    {
      title: "Utility Type",
      dataIndex: "utility_type",
      key: "utility_type",
    },
    {
      title: "Brand",
      dataIndex: "device_brand",
      key: "device_brand",
    },
    {
      title: "Model",
      dataIndex: "device_model",
      key: "device_model",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "200px",
      render: (info, record) => {
        return (
          <FunctionalButton
            buttonClickFunction={() => {
              showDetails(record);
            }}
            custom_button_style={{ Swidth: "150px" }}
            children="Edit"
          />
        );
      },
    },
  ];

  return table_columns;
};

export default TableColumn;
