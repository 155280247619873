import { Col, Flex, Input, Modal, Row } from "antd";
import dayjs from "dayjs";
import FunctionalButton from "../common/FunctionalButton";
import CustomModal from "../common/CustomModal";
import useFetch from "../../hooks/useFetch";
import { renderToStaticMarkup } from "react-dom/server";
import { useState } from "react";

export default function DeviceAlarmAcknowledgement({
  selectedAlarm,
  openModal,
  setOpenModal,
  setRefresh,
}) {
  const [fetchWrapper] = useFetch();
  const [remark, setRemark] = useState(null);

  function acknowledgeAlarm() {
    fetchWrapper({
      endpoint_url: "alarm/acknowledgeDeviceAlarm",
      params: {
        alarm_id: selectedAlarm.id,
        remark: remark,
      },
      onSuccess: (fetch_output) => {
        setRefresh(true);
        setOpenModal(false);
        setRemark(null);
      },
    });
  }

  return (
    <CustomModal
      visible={openModal}
      onCancel={() => setOpenModal(false)}
      title={<h4 style={{ margin: "0px", fontWeight: "500" }}>Acknowledge</h4>}
      title_style={{
        background: "#1F1F1F",
      }}
      modal_style={{
        marginTop: "",
      }}
      content={
        selectedAlarm != null ? (
          <div style={{ paddingInline: 20 }}>
            <Row gutter={[12, 12]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3 style={{ margin: "0px", fontWeight: "400" }}>Site :</h3>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3
                  style={{
                    margin: "0px",
                    fontWeight: "400",
                    textAlign: "right",
                  }}
                >
                  {selectedAlarm.site_name}
                </h3>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3 style={{ margin: "0px", fontWeight: "400" }}>Meter :</h3>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3
                  style={{
                    margin: "0px",
                    fontWeight: "400",
                    textAlign: "right",
                  }}
                >
                  {selectedAlarm.meter_name}
                </h3>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3 style={{ margin: "0px", fontWeight: "400" }}>Lots :</h3>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3
                  style={{
                    margin: "0px",
                    fontWeight: "400",
                    textAlign: "right",
                  }}
                >
                  {selectedAlarm.lots}
                </h3>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3 style={{ margin: "0px", fontWeight: "400" }}>Alarm :</h3>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3
                  style={{
                    margin: "0px",
                    fontWeight: "400",
                    textAlign: "right",
                  }}
                >
                  {selectedAlarm.alarm}
                </h3>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3 style={{ margin: "0px", fontWeight: "400" }}>
                  Start Time :
                </h3>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3
                  style={{
                    margin: "0px",
                    fontWeight: "400",
                    textAlign: "right",
                  }}
                >
                  {dayjs(selectedAlarm.start_datetime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </h3>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3 style={{ margin: "0px", fontWeight: "400" }}>End Time :</h3>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <h3
                  style={{
                    margin: "0px",
                    fontWeight: "400",
                    textAlign: "right",
                  }}
                >
                  {selectedAlarm.end_datetime != null
                    ? dayjs(selectedAlarm.end_datetime).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    : "-"}
                </h3>
              </Col>
              {selectedAlarm.acknowledged_on != null ? (
                <>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <h3 style={{ margin: "0px", fontWeight: "400" }}>
                      Acknowledge By :
                    </h3>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <h3
                      style={{
                        margin: "0px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      {selectedAlarm.username}
                    </h3>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <h3 style={{ margin: "0px", fontWeight: "400" }}>
                      Acknowledge On :
                    </h3>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <h3
                      style={{
                        margin: "0px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      {dayjs(selectedAlarm.acknowledged_on).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </h3>
                  </Col>
                </>
              ) : (
                <></>
              )}
              <Col span={24}>
                <h3 style={{ margin: "0px", fontWeight: "400" }}>Remark :</h3>
              </Col>
              <Col span={24}>
                <Input.TextArea
                  readOnly={selectedAlarm.acknowledged_on != null}
                  autoSize={{ minRows: 8, maxRows: 8 }}
                  value={
                    selectedAlarm.acknowledged_on != null
                      ? selectedAlarm.remark
                      : remark
                  }
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                ></Input.TextArea>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        )
      }
      footer={
        <Flex gap={10} justify="flex-end">
          <FunctionalButton
            children="Cancel"
            buttonClickFunction={() => setOpenModal(false)}
          ></FunctionalButton>
          {selectedAlarm?.acknowledged_on == null ? (
            <FunctionalButton
              children="Acknowledge"
              type="primary"
              buttonClickFunction={acknowledgeAlarm}
            ></FunctionalButton>
          ) : (
            <></>
          )}
        </Flex>
      }
    ></CustomModal>
  );
}
