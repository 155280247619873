import { Form } from "antd";
import { IndexContext } from "../../../../context/IndexContext";
import { useEffect, useState, useContext } from "react";
import CustomCard from "../../../common/CustomCard";
import CustomModal from "../../../common/CustomModal";
import TariffsManagementTable from "./table/TariffsManagementTable";
import TariffsManagementForm from "./TariffsManagementForm";
import FunctionalButton from "../../../common/FunctionalButton";

import useGetTariffs from "../../../../functions/Settings/tariff_management/getTariffs";
import useInsertTariff from "../../../../functions/Settings/tariff_management/insertTariff";
import useUpdateTariff from "../../../../functions/Settings/tariff_management/updateTariff";
import useDeleteTariff from "../../../../functions/Settings/tariff_management/deleteTariff";

export default function TariffsManagementCard({setRefresh, refresh}) {
  const [tariffsManagementInsertForm] = Form.useForm();
  const [insertVisible, setInsertVisible] = useState(false);
  const { messageApi } = useContext(IndexContext);

  const [tariffsManagementEditForm] = Form.useForm();
  const [editVisible, setEditVisible] = useState(false);
  const [visibleData, setVisibleData] = useState({});
  const [tariffsManagementQuantity, setTariffisManagementQuantity] = useState(1);

  const { getTariffs, tariffs  } = useGetTariffs();
  const { insertTariff, insertTariffLoading  } = useInsertTariff();
  const { updateTariff, updateTariffLoading } = useUpdateTariff();

  const [tariffsData, setTariffsData] = useState([]);

  function validateSiteInfo(object) {
    var target = true
    // Create an array of error messages for each empty field
    const errors = Object.entries(object)
        .filter(([key, value]) => value === undefined || value === null || value === '')
        .map(([key]) => {
          target = false
          messageApi.open({
            type: "error",
            content: `Field '${key}' cannot be empty`,
          });         
        }
      );
    
      return target
  }

  const handleInsertConfirm = (value) => {
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        insertTariff(value).then((data) => {
          if('error' in data){
            console.log(data.error)
          } else {
            handleInsertCancel();
            getTariffs(setRefresh)
            setTariffsData([])
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  };
  const handleInsertCancel = () => setInsertVisible(false);

  const handleEditConfirm = (value, key) => {
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        console.log(value, key)
        updateTariff(value, key).then((data) => {
          if('error' in data){
            console.log(data.error)
          } else {
            handleEditCancel();
            getTariffs(setRefresh)
            setTariffsData([])
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  };
  const handleEditCancel = () => setEditVisible(false);

  const { deleteTariff } = useDeleteTariff()
  const [visibleNotify, setVisibleNotify] = useState(false)
  const [visibleNotifyData, setVisibleNotifyData] = useState({
    'title': '',
    'content': '',
    'type': '',
    'key': ''
  })

  const confirmNotify = () => {
    return new Promise((resolve, reject) => {
      deleteTariff(visibleNotifyData['key']).then((data) => {
        if('error' in data){
          console.log('')
        } else {
          cancelNotify();
          getTariffs(setRefresh)
          setTariffsData([])
        }
        resolve();
      }).catch((err)=>{
        resolve(err);
      }
    )}
    )
  }

  const cancelNotify = () => setVisibleNotify(false)

  useEffect(()=>{
    setTariffisManagementQuantity(tariffs.length)
    setTariffsData(tariffs)
  },[tariffs])

  useEffect(()=>{
    if(refresh == true){
      getTariffs(setRefresh)
    }
  },[refresh])

  useEffect(()=>{
    setRefresh(true)
  },[])

  return (
    <>
      <CustomCard
        title={"Tariffs Management ( " + tariffsManagementQuantity + " )"}
        extra={
          <FunctionalButton
            buttonClickFunction={() => setInsertVisible(true)}
            custom_button_style={{ background: "#141414", width: "200px" }}
            children="Insert New Tariffs"
          />
        }
        card_style={{ height: "100%" }}
        title_style={{ background: "#303030", padding: "8px 12px" }}
        children_style={{ padding: "0px" }}
        children={
          <TariffsManagementTable
            setVisibleNotify={setVisibleNotify}
            setVisibleNotifyData={setVisibleNotifyData}
            tariffsManagementData={tariffsData}
            setVisible={setEditVisible}
            visible={editVisible}
            setVisibleData={setVisibleData}
          />
        }
      />

      <TariffsManagementForm
        form={tariffsManagementInsertForm}
        visible={insertVisible}
        modalTitle={"Insert Tariff"}
        handleFinish={handleInsertConfirm}
        handleCancel={handleInsertCancel}
      />
      <TariffsManagementForm
        form={tariffsManagementEditForm}
        visible={editVisible}
        modalTitle={"Edit Tariff"}
        handleFinish={handleEditConfirm}
        handleCancel={handleEditCancel}
        visibleData={visibleData}
      />
      <CustomModal
        title={
          <h4 style={{margin:'0px', fontWeight:'400'}}>{visibleNotifyData.title}</h4>
        }
        visible={visibleNotify}
        content={visibleNotifyData.content}
        content_style={{
          padding: '16px 16px',
          borderBottom: '1px solid #303030'
        }}
        footer={
          <div>
            <FunctionalButton
              buttonClickFunction={() => cancelNotify()}
              custom_button_style={{ width: "150px", marginRight:'10px' }}
              children="Cancel"
            />
            <FunctionalButton
              buttonClickFunction={() => confirmNotify()}
              custom_button_style={{ width: "150px" }}
              children="Confirm"
            />
          </div>
        }
      />
    </>
  );
}
