import { useEffect, useState, useRef } from "react";
import MeterListsFilterCard from "../../components/meter_lists/MeterListsFilterCard";
import MeterListsTableCard from "../../components/meter_lists/MetereListsTableCard";
import { Col, Row } from "antd";
import "../../styles/meter_lists/MeterLists.css";

function MeterListsPage() {
  const filterContainerRef = useRef();
  const [filter, setFilter] = useState({});
  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateAvailableHeight = () => {
      setAvailableHeight(window.innerHeight - filterContainerRef?.current?.offsetHeight - 80);
    };

    updateAvailableHeight();
    window.addEventListener("resize", updateAvailableHeight);
    return () => {
      window.removeEventListener("resize", updateAvailableHeight);
    };
  }, []);

  return (
    <>
      <Row style={{ height: "100%" }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          style={{ paddingBottom: "10px" }}
        >
          <div ref={filterContainerRef}>
            <MeterListsFilterCard filter={filter} setFilter={setFilter} />
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          style={{ height: availableHeight }}
        >
          <MeterListsTableCard
            filter={filter}
          />
        </Col>
      </Row>
    </>
  );
}

export default MeterListsPage;
