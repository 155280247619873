import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetVacantMeterOptions() {
  const [fetchWrapper] = useFetch();
  const [vacantMeterOptions, setVacantMeterOptions] = useState([]);

  const getVacantMeterOptions = useCallback((filter) => {
    fetchWrapper({
      endpoint_url: "settings/getVacantMeterOptions",
      params: filter,
      onSuccess: (fetch_output) => {
        setVacantMeterOptions(fetch_output.vacant_meter_options);
      },
    });
  }, [fetchWrapper]);

  return { getVacantMeterOptions, vacantMeterOptions };
}

export default useGetVacantMeterOptions;
