import { useState, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetManualGeneratedInvoices() {
    const [fetchWrapper] = useFetch();
    const [manualGeneratedInvoices, setManualGeneratedInvoices] = useState([])

    const getManualGeneratedInvoices = useCallback((filter) => {
        fetchWrapper({
            endpoint_url: "invoice/getManualGeneratedInvoices",
            params: filter,
            onSuccess: (fetch_output) => {
                setManualGeneratedInvoices(fetch_output.manual_generated_invoices)
            },
        });
    }, [fetchWrapper]);

    return { getManualGeneratedInvoices, manualGeneratedInvoices };
}

export default useGetManualGeneratedInvoices;
