import CustomCard from "../../../common/CustomCard";
import CustomForm from "../../../common/CustomForm";
import CustomModal from "../../../common/CustomModal";
import { useEffect } from "react";

export default function TenantsManagementForm({
  form,
  visible,
  modalTitle,
  handleFinish,
  handleCancel,
  visibleData,
  tariffOption,
  siteOption
}) {
  
  const TENANT_MANAGEMENT_FORM_PROPERTIES = [
    {
      type: "input",
      name: "tenant_name",
      label: "Tenant Name",
    },
    {
      type: "input",
      name: "pic_name",
      label: "PIC Name",
    },
    {
      type: "input",
      name: "email",
      label: "Email",
    },
    {
      type: "input",
      name: "phone",
      label: "Phone",
    },
    {
      type: "input",
      name: "account_number",
      label: "Account Number",
    },
    {
      type: "select",
      name: "tariff",
      label: "Tariff",
      options: tariffOption
    },
  ];

  
  useEffect(()=>{
    if(visible == false){
      form.resetFields();
    }
  },[visible])

  return (
    <CustomModal
    visible={visible}
      onCancel={handleCancel}
      title={<h4 style={{ margin: "0px", fontWeight: "500" }}>{modalTitle}</h4>}
      title_style={{
        background: "#1F1F1F",
      }}
      modal_style={{
        marginTop: "",
      }}
      content={
        <CustomCard
          title="Tenant Details"
          card_style={{ height: "100%" }}
          title_style={{
            background: "#303030",
            padding: "6px 16px",
            borderRadius: "0px",
            fontSize: "16px",
            fontWeight: "400",
          }}
          children_style={{ padding: "3px 16px", background: "#141414" }}
          children={
            <CustomForm
              form={form}
              formProperties={TENANT_MANAGEMENT_FORM_PROPERTIES}
              handleFinish={handleFinish}
              handleCancel={handleCancel}
              visibleData={visibleData}
            />
          }
        />
      }
    />
  );
}
