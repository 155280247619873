import { Flex } from "antd";
import { backend_url } from "../../../../../configs/configs";
import FunctionalButton from "../../../../common/FunctionalButton";

const TableColumns = ({ setVisibleNotifyData, setVisibleNotify, setVisible, setVisibleData }) => {
  const showDetails = (info) => {
    setVisible(true);
    setVisibleData(info);
  };

  const remove_site = (info) => {
    setVisibleNotify(true);
    setVisibleNotifyData({
      'title': 'Delete Site',
      'type': 'delete_site',
      'content': 
        <div style={{ margin: '0px' }}>
          <h3 style={{ margin: '0px', fontWeight:'400', paddingBottom:'10px', fontSize:'17px', borderBottom:'1px solid #303030'}}>Are you sure you want to delete the following site?</h3>
          <h3 style={{ margin: '0px', fontWeight:'400', marginTop:'10px'}}><strong>Site Name: </strong> {info.site_name}</h3>
        </div>,
      'key': info.key
    });
  };

  const table_columns = [
    {
      title: "Site Name",
      dataIndex: "site_name",
      key: "site_name",
    },
    {
      title: "Short Name",
      dataIndex: "short_name",
      key: "short_name",
    },
    {
      title: "Tariff Type",
      dataIndex: "tariff_type",
      key: "tariff_type",
    },
    {
      title: "Tenant Quantity",
      dataIndex: "tenant_quantity",
      key: "tenant_quantity",
    },
    {
      title: "Meter Quantity",
      dataIndex: "meter_quantity",
      key: "meter_quantity",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "300px",
      render: (info, record) => {
        return (
          <Flex gap="middle">
            <FunctionalButton
              buttonClickFunction={() => {
                showDetails(record);
              }}
              custom_button_style={{ width: "150px" }}
              children="Edit"
            />
            <FunctionalButton
              disabled={record.disabled}
              buttonClickFunction={() => {
                remove_site(record);
              }}              
              custom_button_style={{ width: "150px", background: record.disabled ? "#282828" : 'red' }}
              children="Delete"
            />
          </Flex>
        );
      },
    },
  ];

  return table_columns;
};

export default TableColumns;
