import { Row, Col, Tree } from "antd";
import CustomCard from "../common/CustomCard";
import { useEffect, useState } from "react";
import { icons } from "antd/es/image/PreviewGroup";
import { HomeOutlined } from "@ant-design/icons";
// import InvoiceIDSettings from "./left_card_components/InvoiceIDSettings";
// import ManualInvoiceGeneration from "./left_card_components/ManualInvoiceGeneration";
// import ThirdPartyExcelTemplates from "./left_card_components/ThirdPartyExcelTemplates";
const SETTING_TREE_OPTION = [
  {
    title: "Organization",
    key: "organization",
    disabled: true,
    children: [
      {
        title: "Sites Management",
        key: "site_management",
      },
    ],
  },
  {
    title: "Meters",
    key: "meters",
    disabled: true,
    children: [
      {
        title: "Lots Management",
        key: "lots_management",
      },
      {
        title: "Meters Management",
        key: "meters_management",
      },
      // {
      //   title: "Check Meter Pairing",
      //   key: "check_meter_pairing",
      // },
    ],
  },
  {
    title: "Tenants",
    key: "tenants",
    disabled: true,
    children: [
      {
        title: "Tenants Management",
        key: "tenants_management",
      },
    ],
  },
  {
    title: "Invoices",
    key: "invoices",
    disabled: true,
    children: [
      {
        title: "Tariffs Management",
        key: "tariffs_management",
      },
      {
        title: "Check Invoice Type",
        key: "check_invoice_type",
      },
    ],
  },
];

function LeftCard({ settingTree, setSettingTree }) {
  const [height, setHeight] = useState("auto");

  useEffect(() => {
    function handleResize() {
      const newHeight = window.innerWidth >= 1200 ? "calc(100%)" : "auto";
      setHeight(newHeight);
    }

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (settingTree.length == 0) {
      setSettingTree(["site_management"]);
    }
  }, [settingTree]);
  return (
    <Row style={{ height: "100%" }}>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        style={{ height: height }}
      >
        <CustomCard
          card_style={{ height: "100%" }}
          title="Settings"
          children_style={{ padding: "0px", background: "#141414" }}
          children={
            <Tree
              treeData={SETTING_TREE_OPTION}
              virtual={true}
              defaultExpandAll={true}
              className="custom-tree"
              onSelect={setSettingTree}
              selectedKeys={settingTree}
            />
          }
        />
      </Col>
    </Row>
  );
}

export default LeftCard;
