import { Column } from "@antv/g2plot";
import { useEffect, useRef } from "react";
import { Spin, Empty } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { IndexContext } from '../../../context/IndexContext';
import "../../../styles/dashboard/DashboardPage.css"

export default function MeterTrendingChart({
    containerName
    ,data
    ,parameterSelection
    ,isLoading
    ,isShowChart
}){
    const chartRef = useRef();
    const { isLightTheme } = useContext(IndexContext);

    useEffect(()=>{
        if(!isLoading && isShowChart){
            chartRef.current = new Column(containerName, {
                data: data,
                padding: "auto",
                xField: "date",
                yField: "value",
                yAxis: {
                    title: {
                        text: parameterSelection,
                        style: {
                            fill: isLightTheme? "#000000":"#FFFFFF"
                        }
                    },
                },
                slider: {
                    start: 0.7,
                    end: 1,
                },
                tooltip: {
                    visible: true,
                    formatter: (value, ctg) => {
                        if (
                            parameterSelection == "I1" ||
                            parameterSelection == "I2" ||
                            parameterSelection == "I3"
                        ) {
                            return {
                            name: parameterSelection,
                            value: value["value"] + " A",
                            };
                        } else if (
                            parameterSelection == "V1" ||
                            parameterSelection == "V2" ||
                            parameterSelection == "V3"
                        ) {
                            return {
                            name: parameterSelection,
                            value: value["value"] + " V",
                            };
                        } else {
                            return {
                            name: parameterSelection,
                            value: value["value"],
                            };
                        }
                    },
                },
            })
            chartRef.current?.render()
        }
    },[containerName, isLoading, isShowChart])

    useEffect(()=>{
        if(!isLoading && isShowChart){
            chartRef.current?.changeData(data)
        }
    },[data])

    useEffect(()=>{
        chartRef.current?.update(
            {
                yAxis: {
                    title: {
                        text: parameterSelection,
                    },
                },
                tooltip: {
                    visible: true,
                    formatter: (value, ctg) => {
                        if (
                            parameterSelection == "I1" ||
                            parameterSelection == "I2" ||
                            parameterSelection == "I3"
                        ) {
                            return {
                            name: parameterSelection,
                            value: value["value"] + " A",
                            };
                        } else if (
                            parameterSelection == "V1" ||
                            parameterSelection == "V2" ||
                            parameterSelection == "V3"
                        ) {
                            return {
                            name: parameterSelection,
                            value: value["value"] + " V",
                            };
                        } else {
                            return {
                            name: parameterSelection,
                            value: value["value"],
                            };
                        }
                    },
                },
            }
        )
    },[parameterSelection])

    useEffect(()=>{
        if((isLoading || !isShowChart) && !chartRef?.current?.chart?.destroyed){
            chartRef?.current?.destroy()
        }
    },[isLoading, isShowChart])

    useEffect(()=>{
        chartRef.current?.update(
            {
                yAxis: {
                    title: {
                        text: parameterSelection,
                        style: {
                            fill: isLightTheme? "#000000":"#FFFFFF"
                        }
                    },
                },
            }
        )
    },[isLightTheme])

    return(
        <Spin
            wrapperClassName="meter-trending-loading"
            indicator={<LoadingOutlined spin />}
            spinning={isLoading}
            size="large"
        >   
            {
                !isShowChart && !isLoading? <div className="empty-container"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>
                                            :
                                            <div id={containerName} style={{height: "100%", width: "100%"}}></div>
            }
        </Spin>
    );
}