import React, { useState } from 'react';
import FunctionalButton from "../../../common/FunctionalButton";
import { VerticalAlignBottomOutlined } from '@ant-design/icons';

const TableColumns = ({
    setVisible, visible, setVisibleData
}) => {

    const showDetails = (info) => {
        setVisible(true)
        setVisibleData(info)
    };

    const table_columns = [
        {
            title: 'File Name',
            dataIndex: 'file_name',
            key: 'file_name',
            width: '50px'
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '50px',
        },
        {
            title: 'Remaining',
            dataIndex: 'remaining',
            key: 'remaining',
            width: '50px'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '50px',
            filters: [
                {
                    text: 'Pending',
                    value: 'true'
                },
                {
                  text: 'Processing',
                  value: 'InProgress'
                },
                {
                  text: 'Issued',
                  value: 'Issued'
                }
            ],
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            render: (info) =>{
                if(info == 'InProgress'){
                    return 'Processing'
                }else if(info == 'true'){
                    return 'Pending'
                } else {
                    return info
                }
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '50px',
            render: (info, record) => {
                return (
                    <div style={{display: record.status == 'Issues' ? 'flex' : 'none'}}>
                        <FunctionalButton buttonClickFunction={()=>{showDetails(info)}} custom_button_style={{marginRight:'10px', width:'200px'}} children='Details'/>
                    </div>
                )
            }
        }
    ];

    return table_columns;
};

export default TableColumns;
