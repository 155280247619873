import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Form } from 'antd';
import CustomFormItems from "../../common/CustomFormItems";
import FunctionalButton from "../../common/FunctionalButton";
import useGetInvoiceIdSettings from "../../../functions/Invoice/invoice_id_settings_func/getInvoiceIdSettings";
import useUpdateInvoiceIdSettings from "../../../functions/Invoice/invoice_id_settings_func/updateInvoiceIdSettings";
import { IndexContext } from '../../../context/IndexContext';

const { TabPane } = Tabs;

export default function InvoiceIDSettings({
    invoiceFilter, setRefresh, refresh
}) {
    const { isLightTheme } = useContext(IndexContext);
    const [invoiceSettingsForm] = Form.useForm();
    const [formInvoiceSettingProperties, setFormInvoiceSettingProperties] = useState([]);
    const {getInvoiceIdSettings, invoiceIdSettings} = useGetInvoiceIdSettings();
    const {updateInvoiceIdSettings} = useUpdateInvoiceIdSettings();

    useEffect(()=>{
        if(invoiceFilter != null && invoiceFilter.length != 0){
            getInvoiceIdSettings(invoiceFilter, setRefresh)
        }
    },[invoiceFilter, refresh])

    useEffect(()=>{
        if(invoiceIdSettings != null){
            invoiceSettingsForm.setFieldsValue({
                'next_invoice_id': invoiceIdSettings.next_invoice_id,
                'started_invoice_id': invoiceIdSettings.started_invoice_id,
            })
            setFormInvoiceSettingProperties([
                {
                    type: 'input',
                    name: 'next_invoice_id',
                    label: 'Next Invoice ID',
                    disabled: true
                },
                {
                    type: 'input',
                    name: 'started_invoice_id',
                    label: 'Started Invoice ID',
                    maxLength: 4,
                    addon_before: invoiceIdSettings.invoice_type,
                }
            ])
        }
    },[invoiceIdSettings])
    
    function setNewInvoiceID(values){
        updateInvoiceIdSettings(values, setRefresh)
    }

    return (
        <div style={{}}>
            <Form
                form={invoiceSettingsForm}
                name="user-form"
                onFinish={setNewInvoiceID}        
            >
                <CustomFormItems 
                    form_item_style={{
                        marginBottom:'10px',
                    }}
                    label_style={{
                        textAlign: 'left'
                    }}
                    form_properties={formInvoiceSettingProperties}
                />
                <FunctionalButton 
                    active_button_click_func={false}
                    custom_button_style={{
                        marginBottom:'10px',
                        backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                        border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                    }}
                    htmlType={true}
                    children='Save New Invoice ID'
                    type={'default'}
                />
            </Form>
        </div>
    );
};
