import React, { useState, useEffect } from 'react';
import { Progress, Button } from 'antd';
import CustomModal from './CustomModal'; // Adjust path as needed
import FunctionalButton from "./FunctionalButton";

export default function CustomProgressModal({
    progress_info_list,
    openProgressModal,
    doneProgress
}) {
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  // Function to open the modal
  const openModal = () => {
    doneProgress(false)
    setVisible(true);
    // Start progress simulation when the modal opens
    const id = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(id);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
    setIntervalId(id);
  };

  useEffect(()=>{
    if(openProgressModal == true){
        openModal();
    }
  },[openProgressModal])

  // Function to close the modal
  const closeModal = () => {
    doneProgress(true)
    setVisible(false);
    // Clear interval when modal closes
    if (intervalId) {
      clearInterval(intervalId);
    }
    setProgress(0); // Reset progress
  };

  return (
    <div>
      <CustomModal
        visible={visible}
        modal_style={{borderRadius:'10px'}}
        onCancel={closeModal}
        title="Uploading Manual Invoices"
        content_style={{
            padding:'16px'
        }}
        content={
          <>
            {progress_info_list.map((each_content) => (
                <div style={{display:'flex', justifyContent:'space-between', marginBottom:'10px'}}>
                    <div style={{display:'flex'}}>
                        <h2 style={{fontSize:'17px', margin:'0px', fontWeight:'300'}}>{each_content.item}</h2>
                        <h2 style={{fontSize:'17px', margin:'0px', fontWeight:'300', paddingLeft:'5px'}}>:</h2>
                    </div>
                    <h2 style={{fontSize:'17px', margin:'0px', fontWeight:'300'}}>{each_content.value}</h2>
                </div>
            ))}
            <Progress strokeColor='#00316A' percent={progress} style={{borderRadius:'0px', marginBottom:'10px'}} percentPosition={{align: 'center',type: 'inner'}} size={[, 30]}/>
            {
                progress != 100 ? '' :
                <FunctionalButton buttonClickFunction={closeModal} children='Close' />
            }
          </>
        }
      />
    </div>
  );
}
