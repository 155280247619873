import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetVacantTenantOption() {
  const [fetchWrapper] = useFetch();
  const [vacantTenantOption, setVacantTenantOption] = useState([]);

  const getVacantTenantOption = useCallback((filter) => {
    fetchWrapper({
      endpoint_url: "settings/getVacantTenantOption",
      params: filter,
      onSuccess: (fetch_output) => {
        setVacantTenantOption(fetch_output.vacant_tenant_option);
      },
    });
  }, [fetchWrapper]);

  return { getVacantTenantOption, vacantTenantOption };
}

export default useGetVacantTenantOption;
