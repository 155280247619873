import CustomModal from "../../../common/CustomModal";
import CustomCard from "../../../common/CustomCard";
import CustomForm from "../../../common/CustomForm";

export default function MetersManagementForm({
  form,
  visible,
  modalTitle,
  handleFinish,
  handleCancel,
  visibleData,
  deviceTypeOption,
  loading
}) {

  const METER_MANAGEMENT_FORM_PROPERTIES = [
    {
      type: "input",
      name: "device_id",
      label: "Meter ID",
    },
    {
      type: "select",
      name: "device_type",
      label: "Device Type",
      options: deviceTypeOption,
    },
    {
      type: "select",
      name: "device_brand",
      label: "Device Brand",
      disabled: true
    },
    {
      type: "select",
      name: "device_model",
      label: "Device Model",
      disabled: true
    },
    {
      type: "input",
      name: "utility_type",
      label: "Utility Type",
    },
    {
      type: "input",
      name: "gateway_id",
      label: "Gateway ID",
      disabled: true
    },
    {
      type: "input",
      name: "modbus_id",
      label: "Modbus ID",
      disabled: true
    },
  ];

  return (
    <CustomModal
      visible={visible}
      onCancel={handleCancel}
      title={<h4 style={{ margin: "0px", fontWeight: "500" }}>{modalTitle}</h4>}
      title_style={{
        background: "#1F1F1F",
      }}
      modal_style={{
        marginTop: "",
      }}
      content={
        <CustomCard
          title="Meter Details"
          card_style={{ height: "100%" }}
          title_style={{
            background: "#303030",
            padding: "6px 16px",
            borderRadius: "0px",
            fontSize: "16px",
            fontWeight: "400",
          }}
          children_style={{ padding: "3px 16px", background: "#141414" }}
          children={
            <CustomForm
              form={form}
              formProperties={METER_MANAGEMENT_FORM_PROPERTIES}
              handleFinish={handleFinish}
              handleCancel={handleCancel}
              visibleData={visibleData}
              loading={loading}
            />
          }
        />
      }
    />
  );
}
