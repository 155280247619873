import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetInvoiceType() {
  const [fetchWrapper] = useFetch();
  const [invoiceType, setInvoiceType] = useState([]);

  const getInvoiceType = useCallback((setRefresh) => {
    fetchWrapper({
      method: "GET",
      endpoint_url: "settings/getInvoiceType",
      set_content_type_header: false,
      onSuccess: (fetch_output) => {
        setInvoiceType(fetch_output.invoice_type);
        setRefresh(false)
      },
    });
  }, [fetchWrapper]);

  return { getInvoiceType, invoiceType };
}

export default useGetInvoiceType;
