// all const definition
const backend_port = ":5000";       // define the backend port
export const route_basename = '/v2'    // for defining the base route for BrowserRouter

// get the backend url
let current_url = "";
if (window.location.href.split(":").length == 2) {
  //frontend url has no port number
  current_url = window.location.href.split("/").slice(0, 3);
  current_url[current_url.length - 1] += backend_port;
  current_url = current_url.join("/");
} else {
  current_url = window.location.href.split(":").slice(0, 2).join(":") + backend_port;
}

export const backend_url = current_url + "/v2/";   // eg. "http://localhost:5011/";

export const refresh_token_url = "user_management/refreshUser";