import DataTable from "../../common/DataTable";
import table_column from "./TableColumn";

export default function TenantListsTable({ 
  tenantList,
  filters, 
  tableContainerRef, 
  tableHeightOffset,
  show_size_changer
}) {
  const columns = table_column(filters);

  return (
    <>
      <DataTable
        pagination_style={{ margin: "10px" }}
        table_column={columns}
        table_data={tenantList}
        show_sorter_tooltip={false}
        layoutRef={tableContainerRef}
        tableHeightOffset={tableHeightOffset}
        show_size_changer={show_size_changer}
      />
    </>
  );
}
