import React, { useContext, useState } from "react";
import { Tabs } from "antd";
import { IndexContext } from "../../context/IndexContext"; // Adjust path as needed
import { validateProp } from "../../hooks/useValidate"; // Adjust path as needed
import "../../styles/common/custom_tabs/tabs.css"; // If you need this for other styling

const { TabPane } = Tabs;

export default function CustomTabs({
  width = "100%",
  type = "card",
  tabs = [], // Array of tab objects { title: string, content: ReactNode }
  tab_style = {},
  tab_pane_style = {},
  tab_children_styles = {},
  tab_active_styles = {},
  tab_inactive_styles = {},
  destroy_inactive_tab_pane = true,
  onTabChange = (key) => {},
}) {
  const { isLightTheme } = useContext(IndexContext);
  const [activeKey, setActiveKey] = useState(
    tabs.length > 0 ? tabs[0].key : ""
  );

  // Define inline styles for the tab bar
  const tab_bar_style = {
    padding: "5px 10px 0px 10px",
    backgroundColor: isLightTheme ? "rgb(243 243 243)" : "#1f1f1f", // Tab bar background color
    ...tab_style,
  };

  // Define inline styles for the tab pane
  const default_tab_pane_style = {
    color: isLightTheme ? "#000" : "#fff",
    ...tab_pane_style,
  };

  // Define inline styles for tabs
  const tab_styles = {
    inactive: {
      color: isLightTheme ? "#8c8c8c" : "#cfcfcf",
      ...tab_inactive_styles,
    },
    active: {
      color: isLightTheme ? "black" : "#fff",
      ...tab_active_styles,
    },
  };

  const default_tab_children_style = {
    padding: "12px",
    ...tab_children_styles,
  };

  return (
    <div style={{ width }}>
      <Tabs
        tabBarStyle={tab_bar_style}
        type={type}
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key);
          onTabChange(key);
        }}
        className="custom-tabs" // Keep this if you have other styles
        destroyInactiveTabPane={destroy_inactive_tab_pane}
      >
        {tabs.map((tab) => (
          <TabPane
            style={default_tab_pane_style}
            tab={
              <span
                style={
                  activeKey === tab.key
                    ? tab_styles.active
                    : tab_styles.inactive
                }
              >
                {tab.title}
              </span>
            }
            key={tab.key}
          >
            <div style={default_tab_children_style}>{tab.content}</div>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
}

// PropTypes validation
CustomTabs.propTypes = {
  width: validateProp("width"),
  type: validateProp("string"),
  tabs: validateProp("tabs"),
  tab_style: validateProp("style"),
  tab_pane_style: validateProp("style"),
  tab_children_styles: validateProp("style"),
  tab_active_styles: validateProp("style"),
  tab_inactive_styles: validateProp("style"),
  destroy_inactive_tab_pane: validateProp("boolean"),
};
