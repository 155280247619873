import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetActiveLots() {
  const [fetchWrapper] = useFetch();
  const [activeLots, setActiveLots] = useState([]);
  const [activeLotsLoading, setActiveLotsLoading] = useState(false)

  const getActiveLots = useCallback((filter, setRefresh) => {
    setActiveLotsLoading(true)
    fetchWrapper({
      params: filter,
      endpoint_url: "settings/getActiveLots",
      onSuccess: (fetch_output) => {
        setActiveLots(fetch_output.active_lots);
        setActiveLotsLoading(false)
        setRefresh(false)
      },
    });
  }, [fetchWrapper]);

  return { getActiveLots, activeLots, activeLotsLoading };
}

export default useGetActiveLots;
