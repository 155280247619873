import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetVacantLots() {
  const [fetchWrapper] = useFetch();
  const [vacantLots, setVacantLots] = useState([]);
  const [vacantLotsLoading, setVacantLotsLoading] = useState(false)

  const getVacantLots = useCallback((filter) => {
    setVacantLotsLoading(true)
    fetchWrapper({
      endpoint_url: "settings/getVacantLots",
      params: filter,
      onSuccess: (fetch_output) => {
        setVacantLots(fetch_output.vacant_lots);
        setVacantLotsLoading(false)
      },
    });
  }, [fetchWrapper]);

  return { getVacantLots, vacantLots, vacantLotsLoading };
}

export default useGetVacantLots;
