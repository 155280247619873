import dayjs from "dayjs";
import DataTable from "../common/DataTable";
import useFetch from "../../hooks/useFetch";
import { useEffect, useState } from "react";
import FunctionalButton from "../common/FunctionalButton";
import DeviceAlarmAcknowledgement from "./DeviceAlarmAcknowledgement";

export default function DeviceAlarmTab({
  site = null,
  device_list = [],
  start_datetime,
  end_datetime,
}) {
  const [fetchWrapper] = useFetch();
  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAlarm, setSelectedAlarm] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const table_columns = [
    // {
    //   title: "Site",
    //   dataIndex: "site_name",
    //   key: "site_name",
    // },
    {
      title: "Meter",
      dataIndex: "meter_name",
      key: "meter_name",
      sorter: (a, b) => a.meter_name.localeCompare(b.meter_name)
    },
    {
      title: "Lots",
      dataIndex: "lots",
      key: "lots",
      // sorter: (a, b) => a.lots.localeCompare(b.lots),
      render: (value, record) => {
        return value.join(", ");
      },
    },
    {
      title: "Alarms",
      dataIndex: "alarm",
      key: "alarm",
      filters: [
        {
            text: 'Device Malfunction',
            value: 'Device Malfunction'
        },
        {
          text: 'Device Online',
          value: 'Device Online'
        }
    ],
    onFilter: (value, record) => record.alarm.indexOf(value) === 0,
    },
    {
      title: "Start Datetime",
      dataIndex: "start_datetime",
      key: "start_datetime",
      render: (text, record) => dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "End Datetime",
      dataIndex: "end_datetime",
      key: "end_datetime",
      render: (text, record) =>
        record.end_datetime == null
          ? "-"
          : dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Acknowledge By",
      dataIndex: "username",
      key: "username",
      // sorter: (a, b) => a.username.localeCompare(b.username),
      render: (text, record) => record.username ?? "-",
    },
    {
      title: "Acknowledge On",
      dataIndex: "acknowledged_on",
      key: "acknowledged_on",
      render: (text, record) =>
        record.acknowledged_on == null
          ? "-"
          : dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <div style={{ display: "flex" }}>
            <FunctionalButton
              buttonClickFunction={() => {
                setSelectedAlarm(record);
                setOpenModal(true);
              }}
              type="primary"
              custom_button_style={{ marginRight: "10px", width: "150px" }}
              children={record.acknowledged_on != null ? "View" : "Acknowledge"}
            />
          </div>
        );
      },
    },
  ];

  function getDeviceAlarm() {
    fetchWrapper({
      endpoint_url: "alarm/getDeviceAlarm",
      params: {
        site_id: site,
        device_list: device_list,
        start_datetime: start_datetime,
        end_datetime: end_datetime,
      },
      onSuccess: (fetch_output) => {
        setData(fetch_output.alarm_list);
      },
    });
  }

  useEffect(() => {
    getDeviceAlarm();
  }, [site, device_list, start_datetime, end_datetime]);

  useEffect(() => {
    if (refresh === true) {
      getDeviceAlarm();
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <>
      <DataTable
        table_column={table_columns}
        table_data={data}
        refresh={refresh}
      ></DataTable>
      <DeviceAlarmAcknowledgement
        selectedAlarm={selectedAlarm}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setRefresh={setRefresh}
      ></DeviceAlarmAcknowledgement>
    </>
  );
}
