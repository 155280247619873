import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetInactiveTenants() {
  const [fetchWrapper] = useFetch();
  const [inactiveTenants, setInactiveTenants] = useState([]);
  const [inactiveTenantsLoading, setInactiveTenantsLoading] = useState(false);

  const getInactiveTenants = useCallback((filter) => {
    setInactiveTenantsLoading(true)
    fetchWrapper({
      params: filter,
      endpoint_url: "settings/getInactiveTenants",
      onSuccess: (fetch_output) => {
        setInactiveTenants(fetch_output.inactive_tenants);
        setInactiveTenantsLoading(false)
      },
    });
  }, [fetchWrapper]);

  return { getInactiveTenants, inactiveTenants, inactiveTenantsLoading};
}

export default useGetInactiveTenants;
