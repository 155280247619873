import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import CustomCard from "../common/CustomCard";
import InvoiceIDFilter from "./left_card_components/InvoiceIDFilter";
import InvoiceIDSettings from "./left_card_components/InvoiceIDSettings";
import ManualInvoiceGeneration from "./left_card_components/ManualInvoiceGeneration";
import ThirdPartyExcelTemplates from "./left_card_components/ThirdPartyExcelTemplates";


function LeftCard({
    filter, invoiceFilter, setInvoiceFilter, refresh, setRefresh
}) {

    return (
        <Row style={{height:'100%'}}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                <CustomCard
                    card_style={{ height:'100%'}}
                    title='Invoice ID Filter'
                    children_style = {{ padding:'0px'}}
                    children={
                        <InvoiceIDFilter setInvoiceFilter={setInvoiceFilter}/>
                    }
                    subtitles={[
                        { 
                            title: 'Invoice ID Settings', 
                            children: <InvoiceIDSettings refresh={refresh} setRefresh={setRefresh} invoiceFilter={invoiceFilter}/> 
                        },
                        { 
                            title: 'Manually Invoice Generation', 
                            children: <ManualInvoiceGeneration refresh={refresh} setRefresh={setRefresh} filter={filter} invoiceFilter={invoiceFilter}/> 
                        },
                        { 
                            title: 'Third Party Excel Templates', 
                            children: <ThirdPartyExcelTemplates filter={filter}/>
                        }
                    ]}
                />
            </Col>
        </Row>
    )
}

export default LeftCard;
