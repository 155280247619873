import { Flex } from "antd";
import FunctionalButton from "../../../../common/FunctionalButton";

const TableColumn = ({ setVisibleNotifyData, setVisibleNotify, setVisible, setVisibleData }) => {
  const showDetails = (info) => {
    setVisible(true);
    setVisibleData(info);
  };

  const remove_invoice_type = (info) => {
    setVisibleNotify(true);
    setVisibleNotifyData({
      'title': 'Delete Invoice Type',
      'type': 'delete_invoice_type',
      'content': 
        <div style={{ margin: '0px' }}>
          <h3 style={{ margin: '0px', fontWeight:'400', paddingBottom:'10px', fontSize:'17px', borderBottom:'1px solid #303030'}}>Are you sure you want to delete the following invoice type?</h3>
          <h3 style={{ margin: '0px', fontWeight:'400', marginTop:'10px'}}><strong>Invoice Type: </strong> {info.invoice_name}</h3>
        </div>,
      'key': info.key
    });
  };

  const table_columns = [
    {
      title: "Invoice Name",
      dataIndex: "invoice_name",
      key: "invoice_name",
    },
    {
      title: "Insert Date Time",
      dataIndex: "insert_date_time",
      key: "insert_date_time",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "200px",
      render: (info, record) => {
        return (
          <Flex gap="middle">
            <FunctionalButton
              buttonClickFunction={() => {
                showDetails(record);
              }}
              custom_button_style={{ Swidth: "150px" }}
              children="Edit"
            />
            <FunctionalButton
              disabled={record.disabled}
              buttonClickFunction={() => {
                remove_invoice_type(record)
              }}
              custom_button_style={{ width: "150px", background: record.disabled ? "#282828" : 'red' }}
              children="Delete"
            />
          </Flex>
        );
      },
    },
  ];

  return table_columns;
};

export default TableColumn;
