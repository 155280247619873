import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import SettingsFilterCard from "../../components/settings/SettingsFilterCard";
import LeftCard from "../../components/settings/LeftCard";
import RightCard from "../../components/settings/RightCard";
import "../../styles/settings/SettingsPage.css";

function SettingsPage() {
  const [settingTree, setSettingTree] = useState(["site_management"]);
  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);
  const [refresh, setRefresh] = useState(false)
  const [filter, setFilter] = useState({
    'site_id': 1
  });

  useEffect(()=>{
    setRefresh(true)
  },[filter])

  useEffect(() => {
    const updateAvailableHeight = () => {
        setAvailableHeight(window.innerHeight - 150);
    };

    updateAvailableHeight();
    window.addEventListener('resize', updateAvailableHeight);
    return () => {
      window.removeEventListener('resize', updateAvailableHeight);
    };

  }, []);

  return (
    <>
      <Row style={{height:'100%'}}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{paddingBottom:'10px'}}>
          <SettingsFilterCard loading={refresh} filter={filter} setFilter={setFilter}/>
        </Col>
        <Row gutter={[12, 0]} style={{height:availableHeight, width:'100%'}}>
          <Col xs={24} sm={24} md={24} lg={8} xl={7} xxl={6} style={{height:'auto'}}>
            <LeftCard settingTree={settingTree} setSettingTree={setSettingTree} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} xl={17} xxl={18} style={{height:'auto'}}>
            <RightCard refresh={refresh} setRefresh={setRefresh} filter={filter} settingTree={settingTree} />
          </Col>
        </Row>
      </Row>
    </>
  );
}

export default SettingsPage;
