// library imports
import { ConfigProvider, Switch, Menu, theme } from "antd";
import {
  LogoutOutlined,
  MoonFilled,
  SunFilled,
  HighlightOutlined,
  UserOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
// context imports
import { IndexContext } from "../context/IndexContext";
// hook imports
import useFetch from "../hooks/useFetch";

export default function ProfileMenu() {
  // load theme token
  const { token } = theme.useToken();

  // load index context
  const { messageApi, isLightTheme, updateTheme } = useContext(IndexContext);

  // load fetchWrapper
  const [fetchWrapper] = useFetch();

  // for navigation
  const navigate = useNavigate();

  return (
    <div style={{ minWidth: "150px" }}>
      <Menu
        style={{ minWidth: "200px", borderWidth: 0 }}
        items={[
          // {
          //   key: "theme",
          //   label: (
          //     <ConfigProvider
          //       theme={{
          //         components: {
          //           Switch: {
          //             algorithm: true,
          //             trackHeight: 28,
          //             handleSize: 24,
          //             fontSize: 18,
          //             fontSizeIcon: 18,
          //             trackMinWidth: 60,
          //             innerMinMargin: 5,
          //           },
          //         },
          //       }}
          //     >
          //       Theme
          //       <Switch
          //         disabled={true}
          //         style={{
          //           backgroundColor: isLightTheme ? token["orange-5"] : "",
          //           marginTop: -4,
          //           marginLeft: "30px",
          //         }}
          //         checkedChildren={<SunFilled style={{ marginLeft: 0 }} />}
          //         unCheckedChildren={<MoonFilled style={{ paddingLeft: 0 }} />}
          //         value={isLightTheme}
          //         onChange={(checked) => updateTheme(checked)}
          //       ></Switch>
          //     </ConfigProvider>
          //   ),
          //   icon: <HighlightOutlined />,
          //   disabled: true 
          // },
          // {
          //   key: "user_profile",
          //   label: "User Profile",
          //   disabled: true, 
          //   icon: <UserOutlined />,
          // },
          // { key: "password", label: "Change Password", icon: <KeyOutlined />, disabled: true },
          { key: "logout", label: "Logout", icon: <LogoutOutlined /> },
        ]}
        selectable={false}
        onClick={({ key, keyPath, domEvent }) => {
          if (key === "logout") {
            // logout
            fetchWrapper({
              endpoint_url: "user_management/logoutUser",
              onSuccess: (logout_status) => {
                if (logout_status.status) {
                  messageApi.open({
                    type: "success",
                    content: "Logout Success! Redirecting in 3s...",
                  });

                  setTimeout(() => {
                    navigate("/login", { replace: true });
                  }, 3000);
                } else {
                  messageApi.open({
                    type: "error",
                    content: "Failed to logout. Please contact admin.",
                  });
                }
              },
            });
          } else if (key === "password") {
            navigate("Account/ChangePassword");
          } else if (key === "theme") {
            // do nothing
          } else if (key === "user_profile") {
            navigate("Account/UserProfile");
          } else {
            messageApi.open({
              type: "error",
              content: "Logout Failed! Please contact admin.",
            });
          }
        }}
      ></Menu>
    </div>
  );
}
