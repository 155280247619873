import { useState, useCallback } from "react";
import useFetch from "../../hooks/useFetch";

function useGetStatisticInfo() {
  const [fetchWrapper] = useFetch();
  const [statisticInfo, setStatisticInfo] = useState(null);
  const [isRunning, setIsRunning] = useState(true)

  const getStatisticInfo = useCallback((filter) => {
    setIsRunning(true)
    fetchWrapper({
        endpoint_url: "invoice/getStatisticInfo",
        params: filter,
        onSuccess: (fetch_output) => {
          setIsRunning(false)
          setStatisticInfo(fetch_output)
        },
    });
  }, [fetchWrapper]);

  return { getStatisticInfo, statisticInfo, isRunning};
}

export default useGetStatisticInfo;
