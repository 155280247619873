import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useUpdateMeter() {
  const [fetchWrapper] = useFetch();
  const [updateMeterLoading, setUpdateMeterLoading] = useState(false);
  const { messageApi } = useContext(IndexContext);

  const updateMeter = useCallback(
    (meter_info, key) => {
      setUpdateMeterLoading(true);

      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "settings/updateDevice",
          params: {
            meter_info: meter_info,
            key: key,
          },
          onSuccess: (fetch_output) => {
            if ("error" in fetch_output){
              messageApi.open({
                type: "error",
                content: fetch_output.error,
              });
            } else {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
            }
            setUpdateMeterLoading(false);
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            setUpdateMeterLoading(false);
            reject(error);
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateMeter, updateMeterLoading };
}
export default useUpdateMeter;
