import { theme } from "antd";

// load the theme from local storage
export const USER_THEME = localStorage.getItem("xynersys-app-is-light-theme") === 'true';

export let theme_config = {
    token: {
      colorPrimary: "#070091e4",
      colorWarning: "#faad14",
      colorSuccess: "#52C41A",
      colorError: "ff4d4f",
      colorInfo: "#167fffe4",
      algorithm: USER_THEME
        ? theme.defaultAlgorithm
        : theme.darkAlgorithm,
    },
    components: {
      Layout: {
        algorithm: true,
      },
      Collapse: {
        algorithm: true,
      },
      Card: {
        headerBg: "#969696"
      }
    },
    cssVar: true, 
  };