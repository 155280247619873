import { Typography } from "antd";

const { Text } = Typography;

const TableColumns = (filters) => {
  const table_columns = [
    {
      title: "Meter ID",
      dataIndex: "device_name",
      key: "device_name",
      sorter: (a, b) => a.device_name.localeCompare(b.device_name)
    },
    {
      title: "Lot",
      dataIndex: "lot",
      key: "lot",
      ellipsis: true,
      sorter: (a, b) => a.lot.localeCompare(b.lot)
    },
    {
      title: "Meter Type",
      dataIndex: "device_type",
      key: "device_type",
      filters: filters?.device_type.map((x)=> {return{text: x, value: x}}),
      onFilter: (value, record) => record.device_type.indexOf(value) === 0,
    },
    {
      title: "Utility Type",
      dataIndex: "utility_type",
      key: "utility_type",
      render: (text) => text?? '-',
      sorter: (a, b) => a.utility_type != null && b.utility_type != null? a.utility_type.localeCompare(b.utility_type):false
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      filters: filters?.brand.map((x)=> {return{text: x, value: x}}),
      onFilter: (value, record) => record.brand.indexOf(value) === 0,
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      filters: filters?.model.map((x)=> {return{text: x, value: x}}),
      onFilter: (value, record) => record.model.indexOf(value) === 0,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (info) => {
        return info == "Online" ? (
          <Text type="success">{info}</Text>
        ) : info == "Malfunction" ? (
          <Text type="warning">{info}</Text>
        ) : (
          <Text>{info}</Text>
        );
      },
      filters: [
        {
          text: 'Online',
          value: 'Online'
        },
        {
          text: 'Malfunction',
          value: 'Malfunction'
        },
        {
          text: 'Unconfigured',
          value: 'Unconfigured'
        }
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: 'Real-Time Energy Reading',
      dataIndex: 'realtime_energy_reading',
      key: 'realtime_energy_reading',
      width: '200px',
      render: (text, record) => record.status == "Unconfigured"? '-':text,
      sorter: (a, b) => a.realtime_energy_reading.localeCompare(b.realtime_energy_reading) 
    },
    {
      title: "Last Online",
      dataIndex: "last_online",
      key: "last_online",
    },
  ];

  return table_columns;
};

export default TableColumns;
