import FunctionalButton from "../../../../common/FunctionalButton";

const TableColumns = ({ setVisible, visible, setVisibleData }) => {
  const showDetails = (info) => {
    setVisible(true);
    setVisibleData(info);
  };

  const table_columns = [
    {
      title: "Tenant Name",
      dataIndex: "tenant_name",
      key: "tenant_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Tariff",
      dataIndex: "tariff",
      key: "tariff",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "200px",
      render: (info, record) => {
        return (
          <FunctionalButton
            buttonClickFunction={() => {
              showDetails(record);
            }}
            custom_button_style={{ width: "150px" }}
            children="Edit"
          />
        );
      },
    },
  ];

  return table_columns;
};

export default TableColumns;
