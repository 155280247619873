import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Form } from 'antd';
import FunctionalButton from "../../common/FunctionalButton";
import useDownloadThirdPartyExcelTemplates from "../../../functions/Invoice/thirdy_party_excel_templates_func/downloadThirdPartyExcelTemplates";
import { IndexContext } from '../../../context/IndexContext';

const { TabPane } = Tabs;

export default function ThirdPartyExcelTemplates({
    filter
}) {
    const { isLightTheme } = useContext(IndexContext);
    const {downloadThirdPartyExcelTemplates} = useDownloadThirdPartyExcelTemplates();

    return (
        <>
            <FunctionalButton 
                children='Download Third Party Excel Templates'
                buttonClickFunction={()=>{
                    downloadThirdPartyExcelTemplates(filter)
                }}
                custom_button_style={{
                    backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                    border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                }}
                type={'default'}
            />
        </>
    );
};
