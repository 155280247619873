import dayjs from "dayjs";
import { useEffect, useState } from "react";
import FilterCard from "../common/FilterCard_V2";
import useFetch from "../../hooks/useFetch";

function InvoiceFilterCard({filter, setFilter}) {
    const [fetchWrapper] = useFetch();

    const [siteList, setSiteList] = useState([]);
    const [filterProperties, setFilterProperties] = useState([]);

    function getSite(){
        fetchWrapper({
          endpoint_url: "site_management/getFilterSite",
          params: {
            'non_device': true
          } ,
          onSuccess: (fetch_output) => {
            if("site_filter_list" in fetch_output){
              setSiteList(fetch_output.site_filter_list)
            }
          },
        })
    }

    function filterFormSubmitFailed(values) {
      console.log('failed')
    }

    function filterFormSubmit(values) {
        setFilter({
          site_id: values.site_selection,
          month: dayjs(values.date_selection).format('MM'),
          year: dayjs(values.date_selection).format('YYYY')
        })
    }


    // useEffects
    useEffect(() => {
        getSite()
    }, []);

    useEffect(() => {
        setFilterProperties([
        {
            type: 'select',
            name: 'site_selection',
            label: 'Sites',
            options: siteList.map((x)=>{return{value: x.ID, label: x.LongName}}),
            defaultValue: sessionStorage.getItem('site_id') == undefined ? siteList[0]?.ID : parseInt(sessionStorage.getItem('site_id')),
        },
        {
            type: 'datetime-select',
            name: 'date_selection',
            picker: 'month',
            label: 'Month',
            defaultValue: dayjs().startOf('month')
        }
        ])
    }, [siteList])
    
    return (
        <FilterCard
            filter_properties={filterProperties}
            onSubmit={filterFormSubmit}
            onSubmitFailed={filterFormSubmitFailed}
            show_search_button={true}
            is_inline_layout={true}
        />
    )
}

export default InvoiceFilterCard;
