
import { Row, Col, Input } from 'antd';
import { useState, useEffect, useContext } from "react";
import table_column from "./TableColumn"
import DataTable from "../../../common/DataTable";
import CustomModal from "../../../common/CustomModal";
import CustomCard from "../../../common/CustomCard";
import FunctionalButton from "../../../common/FunctionalButton";
import useUpdateMissingDataInvoices from "../../../../functions/Invoice/invoice_generation_func/updateMissingDataInvoices";
import { IndexContext } from '../../../../context/IndexContext';

export default function MissingDataInvoicesTable({
    missingDataInvoices,
    invoiceFilter,
    setRefresh
}) {
    const [visible, setVisible] = useState(false);
    const [visibleData, setVisibleData] = useState({});
    const [accountSummary, setAccountSummary] = useState()
    const [invoiceDetails, setInvoiceDetails] = useState()
    const [previousEnergy, setPreviousEnergy] = useState()
    const [energyAdjustment, setEnergyAdjustment] = useState()
    const [lastOnlineReading, setLastOnlineReading] = useState(0)
    const [adjustment, setAdjustment] = useState(0)
    const {updateMissingDataInvoices, loading} = useUpdateMissingDataInvoices();
    const { isLightTheme } = useContext(IndexContext);

    const columns = table_column({
        visible,
        setVisible,
        setVisibleData,
        isLightTheme
    });
    
    useEffect(()=>{
        if(loading == false){
            setVisible(false)
        }
    },[loading])

    const generatedInvoiceFunc = () => {
        return new Promise((resolve, reject) => {
            updateMissingDataInvoices(visibleData['missing_invoice_id'], adjustment, invoiceFilter).then(()=>{
                resolve()
                setRefresh(true)
            })
            .catch(reject);
        });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    function formatNumberWithCommas(number) {
        const roundedNumber = number.toFixed(2);
        const [integerPart, decimalPart] = roundedNumber.split('.');
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return `${formattedIntegerPart}.${decimalPart}`;
    }

    useEffect(()=>{
        setAccountSummary(visibleData.account_summary)
        setInvoiceDetails(visibleData.invoice_details)
        setEnergyAdjustment(visibleData.energy_adjustment)
        setLastOnlineReading(visibleData.last_online_reading)
        setPreviousEnergy(visibleData.previous_energy)
    },[visibleData])
    
    useEffect(()=>{
        setAdjustment(0)
    }, [visible])

    return (
        <>
            <DataTable temp_table_height={440} pagination_style={{ margin:'10px' }} tableRowSelection={false} table_column={columns} table_data={missingDataInvoices}/>
            <CustomModal
                visible={visible}
                onCancel={handleCancel}
                title={
                    <h4 style={{ margin: '0px', fontWeight: '500' }}>Invoice Details</h4>
                }
                title_style={{
                    background: '#1F1F1F'
                }}
                modal_style={{
                    marginTop: ''
                }}
                content={
                    <CustomCard
                        title='Account Summary'
                        card_style={{ height: '100%' }}
                        title_style={{ background: '#303030', padding: '6px 16px', borderRadius: '0px', fontSize: '16px', fontWeight: '400' }}
                        children_style={{ padding: '3px 16px', background: '#141414' }}
                        children={
                            <Row>
                                {
                                    accountSummary != null ?
                                    accountSummary.map((each_value)=>{
                                        return (
                                            <Row style={{ width: '100%', padding: '3px 0px' }}>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <h3 style={{ margin: '0px', fontWeight: '400' }}>{each_value.title} :</h3>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{each_value.value}</h3>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                    :
                                    ''
                                }
                            </Row>
                        }
                        subtitles={[
                            {
                                title: 'Invoice Details',
                                children:
                                    <Row>
                                        {
                                            invoiceDetails != null ?
                                            invoiceDetails.map((each_value)=>{
                                                return (
                                                    <Row style={{ width: '100%', padding: '3px 0px' }}>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400' }}>{each_value.title} :</h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{each_value.value}</h3>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                            :
                                            ''
                                        }
                                    </Row>
                            },
                            {
                                title: 'Energy Adjustment',
                                children:
                                    <Row>
                                       {
                                            energyAdjustment != null ?
                                            energyAdjustment.map((each_value)=>{
                                                return (
                                                    <Row style={{ width: '100%', padding: '3px 0px' }}>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400' }}>{each_value.title} :</h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{each_value.value}</h3>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                            :
                                            ''
                                        }
                                        <Row style={{ width: '100%', padding: '3px 0px' }}>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <h3 style={{ margin: '0px', fontWeight: '400' }}>Last Online Reading :</h3>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{lastOnlineReading}</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: '100%', padding: '3px 0px' }}>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <h3 style={{ margin: '0px', fontWeight: '400' }}>Adjustment :</h3>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}><Input type='number' addonAfter='kWh' value={adjustment} maxLength={6} onChange={(e)=>{
                                                    setAdjustment(e.target.value)
                                                }}/></h3>
                                            </Col>
                                        </Row>
                                    </Row>
                            },
                            {
                                title: 'Energy Details',
                                children:
                                    <Row>
                                         <Row style={{ width: '100%', padding: '3px 0px' }}>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <h3 style={{ margin: '0px', fontWeight: '400' }}>Meter Last Reading :</h3>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{formatNumberWithCommas(parseFloat(previousEnergy))} kWh</h3>
                                            </Col>
                                        </Row>
                                         <Row style={{ width: '100%', padding: '3px 0px' }}>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <h3 style={{ margin: '0px', fontWeight: '400' }}>Meter Current Reading :</h3>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{adjustment.length == 0 ? '-' : formatNumberWithCommas(parseFloat(adjustment) + parseFloat(lastOnlineReading))}</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: '100%', padding: '3px 0px' }}>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <h3 style={{ margin: '0px', fontWeight: '400' }}>Energy Consumption :</h3>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{adjustment.length == 0 ? '-' : formatNumberWithCommas((parseFloat(adjustment) + parseFloat(lastOnlineReading)) - parseFloat(previousEnergy))}</h3>
                                            </Col>
                                        </Row>
                                    </Row>
                            }
                        ]}
                    />
                }
                content_style={{
                    padding: '0px'
                }}
                footer_style={{
                    padding: '10px 10px',
                }}
                footer={
                    <div style={{ display: 'flex' }}>
                        <FunctionalButton
                            buttonClickFunction={handleCancel}
                            custom_button_style={{ marginRight: '10px', width: '100px', padding: '3px 5px', marginRight: '6px' }}
                            disabled={loading}
                        >
                            Close
                        </FunctionalButton>
                        <FunctionalButton
                            trigger_loading={true}
                            disabled={adjustment.length == 0 ? true : false}
                            buttonClickFunction={generatedInvoiceFunc}
                            custom_button_style={{ marginRight: '10px', width: '150px', padding: '3px 5px', marginRight: '6px' }}
                        >
                            Generate Invoice
                        </FunctionalButton>
                    </div>
                }
            />
        </>
    )
}

