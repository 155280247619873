import { Row, Col } from 'antd';
import DeviceListCard from './RightCard/DeviceListCard';

export default function RightCard({
    deviceListData
    ,isDeviceListTabsLoading
    ,isShowMeterTrendingChart
    ,dateRangeSelection
    ,setIsShowMeterTrendingChart
    ,selectedDevice
    ,setSelectedDevice
}) {

    return (
        <Row style={{height:'100%'}}>
            <Col span={24} style={{height:'100%'}}>
                <DeviceListCard 
                    deviceListData={deviceListData}
                    isDeviceListTabsLoading={isDeviceListTabsLoading}
                    isShowMeterTrendingChart={isShowMeterTrendingChart}
                    dateRangeSelection={dateRangeSelection}
                    setIsShowMeterTrendingChart={setIsShowMeterTrendingChart}
                    selectedDevice={selectedDevice}
                    setSelectedDevice={setSelectedDevice}
                />
            </Col>
        </Row>
        
    );
}