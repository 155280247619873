import { useState, useEffect } from "react";
import DataTable from "../../../../common/DataTable";
import table_column from "./TableColumn";
import LotsManagementForm from "../LotsManagementForm";
import { Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export default function InactiveLotsTable({ setVisibleNotifyData, setVisibleNotify, setPairingTenantVisibleData, setPairingTenantVisibleModal, inactiveLotsData, vacantLotsLoading}) {
  const [inactiveLotsForm] = Form.useForm();

  const columns = table_column({ setVisibleNotifyData, setVisibleNotify, setPairingTenantVisibleModal, setPairingTenantVisibleData});

  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);

  useEffect(() => {
      const updateAvailableHeight = () => {
          setAvailableHeight(window.innerHeight - 280);
      };

      updateAvailableHeight();
      window.addEventListener('resize', updateAvailableHeight);
      return () => {
          window.removeEventListener('resize', updateAvailableHeight);
      };

  }, []);

  return (
    <>
      {
        vacantLotsLoading == true ? 
        <div style={{height:availableHeight, display:'flex', justifyContent:'center', alignItems:'center'}}>
          <Spin
            wrapperClassName="device-list-loading"
            indicator={<LoadingOutlined spin />}
            spinning={true}
            size="large"
          ></Spin>        
        </div>
        : 
        <DataTable
          temp_table_height={620}
          pagination_style={{ margin: "10px" }}
          table_column={columns}
          table_data={inactiveLotsData}
        />
      }
    </>
  );
}
