// library imports
import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider, message, notification } from "antd";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// style imports
import "./styles/index.css";
// config imports
import { route_basename } from "./configs/configs";
// context imports
import { IndexContext } from "./context/IndexContext";
// hook imports
import useThemeSwitcher from "./hooks/useThemeSwitcher";
// component/page imports
import MainLayout from "./layout/MainLayout";
import DashboardPage from "./pages/dashboard/DashboardPage";
import InvoicePage from "./pages/invoice/InvoicePage";
import SettingsPage from "./pages/settings/SettingsPage";
import LoginPage from "./pages/login/LoginPage";
import UserProfilePage from "./pages/account/UserProfilePage";
import ChangePassword from "./pages/account/ChangePassword";
import { insertCss } from "insert-css";
import { useEffect } from "react";
import MeterListsPage from "./pages/meter_lists/MeterListsPage";
import TenantListsPage from "./pages/tenant_lists/TenantListsPage";
import AlarmPage from "./pages/alarm/AlarmsPage";

const App = () => {
  // load theme switcher
  const [appTheme, updateTheme, isLightTheme] = useThemeSwitcher();

  // for triggering message
  const [messageApi, messageApiContext] = message.useMessage();
  // for triggering notification
  const [notificationApi, notificationApiContext] =
    notification.useNotification();

  // Insert css for body
  useEffect(() => {
    insertCss(
      `
        body {
          scrollbar-color: ${
            isLightTheme ? "#B4B4B4 #F0F0F0;" : "#656565 #313131;"
          } 
        }
      `
    );
  }, [isLightTheme]);

  return (
    <ConfigProvider theme={{ ...appTheme }}>
      {messageApiContext}
      {notificationApiContext}
      <IndexContext.Provider
        value={{
          messageApi: messageApi,
          notificationApi: notificationApi,
          isLightTheme: isLightTheme,
          updateTheme: updateTheme,
        }}
      >
        <BrowserRouter basename={route_basename}>
          <Routes>
            {/* Login Route */}
            <Route path="login" element={<LoginPage />}></Route>
            {/* All content pages */}
            <Route element={<MainLayout updateTheme={updateTheme} />}>
              <Route path="Dashboard" element={<DashboardPage />}></Route>
              <Route path="MeterLists" element={<MeterListsPage />}></Route>
              <Route path="TenantLists" element={<TenantListsPage />}></Route>
              <Route path="Invoice" element={<InvoicePage />}></Route>
              <Route path="Alarms" element={<AlarmPage />}></Route>
              <Route path="Settings" element={<SettingsPage />}></Route>
              {/* Sub route for Account */}
              <Route path="Account">
                <Route path="UserProfile" element={<UserProfilePage />}></Route>
                <Route
                  path="ChangePassword"
                  element={<ChangePassword />}
                ></Route>
              </Route>
            </Route>
            {/* Default all unknown route to login page */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </BrowserRouter>
      </IndexContext.Provider>
    </ConfigProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
