import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useUpdateTenant() {
  const [fetchWrapper] = useFetch();
  const [updateTenantLoading, setUpdateTenantLoading] = useState(false);
  const { messageApi } = useContext(IndexContext);

  const updateTenant = useCallback(
    (tenant_info, key, filter) => {
      setUpdateTenantLoading(true);

      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "settings/updateTenant",
          params: {
            tenant_info: tenant_info,
            key: key,
            site_id: filter['site_id']
          },
          onSuccess: (fetch_output) => {
            if ("error" in fetch_output){
              for (let each_error in fetch_output.error) {
                messageApi.open({
                    type: "error",
                    content: fetch_output.error[each_error]
                });
              }
            } else {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
            }
            setUpdateTenantLoading(false);
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            setUpdateTenantLoading(false);
            reject(error);
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateTenant, updateTenantLoading };
}
export default useUpdateTenant;
