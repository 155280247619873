import { useContext, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useDownloadThirdPartyExcelTemplates() {
    const [fetchWrapper] = useFetch();
    const { messageApi } = useContext(IndexContext);

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }    

    const downloadThirdPartyExcelTemplates = useCallback((filter) => {
        console.log(filter)
        fetchWrapper({
            endpoint_url: "invoice/downloadThirdPartyExcelTemplates",
            json_output: false, 
            params: filter,
            onSuccess: (response) => {
                if (response.headers.get("content-type") === "application/json") {
                    response.json().then((data) => {
                        if (data.error) {
                            messageApi.open({
                                type: "error",
                                content: data.error || "Failed to download third party excel templates file"
                            });
                        } else {
                            messageApi.open({
                                type: "error",
                                content: "Failed to download third party excel templates file"
                            });
                        }
                    });
                } else {
                    response.blob().then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download ='Third_Party_Excel_Templates' + formatDate(new Date) + '.xlsx'; // Ensure the file has the right extension
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    });
                }
            },
            onResponseError: (err) => {
                messageApi.open({
                    type: "error",
                    content: "Failed to download third party eexceltemplates file"
                });
            }
        });
    }, [fetchWrapper, messageApi]);

    return { downloadThirdPartyExcelTemplates };
}

export default useDownloadThirdPartyExcelTemplates;
