import { useContext, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { Button, Col, Form, Row } from "antd";
import FilterCard from "../../components/common/FilterCard_V2";
import CustomCard from "../../components/common/CustomCard";
import CustomTabs from "../../components/common/CustomTabs";
import { IndexContext } from "../../context/IndexContext";
import dayjs from "dayjs";
import DeviceTab from "../../components/alarm/DeviceAlarmTab";
import DeviceAlarmTab from "../../components/alarm/DeviceAlarmTab";
import ConsumptionAlarmTab from "../../components/alarm/ConsumptionAlarmTab";
import { SettingOutlined } from "@ant-design/icons";
import CustomModal from "../../components/common/CustomModal";
import CustomForm from "../../components/common/CustomForm";

export default function AlarmsPage() {
  const [fetchWrapper] = useFetch();
  const { isLightTheme } = useContext(IndexContext);

  const [activeTab, setActiveTab] = useState("Device");

  const [siteList, setSiteList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [filterProperties, setFilterProperties] = useState([]);
  const [filterCardHeight, setFilterCardHeight] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [consumptionThreshold, setConsumptionThreshold] = useState(null);
  const [thresholdForm] = Form.useForm();
  const { messageApi } = useContext(IndexContext);

  const [filter, setFilter] = useState({
    start_datetime: dayjs()
      .startOf("day")
      .add(-7, "day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    end_datetime: dayjs().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    device_list: [],
    tenant_list: [],
    month: dayjs().startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const [siteID, setSiteID] = useState(null);

  const formColSpan = {
    xs: 24,
    sm: 24,
    md: 6,
    lg: 6,
    xl: 4,
    xxl: 4,
  };

  const threshold_form_properties = [
    {
      type: "input",
      name: "upper_limit",
      label: "Upper Limit (%)",
    },
    {
      type: "input",
      name: "lower_limit",
      label: "Lower Limit (%)",
    },
  ];

  function getSite() {
    fetchWrapper({
      endpoint_url: "site_management/getFilterSite",
      params: {
        non_device: false,
      },
      onSuccess: (fetch_output) => {
        if ("site_filter_list" in fetch_output) {
          setSiteList(fetch_output.site_filter_list);
          if (siteID === null) {
            fetch_output.site_filter_list.sort((a, b) => a.ID - b.ID);
            setSiteID(fetch_output.site_filter_list[0].ID);
            setFilter((val) => ({
              ...val,
              site: fetch_output.site_filter_list[0].ID,
            }));
            // sessionStorage.setItem('site_id', fetch_output.site_filter_list[0].ID);
          }
        }
      },
    });
  }

  function getDevice() {
    fetchWrapper({
      endpoint_url: "site_management/getFilterDevice",
      params: {
        site_id: siteID,
      },
      onSuccess: (fetch_output) => {
        if ("device_filter_list" in fetch_output) {
          setDeviceList(fetch_output.device_filter_list);
        }
      },
    });
  }

  function getTenant() {
    fetchWrapper({
      endpoint_url: "site_management/getFilterTenant",
      params: {
        site_id: siteID,
      },
      onSuccess: (fetch_output) => {
        if ("tenant_filter_list" in fetch_output) {
          setTenantList(fetch_output.tenant_filter_list);
        }
      },
    });
  }

  function getConsumptionThreshold() {
    fetchWrapper({
      endpoint_url: "alarm/getConsumptionThreshold",
      params: {
        site_id: siteID,
      },
      onSuccess: (fetch_output) => {
        if ("consumption_threshold" in fetch_output) {
          setConsumptionThreshold(fetch_output.consumption_threshold);
        }
      },
    });
  }

  function filterFormSubmit(values) {
    setFilter({
      ...filter,
      site: values.site_selection,
      ...(activeTab === "Device"
        ? {
            start_datetime: values.startdate_selection?.format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            end_datetime: values.enddate_selection?.format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            device_list: values.device_selection,
          }
        : {
            tenant_list: values.tenant_selection,
            month: values.month_selection?.format("YYYY-MM-DDTHH:mm:ss"),
          }),
    });
  }

  function filterOnChange({ form, key, value }) {
    if (key === "site_selection") {
      form.resetFields(["device_selection"]);
      form.resetFields(["tenant_selection"]);
      setSiteID(value);
      sessionStorage.setItem('site_id', value);
    }
  }

  function getFilterCardHeight() {
    const height = document.getElementById("filter-card").offsetHeight;
    setFilterCardHeight(height);
  }

  function onSaveConsumptionThreshold(value, key) {
    fetchWrapper({
      endpoint_url: "alarm/updateConsumptionThreshold",
      params: {
        site_id: siteID,
        upper_limit: value.upper_limit,
        lower_limit: value.lower_limit,
      },
      onSuccess: (fetch_output) => {
        if ("consumption_threshold" in fetch_output) {
          setOpenModal(false);
          setConsumptionThreshold(fetch_output.consumption_threshold);
          messageApi.open({
            type: "success",
            content: `Successfully update ${fetch_output.consumption_threshold.site_name} consumption threshold.`,
          });
        }
      },
      onFetchError: (error) => {
        messageApi.open({
          type: "error",
          content: error,
        });
      },
      onResponseError: (response) => {
        messageApi.open({
          type: "error",
          content: response,
        });
      },
    });
  }

  useEffect(() => {
    getSite();
    getFilterCardHeight();
    window.addEventListener("resize", getFilterCardHeight);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", getFilterCardHeight);
    };
  }, []);

  useEffect(() => {
    //Set filter properties when site and unit list are available
    if (siteList.length != 0) {
      setFilterProperties([
        {
          type: "select",
          name: "site_selection",
          label: "Sites",
          onchange_active: true,
          options: siteList.map((x) => {
            return { value: x.ID, label: x.LongName };
          }),
          defaultValue: sessionStorage.getItem('site_id') == undefined ? siteID : parseInt(sessionStorage.getItem('site_id')),
        },
        ...(activeTab === "Device"
          ? [
              {
                type: "multi-select",
                name: "device_selection",
                label: "Device",
                onchange_active: true,
                options: deviceList?.map((x) => {
                  return { value: x.ID, label: x.DeviceName };
                }),
                defaultValue: [],
              },
              {
                colspan: formColSpan,
                type: "datetime-select",
                name: "startdate_selection",
                picker: "date",
                label: "Start",
                onchange_active: true,
                defaultValue: dayjs().startOf("day").add(-7, "day"),
              },
              {
                colspan: formColSpan,
                type: "datetime-select",
                name: "enddate_selection",
                picker: "date",
                label: "End",
                onchange_active: true,
                defaultValue: dayjs().startOf("day"),
              },
            ]
          : [
              {
                type: "multi-select",
                name: "tenant_selection",
                label: "Tenant",
                onchange_active: true,
                options: tenantList?.map((x) => {
                  return { value: x.ID, label: x.TenantName };
                }),
                defaultValue: [],
              },
              {
                colspan: formColSpan,
                type: "datetime-select",
                name: "month_selection",
                picker: "month",
                label: "Month",
                onchange_active: true,
                defaultValue: dayjs().startOf("month"),
              },
            ]),
      ]);
    }
  }, [siteList, deviceList, tenantList, activeTab]);

  useEffect(() => {
    getDevice();
    getTenant();
    getConsumptionThreshold();
  }, [siteID]);

  return (
    <>
      <Row gutter={[12, 10]} style={{ height: "100%" }}>
        <Col span={24}>
          <div id="filter-card">
            <FilterCard
              filter_properties={filterProperties}
              onSubmit={filterFormSubmit}
              onChange={filterOnChange}
              show_search_button={true}
              is_inline_layout={true}
            ></FilterCard>
          </div>
        </Col>
        <Col
          span={24}
          style={{ height: `calc(100% - ${filterCardHeight + 10}px)` }}
        >
          <CustomCard
            card_style={{ height: "100%" }}
            card_body_style={{
              height: `calc(100% - ${filterCardHeight + 10}px)`,
            }}
            children_style={{
              height: "100%",
              padding: 0,
              minHeight: 100,
            }}
            extra={
              <Button
                shape="circle"
                onClick={() => setOpenModal(true)}
                icon={<SettingOutlined />}
              ></Button>
            }
            title={"Alarms"}
            children={
              <CustomTabs
                onTabChange={setActiveTab}
                tab_style={{
                  background: isLightTheme ? "#F3F3F3" : "#1D1D1D",
                  height: "100%",
                }}
                tab_children_styles={{ padding: "0px", height: "100%" }}
                tabs={[
                  {
                    title: "Device",
                    key: "Device",
                    content: <DeviceAlarmTab {...filter} />,
                  },
                  {
                    title: "Tenant Consumption",
                    key: "Tenant",
                    content: <ConsumptionAlarmTab {...filter} />,
                  },
                ]}
              ></CustomTabs>
            }
          ></CustomCard>
        </Col>
      </Row>
      <CustomModal
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        title={
          <h4 style={{ margin: "0px", fontWeight: "500" }}>
            Threshold Setting
          </h4>
        }
        title_style={{
          background: "#1F1F1F",
        }}
        content={
          <CustomCard
            title={consumptionThreshold?.site_name}
            card_style={{ height: "100%" }}
            title_style={{
              background: "#303030",
              padding: "6px 16px",
              borderRadius: "0px",
              fontSize: "16px",
              fontWeight: "400",
            }}
            children_style={{ padding: "3px 16px", background: "#141414" }}
            children={
              <CustomForm
                form={thresholdForm}
                formProperties={threshold_form_properties}
                visibleData={consumptionThreshold}
                handleCancel={() => setOpenModal(false)}
                handleFinish={onSaveConsumptionThreshold}
              ></CustomForm>
            }
          />
        }
      ></CustomModal>
    </>
  );
}
