import { useState, useEffect, useRef, useContext } from "react";
import { Col, Row, Spin } from "antd";
import CustomCard from "../common/CustomCard";
import TenantListsTable from "./table/TenantListsTable";
import useFetch from "../../hooks/useFetch";
import { LoadingOutlined, DownloadOutlined } from "@ant-design/icons";
import FunctionalButton from "../common/FunctionalButton";
import { IndexContext } from "../../context/IndexContext";
import dayjs from "dayjs";

function TenantListsTableCard({ filter }) {
  const [fetchWrapper] = useFetch();
  const tableContainerRef = useRef();
  const { isLightTheme } = useContext(IndexContext);
  const { messageApi } = useContext(IndexContext);

  const [height, setHeight] = useState("unset");
  const [tenantList, setTenantList] = useState();
  const [filters, setFilters] = useState();
  const [isTenantListLoading, setIsTenantListLoading] = useState(true);
  const [tableHeightOffset, setTableHeightOffset] = useState(90);

  function getTenantList(){
    fetchWrapper({
      endpoint_url: "tenant_lists/getTenantList",
      params: filter,
      onSuccess: (fetch_output) => {
        if(!("error" in fetch_output)){
          setTenantList(fetch_output.data)
          setFilters(fetch_output.filters)
          setIsTenantListLoading(false)
        }
      }
    })
  }

  const onDownloadButtonClicked = () => {
    const filename = `TenantList_${dayjs().format("YYYYMMDDTHHmmss")}.xlsx`;

      return new Promise((resolve, reject) => {
          fetchWrapper({
              endpoint_url: 'tenant_lists/downloadTenantList',
              params: {...filter},
              json_output: false,
              onSuccess: (response) => {
                resolve(true)
                // returned a json response.
                if(response.headers.get("content-type") === "application/json"){
                    response.json().then((data) => {
                        if (Object.keys(data).includes('error')){
                            // has issue with the download file
                            messageApi.open({
                                type: "error",
                                content: data.error
                            })
                        }
                        else{
                            messageApi.open({
                                type: "error",
                                content: "Failed to download Tenant list."
                            })
                        }
                    })
                }
                else{
                    // no issue with the response. returned a spreadsheet
                    response.blob().then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    })
                }
    
              }
            })
      });
  }

  useEffect(()=>{

    function handleResize() {
        const newHeight = window.innerWidth >= 1200 ? "unset" : '400px';
        const newTableHeightOffset = window.innerWidth >= 1200 ? 90:90;
        setHeight(newHeight); 
        setTableHeightOffset(newTableHeightOffset)
    }

    handleResize(); //Set initial height
    window.addEventListener('resize', handleResize);

    return ()=> {
      window.removeEventListener('resize', handleResize);
    }
}, [])

  useEffect(()=>{
    if(Object.keys(filter).length > 0){
      setIsTenantListLoading(true)
      getTenantList()
    }
  },[filter])

  return (
    <>
      <Row style={{ height: "100%" }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          style={{ height: '100%' }}
        >
          <div
            style={{height: '100%', minHeight: '448px'}}
            ref={tableContainerRef}
          >
            {
              tableContainerRef.current? <CustomCard
                title="Tenant Lists"
                card_style={{ height: "100%" }}
                card_body_style={{height: "calc(100% - 46px)"}}
                title_style={{ padding: "8px 12px" }}
                children_style={{ padding: "0px", height: '100%', minHeight: height }}
                children={
                  <Spin
                    wrapperClassName="tenant-list-loading"
                    indicator={<LoadingOutlined spin />}
                    spinning={isTenantListLoading}
                    size="large"
                    style={{height: "100%"}}
                  >
                    {
                      isTenantListLoading? <></>:
                      <TenantListsTable 
                        tenantList={tenantList} 
                        filters={filters} 
                        tableContainerRef={tableContainerRef}
                        tableHeightOffset={tableHeightOffset}
                        show_size_changer={true}
                      />
                    }
                  </Spin>
                }
                extra={
                  <FunctionalButton
                    buttonClickFunction={onDownloadButtonClicked}
                    trigger_loading={true}
                    type={'default'}
                    custom_button_style={{
                        backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                        border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                    }}
                  >
                    <DownloadOutlined/>
                  </FunctionalButton>
                }
              />:<></>
            }
          </div>
          
        </Col>
      </Row>
    </>
  );
}

export default TenantListsTableCard;
