// A button that is able to works with loading.
// if loading is used, the ClickFunction needs to be a Promise so it can end loading when promise is resolved, either success or failed.


import { Button, Popconfirm } from "antd";
import { useState } from "react";

/**
 * A common button component that works for most of the case.
 * Using children as the node for its content of the button.
 * Support loading (trigger_loading), popconfirm (show_confirmation), normal onClick features. 
 * Follow simple antd properties: type, width, danger
 *
 * @export
 * @param {{ buttonClickFunction: function(); children: ReactNode; buttonClickFunctionArgs?: []; trigger_loading?: boolean; show_confirmation?: boolean; confirmation_message?: { title: string; content: string; }; type?: string; width?: string; danger?: boolean; icon: any; }} param0
 * @param {*} param0.buttonClickFunction
 * @param {*} param0.children
 * @param {{}} [param0.buttonClickFunctionArgs=[]]
 * @param {boolean} [param0.trigger_loading=false]
 * @param {boolean} [param0.show_confirmation=false]
 * @param {{ title: string; content: string; }} [param0.confirmation_message={title:"", content:""}]
 * @param {string} [param0.type='primary']
 * @param {string} [param0.width="fit-content"]
 * @param {boolean} [param0.danger=false]
 * @param {*} param0.icon
 * @returns
 */
export default function FunctionalButton({
    buttonClickFunction, 
    active_button_click_func = true,
    custom_button_style = {}, 
    children,
    htmlType = false,
    buttonClickFunctionArgs=[],
    trigger_loading=false,
    show_confirmation=false,
    confirmation_message={
        title:"",
        content:""
    },
    error_function,
    type='primary',
    width="fit-content",
    danger=false,
    icon, 
    disabled=false
}){
    
    const default_buttom_style = {
        background: '#282828',
        border: '1px solid #303030',
        width: '100%',
        ...custom_button_style
    }
    
    const [buttonLoading, setButtonLoading] = useState(false);
    const [openPopover, setOpenPopover] = useState(false);

    function handleOnClick(){
        // set to button loading if required
        if(trigger_loading){
            setButtonLoading(true);
            if(active_button_click_func == true){
                buttonClickFunction(...buttonClickFunctionArgs).then((successMessage)=>{
                    console.log(successMessage)
                })
                .catch((error) => {
                    error_function()
                })
                .finally(() => {
                    setButtonLoading(false);
                });
            }
            // perform the actual button function
        }
        else{
            if(active_button_click_func == true){
            // just run the function
                buttonClickFunction(...buttonClickFunctionArgs);
            }
        }
    }

    function handlePopconfirmOpenChange(newOpen){
        if (!newOpen) {
            setOpenPopover(newOpen);
            return;
        }
        // Determining condition before show the popconfirm.
        if (show_confirmation) {
            setOpenPopover(newOpen);
        } else {
            handleOnClick();
        }
    }
    
    return (
        <Popconfirm
          title={confirmation_message.title}
          description={confirmation_message.content}
          okText="Yes"
          cancelText="No"
          open={openPopover}
          onOpenChange={handlePopconfirmOpenChange}
          onConfirm={handleOnClick}
          onCancel={()=>{setOpenPopover(false);}}
        >
            <Button
                htmlType={htmlType ? 'submit' : 'button'}
                type={type}
                danger={danger}
                loading={buttonLoading}
                icon={icon}
                style={{ width: width, textAlign: 'center', ...default_buttom_style}}
                disabled={disabled}
            >
                {children}
            </Button>
        </Popconfirm>
    )
}