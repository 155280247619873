import { useState, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetInvoiceIdSettings() {
  const [fetchWrapper] = useFetch();
  const [invoiceIdSettings, setInvoiceIdSettings] = useState();

  const getInvoiceIdSettings = useCallback((invoice_filter, setRefresh) => {
    fetchWrapper({
        endpoint_url: "invoice/getInvoiceIdSettings",
        params: invoice_filter,
        onSuccess: (fetch_output) => {
            setInvoiceIdSettings(fetch_output)
            setRefresh(false)
        },
    });
  }, [fetchWrapper]);

  return { getInvoiceIdSettings, invoiceIdSettings };
}

export default useGetInvoiceIdSettings;
