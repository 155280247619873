import { useState, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetInvoiceIdFilter() {
  const [fetchWrapper] = useFetch();
  const [invoiceIdFilter, setInvoiceIdFilter] = useState(null);

  const getInvoiceIdFilter = useCallback(() => {
    fetchWrapper({
        method: 'GET',
        endpoint_url: "invoice/getInvoiceIdFilter",
        set_content_type_header: false,
        onSuccess: (fetch_output) => {
            setInvoiceIdFilter(fetch_output)
        },
    });
  }, [fetchWrapper]);

  return { getInvoiceIdFilter, invoiceIdFilter };
}

export default useGetInvoiceIdFilter;
