import DataTable from "../../common/DataTable";
import table_column from "./TableColumn";

export default function MeterListsTable({ 
  meterList, 
  filters, 
  tableContainerRef, 
  tableHeightOffset 
}) {
  const columns = table_column(filters);

  return (
    <DataTable
      pagination_style={{ margin: "10px" }}
      table_column={columns}
      table_data={meterList}
      show_sorter_tooltip={false}
      layoutRef={tableContainerRef}
      tableHeightOffset={tableHeightOffset}
      show_size_changer={true}
    />
  );
}
