import { Flex, Row, Col, Progress, ConfigProvider, Skeleton } from "antd"
import { useContext } from 'react';
import { IndexContext } from '../../../context/IndexContext';
import CustomCard from "../../common/CustomCard";
import CustomLegend from "../CustomLegend";
import "../../../styles/dashboard/DashboardPage.css"

export default function SummaryCard({
    summaryData
    ,isSummaryCardLoading
}) {
    const { isLightTheme } = useContext(IndexContext);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Progress: {
                        lineBorderRadius: 5,
                        defaultColor: isLightTheme? "#5B8FF9":"#00316A"
                    }
                }
            }}
        >
            <CustomCard
                title={"Summary"}
                children={
                    <div
                        className="dashboard-summary-card"
                    >
                        <Skeleton loading={isSummaryCardLoading} paragraph={{rows: 3}} active>
                            <Row gutter={[12,12]}>
                                <Col span={24} style={{fontSize: "1rem"}}>
                                    Total Energy Consumption: {summaryData.total_energy_consumption?? '-'} {summaryData.unit}
                                </Col>
                                <Col span={24}>
                                    <Progress 
                                        percent={summaryData.defined_consumption_percentage?? 0} 
                                        size={["100%", 27]} 
                                        showInfo={false}
                                        success={{
                                            percent: summaryData.defined_consumption_percentage?? 0, 
                                            strokeColor: isLightTheme? "#5B8FF9":"#00316A"
                                        }}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Flex align="center" gap={"small"}>
                                        <CustomLegend color={isLightTheme? "#5B8FF9":"#00316A"}/>
                                        Defined Consumption: {summaryData.defined_consumption?? '-'} {summaryData.unit} {summaryData.defined_consumption_percentage == null ? '': `(${summaryData.defined_consumption_percentage+"%"})`}
                                    </Flex>
                                </Col>
                                <Col span={24}>
                                    <Flex align="center" gap={"small"}>
                                        <CustomLegend color={isLightTheme? "#F0F0F0":"#313131"}/>
                                        Undefined: {summaryData.undefined_consumption?? '-'} {summaryData.unit} {summaryData.undefined_consumption_percentage == null ? '':`(${summaryData.undefined_consumption_percentage+"%"})`}
                                    </Flex>
                                </Col>
                            </Row>
                        </Skeleton>
                    </div>
                }
            />
        </ConfigProvider>
    );
}