import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetVacantLotOptions() {
  const [fetchWrapper] = useFetch();
  const [vacantLotOptions, setVacantLotOptions] = useState([]);

  const getVacantLotOptions = useCallback((filter) => {
    fetchWrapper({
      endpoint_url: "settings/getVacantLotOptions",
      params: filter,
      onSuccess: (fetch_output) => {
        setVacantLotOptions(fetch_output.vacant_lot_options);
      },
    });
  }, [fetchWrapper]);

  return { getVacantLotOptions, vacantLotOptions };
}

export default useGetVacantLotOptions;
