import FunctionalButton from "../../components/common/FunctionalButton"
import dayjs from "dayjs";
import { Typography } from "antd";

const { Text } = Typography;


function onClick (device_name, logger_name, setSelectedDevice){
    setSelectedDevice({
        'device_name': device_name,
        'logger_name': logger_name
    })
}

export function getTableColumn({
    device_type
    ,selectedDevice
    ,setSelectedDevice
    ,isLightTheme
}) {

    if(device_type == "Tenant"){
        return [
            {
                title: "ID",
                dataIndex: 'id',
                key: 'id',
                hidden: true
            },
            {
                title: 'Meter ID',
                dataIndex: 'device_name',
                key: 'device_name',
                sorter: (a, b) => a.device_name.localeCompare(b.device_name)
            },
            {
                title: 'Lot',
                dataIndex: 'lot',
                key: 'lot',
                ellipsis: true,
                render: (text) => {
                    return text.join(', ')
                    // if(text != '[]'){
                    //     return (
                    //         text
                    //     )
                    // } else {
                    //     return '-'
                    // }
                },
                sorter: (a, b) => a.lot.join(', ').localeCompare(b.lot.join(', '))
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (info) => {
                    return info == "Online" ? (
                      <Text type="success">{info}</Text>
                    ) : info == "Malfunction" ? (
                      <Text type="warning">{info}</Text>
                    ) : (
                      <Text>{info}</Text>
                    );
                },
                filters: [
                    {
                        text: 'Online',
                        value: 'Online'
                    },
                    {
                        text: 'Malfunction',
                        value: 'Malfunction'
                    },
                    {
                        text: 'Unconfigured',
                        value: 'Unconfigured'
                    }
                ],
                onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
            {
                title: 'Total Consumption',
                dataIndex: 'total_consumption',
                key: 'total_consumption',
                render: (text) => text?? '-',   
                sorter: (a, b) => a.total_consumption != null && b.total_consumption != null? a.total_consumption.localeCompare(b.total_consumption):false
            },
            {
                title: 'Real-Time Energy Reading',
                dataIndex: 'realtime_energy_reading',
                key: 'realtime_energy_reading',
                width: '200px',
                render: (text, record) => record.status == "Unconfigured"? '-':text,
                sorter: (a, b) => a.realtime_energy_reading.localeCompare(b.realtime_energy_reading) 
            },
            {
                title: 'Last Online',
                dataIndex: 'last_online',
                key: 'last_online',
                render: (text, record) => record.status == "Unconfigured"? '-':dayjs(text).format("YYYY-MM-DD HH:mm:ss")
            },
            {
                title: 'Action',
                dataIndex: 'action',
                render: (_, record) => {
                    return (
                        <FunctionalButton
                            children='Show Trend'
                            disabled={record.total_consumption == null? true:false}
                            buttonClickFunction={onClick}
                            buttonClickFunctionArgs={[record.device_name, record.logger_name, setSelectedDevice]}
                            custom_button_style= {{
                                background:selectedDevice?.logger_name == record.logger_name? (isLightTheme? "#EEEEEE":"#282828"):(isLightTheme? "#FFFFFF":"#141414"),
                                color: record.total_consumption == null? "#808080":(isLightTheme? "#000000":"#ffffff"),
                                borderColor: record.total_consumption == null? (isLightTheme? "#D9D9D9":"#424242"):(isLightTheme? "#D9D9D9":"#424242"),
                                boxShadow: "unset"
                            }}
                        />
                    );
                }
            }
        ];
    }else{
        return [
            {
                title: "ID",
                dataIndex: 'id',
                key: 'id',
                hidden: true
            },
            {
                title: 'Meter ID',
                dataIndex: 'device_name',
                key: 'device_name',
                sorter: (a, b) => a.device_name.localeCompare(b.device_name)
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (info) => {
                    return info == "Online" ? (
                      <Text type="success">{info}</Text>
                    ) : info == "Malfunction" ? (
                      <Text type="warning">{info}</Text>
                    ) : (
                      <Text>{info}</Text>
                    );
                },
                filters: [
                    {
                        text: 'Online',
                        value: 'Online'
                    },
                    {
                        text: 'Malfunction',
                        value: 'Malfunction'
                    },
                    {
                        text: 'Unconfigured',
                        value: 'Unconfigured'
                    }
                ],
                onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
            {
                title: 'Total Consumption',
                dataIndex: 'total_consumption',
                key: 'total_consumption',
                render: (text) => text?? '-',
                sorter: (a, b) => a.total_consumption != null && b.total_consumption != null? a.total_consumption.localeCompare(b.total_consumption):false
            },
            {
                title: 'Real-Time Energy Reading',
                dataIndex: 'realtime_energy_reading',
                key: 'realtime_energy_reading',
                width: '200px',
                render: (text, record) => record.status == "Unconfigured"? '-':text,
                sorter: (a, b) => a.realtime_energy_reading.localeCompare(b.realtime_energy_reading)
            },
            {
                title: 'Last Online',
                dataIndex: 'last_online',
                key: 'last_online',
                render: (text, record) => record.status == "Unconfigured"? '-':dayjs(text).format("YYYY-MM-DD HH:mm:ss")
            },
            {
                title: 'Action',
                dataIndex: 'action',
                render: (_, record) => {
                    return (
                        <FunctionalButton
                            children='Show Trend'
                            disabled={record.total_consumption == null? true:false}
                            buttonClickFunction={onClick}
                            buttonClickFunctionArgs={[record.device_name, record.logger_name, setSelectedDevice]}
                            custom_button_style= {{
                                background:selectedDevice?.logger_name == record.logger_name? (isLightTheme? "#EEEEEE":"#282828"):(isLightTheme? "#FFFFFF":"#141414"),
                                color: record.total_consumption == null? "#808080":(isLightTheme? "#000000":"#ffffff"),
                                borderColor: record.total_consumption == null? (isLightTheme? "#D9D9D9":"#424242"):(isLightTheme? "#D9D9D9":"#424242"),
                                boxShadow: "unset"
                            }}
                        />
                    );
                }
            }
        ]
    }
}
