import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetDeviceTypeOption() {
  const [fetchWrapper] = useFetch();
  const [deviceTypeOption, setDeviceTypeOption] = useState([]);

  const getDeviceTypeOption = useCallback(() => {
    fetchWrapper({
      method: "GET",
      endpoint_url: "settings/getDeviceTypeOption",
      set_content_type_header: false,
      onSuccess: (fetch_output) => {
        setDeviceTypeOption(fetch_output.device_type_option);
      },
    });
  }, [fetchWrapper]);

  return { getDeviceTypeOption, deviceTypeOption };
}

export default useGetDeviceTypeOption;
