import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import FilterCard from "../common/FilterCard_V2";

function TenantListsFilterCard({ filter, setFilter }) {
  const [fetchWrapper] = useFetch();

  const [siteList, setSiteList] = useState([]);
  const [filterProperties, setFilterProperties] = useState([]);

  function getSite() {
    fetchWrapper({
      endpoint_url: "site_management/getFilterSite",
      params: {
        non_device: false,
      },
      onSuccess: (fetch_output) => {
        if ("site_filter_list" in fetch_output) {
          setSiteList(fetch_output.site_filter_list);
        }
      },
    });
  }

  function filterFormSubmitFailed(values) {}

  function filterFormSubmit(values) {
    setFilter({
      site: values.site_selection,
    });
    sessionStorage.setItem('site_id', values.site_selection);
  }

  // useEffects
  useEffect(() => {
    getSite();
  }, []);

  useEffect(() => {
    if(siteList.length != 0){
      setFilterProperties([
        {
          type: "select",
          name: "site_selection",
          label: "Sites",
          options: siteList.map((x) => {
            return { value: x.ID, label: x.LongName };
          }),
          defaultValue: sessionStorage.getItem('site_id') == undefined ? siteList[0]?.ID : parseInt(sessionStorage.getItem('site_id')),
        },
      ]);
    }
  }, [siteList]);

  useEffect(() => {
    if(filterProperties.length != 0 && Object.keys(filter).length == 0){
      setFilter({
        site: sessionStorage.getItem('site_id') == undefined ? siteList[0]?.ID : parseInt(sessionStorage.getItem('site_id'))
      })
    }
  }, [filterProperties])

  return (
    <FilterCard
      filter_properties={filterProperties}
      onSubmit={filterFormSubmit}
      onSubmitFailed={filterFormSubmitFailed}
      show_search_button={true}
      is_inline_layout={true}
    />
  );
}

export default TenantListsFilterCard;
