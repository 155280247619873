import { Form, DatePicker, Select, Input, Typography, Row, Col, InputNumber, Checkbox, Switch, Divider, Flex, TimePicker } from "antd";
import dayjs from "dayjs";
import '../../styles/common/custom_form_item/form_item.css'; 

const { Text, Title } = Typography;


/**
 * Auto populate form items for a form based on form_properties. 
 * Currently support datetime, date, time, select, text-input, number-input, switch, checkbox, text (text only)
 *
 * @export
 * @param {{ form_properties: object[]; width?: string; }} args
 * @param {*} args.form_properties
 * @param {string} [args.width='100%']
 * @returns 
 */
export default function CustomFormItems({form_properties, width='100%', form_item_style = {}}){

    return (
        <>
            {
                form_properties.map((form_item) => {
                    // check if there is children
                    if(form_item.children){
                        return (
                            <>
                                <Row>
                                    <Col xs={24} sm={5} md={4} lg={3} xl={2}><h3>{form_item.label}</h3></Col>
                                    <Col xs={3} sm={1} md={1} lg={1} xl={1} style={{display: 'flex', alignItems: 'center'}}><Divider type="vertical" style={{height: '75%'}} /></Col>
                                    <Col xs={21} sm={18} md={19} lg={20} xl={21}>
                                        <CustomFormItems form_properties={form_item.children} />
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                    else{
                        // check if need to convert defaultValue for dayjs
                        if (form_item.defaultValue && ["datetime-select", "date-select", "time-select"].includes(form_item.type) && typeof(form_item.defaultValue) === "string"){
                            form_item.defaultValue = dayjs(form_item.defaultValue);
                        }

                        // need to convert to boolean
                        if (['checkbox', 'switch'].includes(form_item.type) && typeof(form_item.defaultValue) === "string"){
                            if (form_item.defaultValue.toLowerCase() === 'true'){
                                form_item.defaultValue = true;
                            }
                            else if (form_item.defaultValue.toLowerCase() === 'false'){
                                form_item.defaultValue = false;
                            }
                        }

                        if (form_item.type === "text"){
                            return (
                                <Row align={"middle"}>
                                    <h3>{form_item.label}:</h3>
                                    <Text>{form_item.defaultValue}</Text>
                                </Row>
                            );
                        }
                        else{
                            return (
                                <Form.Item
                                    style={form_item_style}
                                    name={form_item.name}
                                    label={<Text strong>{form_item.label}</Text>}
                                    initialValue={form_item.defaultValue}
                                    // Checkbox and Switch ant design component has different value properties. need to overwrite for them.
                                    valuePropName={['checkbox', 'switch'].includes(form_item.type)? 'checked': 'value'}
                                    rules={[ 
                                        {
                                            required: form_item.required? form_item.required: false
                                        }
                                    ]}
                                >   
                                    {
                                        form_item.type === "datetime-select"?
                                        <DatePicker 
                                            showTime={form_item.showTime} 
                                            style={{width: width}} 
                                            format={form_item.format}
                                            picker={form_item.picker}
                                            disabled={form_item.disabled?form_item.disabled:false}
                                        />
                                        :
                                        form_item.type === "date-select"?
                                        <DatePicker 
                                            style={{width: width}} 
                                            disabled={form_item.disabled?form_item.disabled:false}
                                        />
                                        :
                                        form_item.type === "time-select"?
                                        <TimePicker 
                                            style={{width: width}} 
                                            disabled={form_item.disabled?form_item.disabled:false}
                                        />
                                        :
                                        form_item.type === "select"?
                                        <Select
                                            mode={form_item.mode}
                                            options={form_item.options}
                                            style={{width: width}}
                                            disabled={form_item.disabled?form_item.disabled:false}
                                        />
                                        :
                                        form_item.type === "input"?
                                        <Input 
                                            addonBefore={form_item.addon_before}
                                            addonAfter={form_item.addon_after}
                                            type={form_item.input_type}
                                            style={{width: width}}
                                            maxLength={form_item.maxLength}
                                            disabled={form_item.disabled?form_item.disabled:false}
                                        />
                                        :
                                        form_item.type === "input-number"?
                                        <InputNumber 
                                            style={{width: width}}
                                            type="number"
                                            addonBefore={form_item.addon_before}
                                            addonAfter={form_item.addon_after}
                                            maxLength={form_item.maxLength}
                                            disabled={form_item.disabled?form_item.disabled:false}
                                        />
                                        :
                                        form_item.type === "checkbox"?
                                        <Checkbox
                                            disabled={form_item.disabled?form_item.disabled:false}
                                        />
                                        :
                                        form_item.type === "switch"?
                                        <Switch 
                                            disabled={form_item.disabled?form_item.disabled:false}
                                        />
                                        :
                                        <Text>Invalid Type</Text>
                                    }
                                </Form.Item>
                            )
                        }
                    }
                })
            }
        </>
    );
}
