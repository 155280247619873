import { useEffect, useState } from "react";
import { Row, Col } from "antd"
import FilterCard from "../../components/common/FilterCard_V2";
import DeviceHealthCard from "../../components/dashboard/DeviceHealthCard";
import SubFilterCard from "../../components/dashboard/SubFilterCard";
import LeftCard from "../../components/dashboard/LeftCard";
import RightCard from "../../components/dashboard/RightCard";
import useFetch from "../../hooks/useFetch";
import StatisticInfo from "../../components/dashboard/StatisticInfo";

function DashboardPage() {
  const [fetchWrapper] = useFetch();

  const [abortController, setAbortController] = useState(new AbortController());

  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);

  const [filterProperties, setFilterProperties] = useState([]);
  const [filter, setFilter] = useState({});
  const [dateRangeSelection, setDateRangeSelection] = useState({});
  const [selectedDevice, setSelectedDevice] = useState({});

  const [isDeviceHealthCardLoading, setIsDeviceHealthCardLoading] = useState(true);
  const [isStatisticCardLoading, setIsStatisticCardLoading] = useState(true);
  const [isSummaryCardLoading, setIsSummaryCardLoading] = useState(true);
  const [isEnergyConsumptionDistributionCardLoading, setIsEnergyConsumptionDistributionCardLoading] = useState(true);
  const [isDeviceListTabsLoading, setIsDeviceListTabsLoading] = useState(true);
  const [isShowMeterTrendingChart, setIsShowMeterTrendingChart] = useState(false);

  const [siteList, setSiteList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [statisticData, setStatisticData] = useState({statistic: [{title: "Purchased Energy From TNB"}, {title: "PV Export"}, {title: "PV Generation"}, {title: "PV Usage"}]});
  const [deviceHealth, setDeviceHealth] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [energyConsumptionDistributionData, setEnergyConsumptionDistributionData] = useState();
  const [deviceListData, setDeviceListdata] = useState({});
  const [displayUnit, setDisplayUnit] = useState();

  const [upperInterval, setUpperInterval] = useState();
  const [lowerInterval, setLowerInterval] = useState();
  

  function getSite(){
    fetchWrapper({
      endpoint_url: "site_management/getFilterSite",
      params: {
        'non_device': false
      },
      onSuccess: (fetch_output) => {
        if("site_filter_list" in fetch_output){
          setSiteList(fetch_output.site_filter_list)
        }
      },
    })
  }

  function getUnit(){
    fetchWrapper({
      endpoint_url: "dashboard/getUnit",
      method: "GET",
      set_content_type_header: false,
      onSuccess: (fetch_output) => {
        if("unit_list" in fetch_output){
          setUnitList(fetch_output.unit_list)
        }
      },
    })
  }

  function getDeviceHealth(){
    fetchWrapper({
      endpoint_url: 'dashboard/getDeviceHealth',
      params: filter,
      onSuccess: (fetch_output) => {
        if(!("error" in fetch_output)){
          setDeviceHealth(fetch_output)
          setIsDeviceHealthCardLoading(false)
        }
      }
    })
  }

  function getStatisticData(){
    fetchWrapper({
      endpoint_url: 'dashboard/getStatistic',
      params: filter,
      onSuccess: (fetch_output) => {
        if(!("error" in fetch_output)){
          setStatisticData(fetch_output)
          setIsStatisticCardLoading(false)
        }
      }
    })
  }

  function getSummaryData(){
    fetchWrapper({
      endpoint_url: 'dashboard/getSummary',
      params: {...filter, ...dateRangeSelection},
      abort_controller: abortController,
      onSuccess: (fetch_output) => {
        if(!("error" in fetch_output)){
          setSummaryData(fetch_output)
          setIsSummaryCardLoading(false)
        }
      }
    })
  }

  function getEnergyConsumptionDistributionData(){
    fetchWrapper({
      endpoint_url: 'dashboard/getEnergyConsumptionDistribution',
      params: {...filter, ...dateRangeSelection},
      abort_controller: abortController,
      onSuccess: (fetch_output) => {
        if(!("error" in fetch_output)){
          setEnergyConsumptionDistributionData(fetch_output?.data)
          if(displayUnit != fetch_output?.unit){
            setDisplayUnit(fetch_output?.unit)
          }
          setIsEnergyConsumptionDistributionCardLoading(false)
        }
      }
    })
  }

  function getDeviceListData(){
    fetchWrapper({
      endpoint_url: 'dashboard/getDeviceList',
      params: {...filter, ...dateRangeSelection},
      abort_controller: abortController,
      onSuccess: (fetch_output) => {
        if(!("error" in fetch_output)){
          setDeviceListdata(fetch_output)
          setIsDeviceListTabsLoading(false)
        }
      }
    })
  }

  function filterFormSubmit(values) {
    setFilter({
      site: values.site_selection,
      unit: values.unit_selection
    })
    sessionStorage.setItem('site_id', values.site_selection);
    setSelectedDevice({})
  }

  function filterFormSubmitFailed(values) {
  }

  // useEffects
  useEffect(() => {
    getSite()
    getUnit()

    const updateAvailableHeight = () => {
        // Calculate height excluding the header
        setAvailableHeight(window.innerWidth >= 1200 ? window.innerHeight - 150 - 210 : '');
    };

    // Initial calculation
    updateAvailableHeight();

    // Update height on resize
    window.addEventListener('resize', updateAvailableHeight);

    // Clean up event listener on component unmount
    return () => {
        window.removeEventListener('resize', updateAvailableHeight);
    };
  }, []);

  useEffect(() => {

    //Set filter properties when site and unit list are available
    if(siteList.length != 0 && unitList.length != 0){
      setFilterProperties([
        {
          type: "select",
          name: "site_selection",
          label: "Sites",
          options: siteList.map((x)=>{return{value: x.ID, label: x.LongName}}),
          defaultValue: sessionStorage.getItem('site_id') == undefined ? siteList[0]?.ID : parseInt(sessionStorage.getItem('site_id')),
        },
        {
          type: "select",
          name: "unit_selection",
          label: "Unit",
          options: unitList.map((x)=>{return{value: x.ID, label: x.Name}}),
          defaultValue: 1,
        }
      ])
    }

  }, [siteList, unitList])

  useEffect(() => {
    if(filterProperties.length != 0 && Object.keys(filter).length == 0){
      setFilter({
        site: sessionStorage.getItem('site_id') == undefined ? siteList[0]?.ID : parseInt(sessionStorage.getItem('site_id')),
        unit: 1
      })
    }
  }, [filterProperties])

  useEffect(() => {
    if(Object.keys(filter).length !== 0){
      //Abort summary, energy consumption distribution, and device list's fetch request
      abortController.abort('')
      //Assign a new abort controller
      setAbortController(new AbortController())

      if(upperInterval){
        clearInterval(upperInterval);
      }

      //Trigger loading
      setIsDeviceHealthCardLoading(true)
      setIsStatisticCardLoading(true)

      //Call API
      getDeviceHealth()
      getStatisticData()

      //Update data every 10 minutes
      setUpperInterval(
        setInterval(()=>{
          getDeviceHealth()
          getStatisticData()
        }, 600000)
      )
    }
  }, [filter])

  useEffect(() => {
    //Get Summary, Energy Consumption Distribution, Device Lists, Trending
    if(Object.keys(filter).length !== 0 && Object.keys(dateRangeSelection).length !== 0){

      if(lowerInterval){
        clearInterval(lowerInterval);
      }

      //Trigger loading
      setIsSummaryCardLoading(true)
      setIsEnergyConsumptionDistributionCardLoading(true)
      setIsDeviceListTabsLoading(true)
      setIsShowMeterTrendingChart(false)

      //Call API
      getSummaryData()
      getEnergyConsumptionDistributionData()
      getDeviceListData()

      //Update data every 10 minutes
      setLowerInterval(
        setInterval(()=>{
          getSummaryData()
          getEnergyConsumptionDistributionData()
          getDeviceListData()
        }, 600000)
      )
    }
  }, [dateRangeSelection, abortController])

  useEffect(()=>{
    // Clean up event listener on component unmount
    return () => {
      clearInterval(upperInterval);
      clearInterval(lowerInterval);
    };
  },[upperInterval, lowerInterval])

  return (
    <>
      <Row gutter={[12,10]} style={{height:'100%'}}>
        <Col span={24}>
          <div className="dashboard-filter-card">
            <FilterCard
              filter_properties={filterProperties}
              onSubmit={filterFormSubmit}
              onSubmitFailed={filterFormSubmitFailed}
              show_search_button={true}
              is_inline_layout={true}
            />  
          </div>
        </Col>
        <Col span={24}>
          <Row gutter={[12,12]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={7} xxl={6}>
              <DeviceHealthCard data={deviceHealth} isLoading={isDeviceHealthCardLoading}/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={17} xxl={18}>
              <StatisticInfo
                statisticData={statisticData}
                isStatisticCardLoading={isStatisticCardLoading}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <SubFilterCard
            dateRangeSelection={dateRangeSelection}
            setDateRangeSelection={setDateRangeSelection}
            filter={filter}
            setIsSummaryCardLoading={setIsSummaryCardLoading}
            setIsEnergyConsumptionDistributionCardLoading={setIsEnergyConsumptionDistributionCardLoading}
            setIsDeviceListTabsLoading={setIsDeviceListTabsLoading}
            setIsShowMeterTrendingChart={setIsShowMeterTrendingChart}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[12,10]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={7} xxl={6} style={{height: availableHeight}}>
              <LeftCard
                summaryData={summaryData}
                isSummaryCardLoading={isSummaryCardLoading}
                energyConsumptionDistributionData={energyConsumptionDistributionData}
                isEnergyConsumptionDistributionCardLoading={isEnergyConsumptionDistributionCardLoading}
                displayUnit={displayUnit}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={17} xxl={18} style={{height: availableHeight}}>
              <RightCard
                deviceListData={deviceListData}
                isDeviceListTabsLoading={isDeviceListTabsLoading}
                isShowMeterTrendingChart={isShowMeterTrendingChart}
                dateRangeSelection={dateRangeSelection}
                setIsShowMeterTrendingChart={setIsShowMeterTrendingChart}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DashboardPage;
