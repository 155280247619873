import { Form } from "antd";
import { useEffect, useState, useContext } from "react";
import CustomCard from "../../../common/CustomCard";
import CustomTabs from "../../../common/CustomTabs";
import ActiveMetersTable from "./active_meters/ActiveMetersTable";
import UnconfiguredMetersTable from "./unconfigured_meters/UnconfiguredMetersTable";
import FunctionalButton from "../../../common/FunctionalButton";
import MetersManagementForm from "./MetersManagementForm";

import { IndexContext } from "../../../../context/IndexContext";

import useGetDeviceTypeOption from "../../../../functions/Settings/meter_management/getDeviceTypeOption";
import useUpdateMeter from "../../../../functions/Settings/meter_management/updateMeter";
import useGetActiveMeters from "../../../../functions/Settings/meter_management/getActiveMeters";
import useGetUnconfiguredMeters from "../../../../functions/Settings/meter_management/getUnconfiguredMeters";

export default function MetersManagementCard({ refresh, setRefresh, filter }) {
  const [meterManagementInsertForm] = Form.useForm();

  const { messageApi } = useContext(IndexContext);

  const [meterManagementEditForm] = Form.useForm();
  const [editVisible, setEditVisible] = useState(false);
  const [visibleData, setVisibleData] = useState({});

  const { getDeviceTypeOption, deviceTypeOption } = useGetDeviceTypeOption();
  const { updateMeter, updateMeterLoading } = useUpdateMeter();
  const { getActiveMeters, activeMeters, activeMetersLoading } = useGetActiveMeters();
  const { getUnconfiguredMeters, unconfiguredMeters, unconfiguredMetersLoading } = useGetUnconfiguredMeters();

  const [metersManagementQuantity, setMetersQuantity] = useState({
    active_meters: 3,
    unconfigured_meters: 1,
  });

  const [activeMetersData, setActiveMetersData] = useState([]);

  const [unconfiguredMetersData, setUnconfiguredMetersData] = useState([]);

  function validateSiteInfo(object) {
    var target = true
    // Create an array of error messages for each empty field
    const errors = Object.entries(object)
        .filter(([key, value]) => value === undefined || value === null || value === '')
        .map(([key]) => {
          target = false
          messageApi.open({
            type: "error",
            content: `Field '${key}' cannot be empty`,
          });         
        }
      );
    
      return target
  }

  const handleEditConfirm = (value, key) => {
    console.log(value, key)
    if(validateSiteInfo(value)){
      return new Promise((resolve, reject) => {
        updateMeter(value, key).then((data) => {
          if('error' in data){

          } else {
            handleEditCancel();
            getActiveMeters(filter, setRefresh)
          }
          resolve();
        }).catch((err)=>{
          resolve(err);
        }
      )}
      )
    }
  };

  const handleEditCancel = () => setEditVisible(false);

  useEffect(()=>{
    setMetersQuantity(metersManagementQuantity => ({
      ...metersManagementQuantity,
      active_meters: activeMeters.length
    }));
    setActiveMetersData(activeMeters)
  }, [activeMeters])

  useEffect(()=>{
    setMetersQuantity(metersManagementQuantity => ({
      ...metersManagementQuantity,
      unconfigured_meters: unconfiguredMeters.length
    }));
    setUnconfiguredMetersData(unconfiguredMeters)
  }, [unconfiguredMeters])

  useEffect(()=>{
    if(refresh == true){
      setMetersQuantity({
        active_meters: 0,
        unconfigured_meters: 0,
      })
      setActiveMetersData([])
      setUnconfiguredMetersData([])
      getActiveMeters(filter, setRefresh)
      getUnconfiguredMeters(filter)
      getDeviceTypeOption()
    }
  },[refresh])

  useEffect(()=>{
    setRefresh(true)
  },[])

  return (
    <>
      <CustomCard
        title="Meters Management"
        card_style={{ height: "100%" }}
        title_style={{ background: "#303030", padding: "8px 12px" }}
        children_style={{ padding: "0px" }}
        children={
          <CustomTabs
            tab_style={{ background: "#1D1D1D" }}
            tab_children_styles={{
              background: "#141414",
              padding: "0px",
              height: "100%",
            }}
            tabs={[
              {
                title:
                  "Active Meters ( " +
                  metersManagementQuantity["active_meters"] +
                  " )",
                key: "1",
                content: (
                  <ActiveMetersTable
                    activeMetersLoading={activeMetersLoading}
                    activeMetersData={activeMetersData}
                    setVisible={setEditVisible}
                    visible={editVisible}
                    setVisibleData={setVisibleData}
                  />
                ),
              },
              {
                title:
                  "Unconfigured Meters ( " +
                  metersManagementQuantity["unconfigured_meters"] +
                  " )",
                key: "2",
                content: (
                  <UnconfiguredMetersTable
                    unconfiguredMetersLoading={unconfiguredMetersLoading}
                    unconfiguredMetersData={unconfiguredMetersData}
                    setVisible={setEditVisible}
                    visible={editVisible}
                    setVisibleData={setVisibleData}
                  />
                ),
              },
            ]}
          />
        }
      />

      <MetersManagementForm
        deviceTypeOption={deviceTypeOption}
        form={meterManagementEditForm}
        visible={editVisible}
        modalTitle={"Edit Meters"}
        handleFinish={handleEditConfirm}
        handleCancel={handleEditCancel}
        visibleData={visibleData}
        loading={updateMeterLoading}
      />
    </>
  );
}
