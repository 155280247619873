import { useContext, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useUploadManuallyInvoices() {
    const [fetchWrapper] = useFetch();
    const { messageApi } = useContext(IndexContext);

    const uploadManullyInvoices = useCallback((formData) => {
        return new Promise((resolve, reject) => {
            fetchWrapper({
                endpoint_url: "invoice/uploadManuallyInvoices",
                set_content_type_header: false,
                body: formData,
                onSuccess: (fetch_output) => {
                    if (fetch_output.error) {
                        messageApi.open({
                            type: "error",
                            content: "Failed to upload manual invoice file"
                        });
                        for (let each_error in fetch_output.error) {
                            messageApi.open({
                                type: "error",
                                content: fetch_output.error[each_error]
                            });
                        }
                        reject(fetch_output);
                    } else {
                        messageApi.open({
                            type: "success",
                            content: "File uploaded manual invoice successfully"
                        });
                        resolve(fetch_output);
                    }
                },
                onFailure: (error) => {
                    messageApi.open({
                        type: "error",
                        content: "Failed to upload manual file"
                    });
                    reject(error);
                }
            });
        });
    }, [fetchWrapper, messageApi]);

    return { uploadManullyInvoices };
}

export default useUploadManuallyInvoices;
