import { useState, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetManualInvoiceProgress() {
    const [fetchWrapper] = useFetch();
    const [manualInvoiceProgress, setManualInvoiceProgress] = useState([])

    const getManualInvoiceProgress = useCallback(() => {
        fetchWrapper({
            endpoint_url: "invoice/getManualInvoiceProgress",
            method: 'GET',
            set_content_type_header: false,            
            onSuccess: (fetch_output) => {
                setManualInvoiceProgress(fetch_output.manual_progress_lists)
            },
        });
    }, [fetchWrapper]);

    return { getManualInvoiceProgress, manualInvoiceProgress };
}

export default useGetManualInvoiceProgress;
