import { useEffect, useRef } from "react";
import { Flex, Skeleton } from "antd";
import { TinyColumn } from "@antv/g2plot";
import { useContext } from 'react';
import PropTypes from 'prop-types';

import { IndexContext } from '../../context/IndexContext';
import CustomCard from "./CustomCard";
import { validateProp } from '../../hooks/useValidate';

export default function StatisticCard({
    isLoading = false
    ,title = ""
    ,title_icon
    ,container_name = ""
    ,chartData = []
    ,custom_card_title_style = {}
    ,custom_card_children_style = {}
    ,isShowChart = false
    ,chart_color = {}
    ,main_content_list = []
    ,sub_content_list = []
    ,sub_content_style = {}
}) {
    const chartRef = useRef();
    const { isLightTheme } = useContext(IndexContext);

    useEffect(()=>{
        if(isShowChart && !isLoading){
            chartRef.current = new TinyColumn(container_name, {
                autoFit: true,
                padding: [0, -2, -15, -2],
                data: chartData,
                columnWidthRatio: 1,
                columnStyle: {
                    fill: isLightTheme? chart_color.light_theme : chart_color.dark_theme,
                    stroke: isLightTheme? chart_color.light_theme : chart_color.dark_theme,
                    shadowColor: isLightTheme? chart_color.light_theme : chart_color.dark_theme,
                    lineWidth: 0,
                },
            })
            chartRef.current.render();
        }
    },[container_name, isShowChart, isLoading])

    useEffect(()=>{
        if(isShowChart && !isLoading){
            chartRef.current?.changeData(chartData)
        }
    }, [chartData])

    useEffect(()=>{
        if(isLoading || !isShowChart){
            chartRef.current?.destroy()
        }
    },[isLoading, isShowChart])

    useEffect(()=>{
        if(isShowChart && !isLoading){
            chartRef.current?.update({
                columnStyle: {
                    fill: isLightTheme? chart_color.light_theme : chart_color.dark_theme,
                    stroke: isLightTheme? chart_color.light_theme : chart_color.dark_theme,
                    shadowColor: isLightTheme? chart_color.light_theme : chart_color.dark_theme,
                    lineWidth: 0,
                },
            })
        }
    },[isLightTheme])

    return (
        <>
            <CustomCard
                title={
                    <Flex gap="small">
                        {title_icon}
                        {title}
                    </Flex>
                }
                title_style={{padding: "6px 12px", ...custom_card_title_style}}
                children_style={{padding: "0px", height: "100px", ...custom_card_children_style}}
                children={
                    <>
                        <Skeleton loading={isLoading} paragraph={{rows: 1}} style={{padding: 10}} active>
                            <div id={container_name} style={{height: "inherit", width: "100%", paddingTop: "5px", borderRadius: "7px", overflow: "hidden", position: "absolute"}}></div>
                            <div style={{position: "absolute", zIndex: 0, height: "inherit", width: "100%"}}>
                                <Flex vertical={true} justify="space-between" style={{height: "inherit", padding: 10, color: isLightTheme ? '#000' : '#fff'}}>
                                    <Flex justify="start" align="center" gap={"small"}>
                                        {main_content_list.map((mainContent, index)=>{return(<div key={index}>{mainContent}</div>)})}
                                    </Flex>
                                    <Flex justify="end" align="center" gap={"small"} style={{fontWeight: 500, fontStyle: "italic", ...sub_content_style}}>
                                        {sub_content_list.map((subContent, index)=>{return(<div key={index}>{subContent}</div>)})}
                                    </Flex>
                                </Flex>
                            </div>
                        </Skeleton>
                        
                    </> 
                }
            />
        </>
    );
}

StatisticCard.propTypes = {
    isLoading: validateProp('boolean'),
    title: validateProp('string'),
    children_style: validateProp('style'),
    title_icon: validateProp('node'),
    container_name: validateProp('string'),
    chartData: validateProp('shape'),
    isShowChart: validateProp('boolean'),
    chart_color: PropTypes.shape({
        lightTheme: validateProp('string'),
        darkTheme: validateProp('string'),
    }),
    main_content_list: PropTypes.arrayOf(validateProp('node')),
    sub_content_list: PropTypes.arrayOf(validateProp('node')),
    sub_content_style: validateProp('style'),
};