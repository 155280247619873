import { useContext, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useRegenerateInvoice() {
    const [fetchWrapper] = useFetch();
    const { messageApi } = useContext(IndexContext);

    const regenerateInvoice = useCallback((info) => {
        return new Promise((resolve, reject) => {
            fetchWrapper({
                endpoint_url: "invoice/regenerateInvoice",
                params: {
                    invoice_id: info.invoice
                },
                onSuccess: (response) => {
                    console.log(response)
                    messageApi.open({
                        type: "success",
                        content: "Regenerated invoice successfully"
                    });
                    resolve(response);
                },
                onFailure: (error) => {
                    messageApi.open({
                        type: "error",
                        content: "Failed to regenerated invoice"
                    });
                    reject(error);
                }
            });
        });
    }, [fetchWrapper, messageApi]);

    return { regenerateInvoice };
}

export default useRegenerateInvoice;
