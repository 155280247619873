import { useContext, useState, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useUpdatePendingDataInvoices() {
    const [fetchWrapper] = useFetch();
    const [loading, setLoading] = useState(false);
    const { messageApi } = useContext(IndexContext);

    const updatePendingDataInvoices = useCallback((pending_invoice_id, invoiceFilter) => {
        setLoading(true);
        
        return new Promise((resolve, reject) => {
            fetchWrapper({
                endpoint_url: "invoice/updatePendingDataInvoices",
                params: {
                    'pending_invoice_id': pending_invoice_id,
                    'invoice_filter': invoiceFilter,
                },
                onSuccess: (fetch_output) => {
                    messageApi.open({
                        type: "success",
                        content: fetch_output.message,
                    });
                    setLoading(false);
                    resolve(fetch_output);
                },
                onFailure: (error) => {
                    messageApi.open({
                        type: "error",
                        content: error,
                    });
                    setLoading(false);
                    reject(error);
                },
            });
        });
    }, [fetchWrapper]);

    return { updatePendingDataInvoices, loading };
}

export default useUpdatePendingDataInvoices;
