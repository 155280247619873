import { useContext } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CustomTabs from "../../common/CustomTabs";
import DataTable from "../../common/DataTable";
import { getTableColumn } from "../../../functions/Dashboard/getTableColumn";
import { IndexContext } from '../../../context/IndexContext';
import "../../../styles/dashboard/DashboardPage.css"

export default function DeviceListTabs({
    devicesData
    ,tableContainerRef
    ,tableHeightOffset
    ,selectedDevice
    ,setSelectedDevice
    ,isDeviceListTabsLoading
}) {
    const { isLightTheme } = useContext(IndexContext);
    
    return (
        <Spin
            wrapperClassName="device-list-loading"
            indicator={<LoadingOutlined spin />}
            spinning={isDeviceListTabsLoading}
            size="large"
            style={{height: "100%"}}
        >
            {
                isDeviceListTabsLoading? <></>:
                <CustomTabs
                    tab_style={{background:isLightTheme? '#F3F3F3':'#1D1D1D'}}
                    tab_children_styles={{padding:'0px', height:'100%'}}
                    tabs={
                        devicesData.data?.map((data, index)=>{
                            return {
                                title: data.device_type,
                                key: index.toString(),
                                content:    <DataTable 
                                                size={"small"}
                                                pagination_style= {{ margin:'10px' }} 
                                                table_data= {data.table_data}
                                                layoutRef={tableContainerRef}
                                                tableWrapperViewportHeight={0.5}
                                                tableHeightOffset={tableHeightOffset}
                                                show_sorter_tooltip={false}
                                                table_column= {
                                                    getTableColumn({
                                                        device_type: data.device_type,
                                                        selectedDevice: selectedDevice,
                                                        setSelectedDevice: setSelectedDevice,
                                                        isLightTheme: isLightTheme
                                                    })
                                                } 
                                            />
                            }        
                        })
                    }
                />
            }
        </Spin>
    );
}