import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useUpdateMoveOut() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);

  const updateMoveOut = useCallback(
    (move_out_info, key) => {
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "settings/updateMoveOut",
          params: {
            move_out_info: move_out_info,
            key: key,
          },
          onSuccess: (fetch_output) => {
            if ("error" in fetch_output){
              messageApi.open({
                type: "error",
                content: fetch_output.error,
              });
            } else {
              messageApi.open({
                type: "success",
                content: fetch_output.message,
              });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            reject(error);
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateMoveOut };
}
export default useUpdateMoveOut;
