import { Button, Flex, Form, Input, Select, Table } from "antd";
import { useState } from "react";
import FunctionalButton from "./FunctionalButton";

export default function CustomEditTable({
  form,
  columnType,
  data,
  setData,
  handleDataType,
  addDataType,
  addFunction = false,
  addButtonText = "Add Row",
  deleteFunction = false,
  deleteDisabled = false,
}) {
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const handleCancel = () => {
    setEditingKey("");
  };

  const handleDelete = (record) => {
    const newData = data.filter((item) => item.key !== record["key"]);
    setData((prevData) => ({ ...prevData, [handleDataType]: newData }));
  };

  const handleAdd = () => {
    const newKey = data.length + 1;
    const newData = { key: newKey, [addDataType]: "testing" };

    console.log("Data: ", data);
    console.log("HandleDataType: ", handleDataType);

    setData((prevData) => ({
      ...prevData,
      [handleDataType]: [...prevData[handleDataType], newData],
    }));

    setEditingKey(newKey);
  };

  const handleSave = async (record) => {
    try {
      const row = await form.validateFields();

      const newData = [...data];
      const index = newData.findIndex((item) => record["key"] === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });

        setData((prevData) => ({
          ...prevData,
          [handleDataType]: newData,
        }));

        setEditingKey("");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const columns = [
    columnType,
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Flex gap="middle" wrap>
            <Button
              onClick={() => handleSave(record)}
              style={{
                width: "100px",
                background: "#282828",
                border: "1px solid #303030",
              }}
            >
              Save
            </Button>

            {deleteFunction ? (
              <FunctionalButton
                buttonClickFunction={() => handleDelete(record)}
                custom_button_style={{
                  width: "100px",
                  background: deleteDisabled ? "black" : "red",
                }}
                disabled={deleteDisabled}
                children="Delete"
              />
            ) : (
              <FunctionalButton
                buttonClickFunction={() => handleCancel()}
                custom_button_style={{ width: "100px" }}
                children="Cancel"
              />
            )}
          </Flex>
        ) : (
          <Flex gap="middle" wrap>
            <Button
              onClick={() => edit(record)}
              style={{
                width: "100px",
                background: "#282828",
                border: "1px solid #303030",
              }}
            >
              Edit
            </Button>
            {deleteFunction && (
              <FunctionalButton
                buttonClickFunction={() => handleDelete(record)}
                custom_button_style={{
                  width: "100px",
                  background: deleteDisabled ? "black" : "red",
                }}
                disabled={deleteDisabled}
                children="Delete"
              />
            )}
          </Flex>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) return col;
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        options: col.options,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form.Item className="table-edit-lot">
      <Table
        components={{ body: { cell: EditableCell } }}
        dataSource={data}
        size="small"
        columns={mergedColumns}
        scroll={{y:130}}
        pagination={false}
        rowClassName="editable-row"
      />
      {addFunction && (
        <Flex justify="flex-end" style={{ padding: "10px 12px" }}>
          <Button
            onClick={handleAdd}
            style={{
              width: "50%",
              background: "#282828",
              border: "1px solid #303030",
            }}
          >
            {addButtonText}
          </Button>
        </Flex>
      )}
    </Form.Item>
  );
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  options = [],
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "select" ? <Select options={options} /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
