import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetUnconfiguredMeters() {
  const [fetchWrapper] = useFetch();
  const [unconfiguredMeters, setUnconfiguredMeters] = useState([]);
  const [unconfiguredMetersLoading, setUnconfiguredMetersLoading] = useState(false)

  const getUnconfiguredMeters = useCallback((filter) => {
    setUnconfiguredMetersLoading(true)
    fetchWrapper({
      endpoint_url: "settings/getUnconfiguredMeters",
      params: filter,
      onSuccess: (fetch_output) => {
        setUnconfiguredMeters(fetch_output.unconfigured_meters);
        setUnconfiguredMetersLoading(false)
      },
    });
  }, [fetchWrapper]);

  return { getUnconfiguredMeters, unconfiguredMeters, unconfiguredMetersLoading };
}

export default useGetUnconfiguredMeters;
