import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetActiveMeters() {
  const [fetchWrapper] = useFetch();
  const [activeMeters, setActiveMeters] = useState([]);
  const [activeMetersLoading, setActiveMeterLoading] = useState(false)

  const getActiveMeters = useCallback((filter, setRefresh) => {
    setActiveMeterLoading(true)
    fetchWrapper({
      endpoint_url: "settings/getActiveMeters",
      params: filter,
      onSuccess: (fetch_output) => {
        setRefresh(false)
        setActiveMeters(fetch_output.active_device);
        setActiveMeterLoading(false)
      },
    });
  }, [fetchWrapper]);

  return { getActiveMeters, activeMeters, activeMetersLoading };
}

export default useGetActiveMeters;
