import CustomModal from "../../../common/CustomModal";
import CustomCard from "../../../common/CustomCard";
import CustomForm from "../../../common/CustomForm";
import { useEffect } from "react";


export default function PairingMeterModal({
  form,
  visible,
  modalTitle,
  handleFinish,
  handleCancel,
  vacantLotOptions,
  vacantMeterOptions
}) {

  const LOT_METER_MANAGEMENT_FORM_PROPERTIES = [
    {
      type: "select",
      name: "lot_id",
      label: "Lot Name",
      mode: "multiple",
      options: vacantLotOptions
    },
    {
      type: "select",
      name: "meter_id",
      label: "Meter Name",
      mode: "multiple",
      options: vacantMeterOptions
    },
  ];

  return (
    <CustomModal
      visible={visible}
      onCancel={handleCancel}
      title={<h4 style={{ margin: "0px", fontWeight: "500" }}>{modalTitle}</h4>}
      title_style={{
        background: "#1F1F1F",
      }}
      modal_style={{
        marginTop: "",
      }}
      content={
        <CustomCard
          title="Lot Details"
          card_style={{ height: "100%" }}
          title_style={{
            background: "#303030",
            padding: "6px 16px",
            borderRadius: "0px",
            fontSize: "16px",
            fontWeight: "400",
          }}
          children_style={{ padding: "3px 16px", background: "#141414" }}
          children={
            <CustomForm
              form={form}
              formProperties={LOT_METER_MANAGEMENT_FORM_PROPERTIES}
              handleFinish={handleFinish}
              handleCancel={handleCancel}
            />
          }
        />
      }
    />
  );
}
