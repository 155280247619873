import { Pie } from "@antv/g2plot";
import { useRef, useEffect } from "react";

export default function PieChart({
    containerName
    ,data 
    ,unit
}) {
    const chartRef = useRef();

    useEffect(()=>{
        chartRef.current?.destroy()
        chartRef.current = new Pie(containerName, {
            autoFit: true,
            data: data,
            angleField: 'total_consumption',
            colorField: 'display_name',
            color: ['#5B8FF9', '#61DDAA', '#65789B', '#F6BD16', '#7262FD', '#78D3F8', '#9661BC', '#F6903D', '#008685', '#F08BB4', '#FF6B3B', '#BBE800', '#FFC100', '#9FB40F', '#76523B', '#DAD5B5', '#0E8E89', '#E19348', '#F383A2', '#247FEA'],
            radius: 0.8,
            label: {
                type: 'inner',
                content: '{percentage}'
            },
            interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
            legend: {
                offsetX: -20
            },
            tooltip: {
                formatter: (value, ctg) => {
                    return {
                        name: value.display_name,
                        value: (Math.round(value["total_consumption"] * 100) / 100).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) +" "+ unit
                    }
                }
            }
        })

        chartRef.current?.render()

    }, [containerName])

    useEffect(()=>{
        chartRef.current?.changeData(data) 
    },[data])

    useEffect(()=>{
        chartRef.current?.update({
            tooltip: {
                formatter: (value, ctg) => {
                    return {
                        name: value.display_name,
                        value: (Math.round(value["total_consumption"] * 100) / 100).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) +" "+ unit
                    }
                }
            }
        })
    }, [unit])

    return (
        <div id={containerName} style={{width: "100%", height: '100%'}}></div>
    );
}