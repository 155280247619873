import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetActiveSites() {
  const [fetchWrapper] = useFetch();
  const [activeSites, setActiveSites] = useState([]);
  const [activeSitesLoading, setActiveSitesLoading] = useState(false)

  const getActiveSites = useCallback((setRefresh, setSiteLoading) => {
    setActiveSitesLoading(true)
    fetchWrapper({
      method: "GET",
      endpoint_url: "settings/getActiveSites",
      set_content_type_header: false,
      onSuccess: (fetch_output) => {
        setActiveSitesLoading(false)
        setActiveSites(fetch_output.active_site);
        setSiteLoading(false)
        setRefresh(false)
      },
    });
  }, [fetchWrapper]);

  return { getActiveSites, activeSites, setActiveSites, activeSitesLoading};
}

export default useGetActiveSites;
