import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";

function useGetUnconfiguredLots() {
  const [fetchWrapper] = useFetch();
  const [unconfiguredLots, setUnconfiguredLots] = useState([]);
  const [unconfiguredLoading, setUnconfiguredLoading] = useState(false)

  const getUnconfiguredLots = useCallback((filter) => {
    setUnconfiguredLoading(true)
    fetchWrapper({
      params: filter,
      endpoint_url: "settings/getUnconfiguredLots",
      onSuccess: (fetch_output) => {
        setUnconfiguredLots(fetch_output.unconfigured_lots);
        setUnconfiguredLoading(false)
      },
    });
  }, [fetchWrapper]);

  return { getUnconfiguredLots, unconfiguredLots, unconfiguredLoading };
}

export default useGetUnconfiguredLots;
