import { Col, Row, Spin } from "antd";
import { LoadingOutlined, DownloadOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef, useContext } from "react";
import CustomCard from "../common/CustomCard";
import MeterListsTable from "./table/MeterListsTable";
import useFetch from "../../hooks/useFetch";
import FunctionalButton from "../common/FunctionalButton";
import { IndexContext } from "../../context/IndexContext";
import dayjs from "dayjs";

function MeterListsTableCard({ filter }) {
  const [fetchWrapper] = useFetch();
  const tableContainerRef = useRef();
  const { isLightTheme } = useContext(IndexContext);
  const { messageApi } = useContext(IndexContext);

  const [height, setHeight] = useState("unset");
  const [meterList, setMeterList] = useState();
  const [filters, setFilters] = useState();
  const [isMeterListLoading, setIsMeterListLoading] = useState(true);
  const [tableHeightOffset, setTableHeightOffset] = useState(90);

  function getMeterList(){
    fetchWrapper({
      endpoint_url: "device_lists/getDeviceList",
      params: filter,
      onSuccess: (fetch_output) => {
        if(!("error" in fetch_output)){
          setMeterList(fetch_output.data)
          setFilters(fetch_output.filters)
          setIsMeterListLoading(false)
        }
      }
    })
  }

  const onDownloadButtonClicked = () => {
    const filename = `DeviceList_${dayjs().format("YYYYMMDDTHHmmss")}.xlsx`;

      return new Promise((resolve, reject) => {
          fetchWrapper({
              endpoint_url: 'device_lists/downloadDeviceList',
              params: {...filter},
              json_output: false,
              onSuccess: (response) => {
                resolve(true)
                // returned a json response.
                if(response.headers.get("content-type") === "application/json"){
                    response.json().then((data) => {
                        if (Object.keys(data).includes('error')){
                            // has issue with the download file
                            messageApi.open({
                                type: "error",
                                content: data.error
                            })
                        }
                        else{
                            messageApi.open({
                                type: "error",
                                content: "Failed to download Device list."
                            })
                        }
                    })
                }
                else{
                    // no issue with the response. returned a spreadsheet
                    response.blob().then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    })
                }
    
              }
            })
      });
  }

  useEffect(()=>{

    function handleResize() {
        const newHeight = window.innerWidth >= 1200 ? "unset" : '400px';
        const newTableHeightOffset = window.innerWidth >= 1200 ? 90:90;
        setHeight(newHeight); 
        setTableHeightOffset(newTableHeightOffset)
    }

    handleResize(); //Set initial height
    window.addEventListener('resize', handleResize);

    return ()=> {
      window.removeEventListener('resize', handleResize);
    }
}, [])

  useEffect(()=>{
    if(Object.keys(filter).length > 0){
      setIsMeterListLoading(true)
      getMeterList()
    }
  },[filter])

  return (
    <>
      <Row style={{ height: "100%" }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          style={{ height: '100%' }}
        >
          <div
            style={{height: '100%', minHeight: '448px'}}
            ref={tableContainerRef}
          >
            {
              tableContainerRef.current? <CustomCard
                title="Device Lists"
                card_style={{ height: "100%" }}
                card_body_style={{height: "calc(100% - 46px)"}}
                title_style={{ padding: "8px 12px" }}
                children_style={{ padding: "0px", height: '100%', minHeight: height }}
                children={
                  <Spin
                    wrapperClassName="device-list-loading"
                    indicator={<LoadingOutlined spin />}
                    spinning={isMeterListLoading}
                    size="large"
                    style={{height: "100%"}}
                  >
                    {
                      isMeterListLoading? <></>:
                      <MeterListsTable 
                        meterList={meterList} 
                        filters={filters} 
                        tableContainerRef={tableContainerRef}
                        tableHeightOffset={tableHeightOffset}
                      />
                    }
                    
                  </Spin>
                }
                extra={
                  <FunctionalButton
                    buttonClickFunction={onDownloadButtonClicked}
                    trigger_loading={true}
                    type={'default'}
                    custom_button_style={{
                        backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                        border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                    }}
                  >
                    <DownloadOutlined/>
                  </FunctionalButton>
                }
              />:<></>
            }
          </div>
        </Col>
      </Row>
    </>
  );
}

export default MeterListsTableCard;
