import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Form } from 'antd';
import CustomFormItems from "../../common/CustomFormItems";
import FunctionalButton from "../../common/FunctionalButton";
import useGetInvoiceIdFilter from "../../../functions/Invoice/invoice_id_settings_func/getInvoiceIdFilter";
import { IndexContext } from '../../../context/IndexContext';

const { TabPane } = Tabs;

export default function InvoiceIDFilter({
    setInvoiceFilter
}) {
    const { isLightTheme } = useContext(IndexContext);
    const [InvoiceIDFilterForm] = Form.useForm();
    const {getInvoiceIdFilter, invoiceIdFilter} = useGetInvoiceIdFilter();
    const [formInvoiceSettingProperties, setFormInvoiceSettingProperties] = useState([]);


    useEffect(()=>{
        getInvoiceIdFilter()
    },[])
    
    useEffect(()=>{
        if(invoiceIdFilter != null){
            InvoiceIDFilterForm.setFieldsValue({
                'invoice_type': invoiceIdFilter.invoice_settings.invoice_type,
                'year': invoiceIdFilter.invoice_settings.year,
                'month': invoiceIdFilter.invoice_settings.month,
            })
            setFormInvoiceSettingProperties([
                {
                    type: 'select',
                    name: 'invoice_type',
                    label: 'Invoice Type',
                    options: invoiceIdFilter.invoice_type,
                },
                {
                    type: 'select',
                    name: 'year',
                    label: 'Year',
                    options: invoiceIdFilter.year_selection,
                },
                {
                    type: 'select',
                    name: 'month',
                    label: 'Month',
                    options: invoiceIdFilter.month_selection,
                }
            ])
            setInvoiceFilter(invoiceIdFilter.invoice_settings)
        }
    },[invoiceIdFilter])
    
    function testing(values){
        setInvoiceFilter(values)
    }

    return (
        <div style={{ padding: '12px' }}>
            <Form
                form={InvoiceIDFilterForm}
                onFinish={testing}        
            >
                <CustomFormItems 
                    form_item_style={{
                        marginBottom:'10px',
                    }}
                    label_style={{
                        textAlign: 'left'
                    }}
                    form_properties={formInvoiceSettingProperties}
                />
                <FunctionalButton 
                    htmlType={true}
                    active_button_click_func={false}
                    custom_button_style={{
                        marginBottom:'10px',
                        backgroundColor: isLightTheme? '#FFFFFF':'#282828',
                        border:isLightTheme?  '1px solid #D9D9D9':'1px solid #303030'
                    }}
                    children='Search Invoice ID'
                    type={'default'}
                />
            </Form>
        </div>
    );
};
