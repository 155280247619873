import { useContext, useState, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useUpdateMissingDataInvoices() {
  const [fetchWrapper] = useFetch();
  const [loading, setLoading] = useState(false);
  const { messageApi } = useContext(IndexContext);

  const updateMissingDataInvoices = useCallback(
    (missing_invoice_id, adjustment, invoiceFilter) => {
      setLoading(true);

      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "invoice/updateMissingDataInvoices",
          params: {
            missing_invoice_id: missing_invoice_id,
            adjustment: adjustment,
            invoice_filter: invoiceFilter,
          },
          onSuccess: (fetch_output) => {
            messageApi.open({
              type: "success",
              content: fetch_output.message,
            });
            setLoading(false);
            resolve(fetch_output);
          },
          onFailure: (error) => {
            messageApi.open({
              type: "error",
              content: error,
            });
            setLoading(false);
            reject(error);
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateMissingDataInvoices, loading };
}

export default useUpdateMissingDataInvoices;
