import {
  DashboardOutlined,
  SettingOutlined,
  FileTextOutlined,
  AlertOutlined,
  DesktopOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Flex } from "antd";

const header_height = 58;

export default function useNavMenu() {
  const NavMenu = [
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <DashboardOutlined style={{ marginRight: 12 }} />
          Dashboard
        </Flex>
      ),
      key: "Dashboard",
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <DesktopOutlined style={{ marginRight: 12 }} />
          Device Lists
        </Flex>
      ),
      key: "MeterLists",
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <TeamOutlined style={{ marginRight: 12 }} />
          Tenant Lists
        </Flex>
      ),
      key: "TenantLists",
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <FileTextOutlined style={{ marginRight: 12 }} />
          Invoices
        </Flex>
      ),
      key: "Invoice",
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <AlertOutlined style={{ marginRight: 12 }} />
          Alarms
        </Flex>
      ),
      key: "Alarms",
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 8, height: header_height }}
        >
          <SettingOutlined style={{ marginRight: 12 }} />
          Settings
        </Flex>
      ),
      key: "Settings",
    },
  ];

    return [NavMenu, header_height];
}
