import { useState, useEffect } from "react";
import DataTable from "../../../../common/DataTable";
import table_column from "./TableColumn";
import LotsManagementForm from "../LotsManagementForm";
import { Form, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function ActiveLotsTable({ setVisibleNotifyData, setVisibleNotify, setMoveOutModal, setMoveOutData, activeLotsData, activeLotsLoading}) {

  const columns = table_column({ setVisibleNotifyData, setVisibleNotify, setMoveOutModal, setMoveOutData });

  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateAvailableHeight = () => {
      setAvailableHeight(window.innerHeight - 280);
    };

    updateAvailableHeight();
    window.addEventListener('resize', updateAvailableHeight);
    return () => {
      window.removeEventListener('resize', updateAvailableHeight);
    };
  }, []);

  return (
    <>
      {
        activeLotsLoading == true ? 
        <div style={{height:availableHeight, display:'flex', justifyContent:'center', alignItems:'center'}}>
          <Spin
            wrapperClassName="device-list-loading"
            indicator={<LoadingOutlined spin />}
            spinning={true}
            size="large"
          ></Spin>        
        </div>
        : 
        <DataTable
          temp_table_height={620}
          pagination_style={{ margin: "10px" }}
          table_column={columns}
          table_data={activeLotsData}
        />
      }
    </>
  );
}
