import { useContext, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";

function useDownloadInvoice() {
    const [fetchWrapper] = useFetch();
    const { messageApi } = useContext(IndexContext);

    const downloadInvoice = useCallback((info) => {
        fetchWrapper({
            endpoint_url: "invoice/downloadInvoice",
            params: {
                invoice_id: info.invoice
            },
            json_output: false,
            onSuccess: (response) => {
                if (response.headers.get("content-type") === "application/json") {
                    response.json().then((data) => {
                        if (data.error) {
                            messageApi.open({
                                type: "error",
                                content: data.error || "Failed to download file"
                            });
                        } else {
                            messageApi.open({
                                type: "error",
                                content: "Failed to download file."
                            });
                        }
                    });
                } else {
                    response.blob().then((blob) => {
                        console.log(blob)
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = info.invoice + '.pdf'; // Ensure the file has the right extension
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    });
                }
            },
            onResponseError: (err) => {
                messageApi.open({
                    type: "error",
                    content: "Failed to download file"
                });
            }
        });
    }, [fetchWrapper, messageApi]);

    return { downloadInvoice };
}

export default useDownloadInvoice;
