import { useState, useEffect } from "react";
import StatisticCard from "../../../common/StatisticCard";
import { Row, Col } from "antd"
import { DollarOutlined, FileDoneOutlined, RiseOutlined, FallOutlined } from "@ant-design/icons";
import { trendIconType } from "../../../../functions/common/trendIconType";
import useGetStatisticInfo from "../../../../functions/Invoice/getStatisticInfo";

export default function StatisticInfo({
  filter,
  refresh
}) {

    const {getStatisticInfo, statisticInfo, isRunning} = useGetStatisticInfo();

    const current_data = [
        {
            title: "Total Bill Invoice Issued",
            title_icon: <DollarOutlined />,
            data: statisticInfo != null ? statisticInfo['total_bill_invoice_issues'] : []
        },
        {
            title: "Monthly Invoice Generation Quantity",
            title_icon: <FileDoneOutlined />,
            data: statisticInfo != null ? statisticInfo['monthly_invoice_generation_quantity'] : []
        },
        {
            title: "Current Site Monthly Invoice Generation Quantity",
            title_icon: <FileDoneOutlined />,
            data: statisticInfo != null ? statisticInfo['current_site_monthly_invoice_generation_quantity'] : []
        }
    ]
  
    function trendIconType(type){
        switch (type) {
          case 1:
            return (
              <RiseOutlined style={{color: "green", fontSize: "1.3rem"}}/>
            );
          case 2:
            return (
              <RiseOutlined style={{color: "red", fontSize: "1.3rem"}}/>
            );
          case 3:
            return (
              <FallOutlined style={{color: "green", fontSize: "1.3rem"}}/>
            );
          case 4:
            return (
              <FallOutlined style={{color: "red", fontSize: "1.3rem"}}/>
            );
          default:
            break;
        }
    }

    useEffect(()=>{
        getStatisticInfo(filter)
    },[filter, refresh])
    
    return (
        <>
            <Row gutter={[12,12]} >
                {
                    current_data.map((data, index) => {
                        return (
                            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                                <StatisticCard
                                    key={index}
                                    isLoading={isRunning}
                                    title={data.title}
                                    title_icon={data.title_icon}
                                    container_name={`current-energy-data-card-${index}`}
                                    chartData={data.chartData}
                                    isShowChart={true}
                                    chart_color={data.chartColor}
                                    main_content_list={[
                                        <h1 style={{margin: 0}}>{data.data.unit?? ' - '} {data.data.current?? ' - '}</h1>
                                    ]}
                                    sub_content_list={[
                                        trendIconType(data.data.trend),
                                        <>Last: {data.data.previous} {data.data.unit} ({data.data.percentage?? ' - '}%)</>
                                    ]}
                                    children_style = {{height:'120px'}}
                                    custom_card_title_style = {{ padding: '12px'}}
                                />
                            </Col>
                        )
                    })
                }
                
            </Row>
        </>
    )
}

