import React from 'react';
import { Card, Typography } from 'antd';
import PropTypes from 'prop-types'; // For type checking
import { useContext } from 'react';
import { IndexContext } from '../../context/IndexContext';
import { validateProp } from '../../hooks/useValidate'; // Adjust path as needed
import '../../styles/common/custom_card/card.css'; 

const { Text } = Typography;

export default function CustomCard({
    card_style = {},
    title,
    title_style = {},
    extra,
    extra_style = {},
    children,
    children_style = {},
    subtitles = [],
    sub_title_style = {},
    sub_title_children_style = {},
    sub_title_icon_style = {},
    sub_titles_wrapper_style = {},
    card_body_style = {}
}) {

    const { isLightTheme } = useContext(IndexContext);

    // Default styles

    const default_card_style = {
        width: '100%',
        height: 'auto',
        ...card_style
    }

    const default_title_style = {
        padding: '12px',
        fontSize: '14px',
        fontWeight: '500',
        color: isLightTheme ? '#000' : '#fff',
        backgroundColor: isLightTheme ? '#fff' : '#303030',
        borderRadius: 6,
        ...title_style
    };

    const default_title_icon_style = {
        color: default_title_style.color,
        fontSize: default_title_style.fontSize,
        fontWeight: default_title_style.fontWeight,
        ...extra_style 
    };

    const default_children_style = {
        padding: default_title_style.padding,
        ...children_style
    };

    const default_ub_title_style = {
        ...default_title_style,
        ...sub_title_style 
    };

    const default_sub_title_children_style = {
        ...{
            padding: default_ub_title_style.padding,
            ...sub_title_style 
        }, ...sub_title_children_style
    };

    const defaultSub_title_icon_style = {
        color: default_ub_title_style.color,
        fontSize: default_ub_title_style.fontSize,
        fontWeight: default_ub_title_style.fontWeight,
        ...sub_title_icon_style 
    };

    return (
        <Card
            id='custom_card'
            style={default_card_style}
            styles={{
                header:{
                    backgroundColor: default_title_style.backgroundColor, 
                    color: default_title_style.color 
                },
                body: {
                    ...card_body_style
                }
            }}
            title={
                <div 
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        ...default_title_style
                    }}
                >
                    <span>{title}</span>
                    <span style={default_title_icon_style}>{extra}</span>
                </div>
            }
        >   
            <div style={default_children_style}>
                {children}
            </div>

            {/* Render multiple subtitles */}
            {subtitles.map((subtitle, index) => (
                <div key={index} style={{...sub_titles_wrapper_style}}>
                    <div 
                        ref={subtitle.title_ref}
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'space-between', 
                            ...default_ub_title_style
                        }}
                    >
                        <span style={subtitle.title_style}>{subtitle.title}</span>
                        <span style={defaultSub_title_icon_style}>{subtitle.icon}</span>
                    </div>
                    {subtitle.children && (
                        <div style={default_sub_title_children_style}>
                            {subtitle.children}
                        </div>
                    )}
                </div>
            ))}
        </Card>
    );
}

// PropTypes validation
CustomCard.propTypes = {
    title: validateProp('node'),
    title_style: validateProp('style'),
    extra: validateProp('node'),
    extra_style: validateProp('style'),
    children: validateProp('node'),
    children_style: validateProp('style'),
    subtitles: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: validateProp('node'),
            children: validateProp('node'),
        })
    ),    
    sub_title_style: validateProp('style'),
    sub_title_children_style: validateProp('style'),
    sub_title_icon_style: validateProp('style'),
    sub_titles_wrapper_style: validateProp('style'),
    card_body_style: validateProp('style'),
};