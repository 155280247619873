import React, { useState } from 'react';
import FunctionalButton from "../../../common/FunctionalButton";

const TableColumns = ({setVisible, visible, setVisibleData, isLightTheme}) => {

    const showDetails = (info) => {
        setVisible(true)
        setVisibleData(info)
    };

    const table_columns = [
        {
            title: 'Tenant Name',
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            sorter: (a, b) => a.tenant_name.localeCompare(b.tenant_name)
        },
        {
            title: 'Lot',
            dataIndex: 'lot',
            key: 'lot',
            sorter: (a, b) => a.lot.localeCompare(b.lot)
        },
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period'
        },
        {
            title: 'Previous Month (kWh)',
            dataIndex: 'previous_month',
            key: 'previous_month',
            sorter: (a, b) => a.previous_month.localeCompare(b.previous_month)
        },
        {
            title: 'Current Month (kWh)',
            dataIndex: 'current_month',
            key: 'current_month',
            sorter: (a, b) => a.current_month.localeCompare(b.current_month)
        },
        {
            title: 'Energy Consumption',
            dataIndex: 'energy_consumption',
            key: 'energy_consumption',
            sorter: (a, b) => a.energy_consumption.localeCompare(b.energy_consumption)
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: '200px',
            render: (info) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <FunctionalButton
                            buttonClickFunction={() => showDetails(info)}
                            custom_button_style={{ 
                                marginRight: '10px', 
                                width: '150px',
                                background:isLightTheme? "#FFFFFF":"#141414",
                                color: isLightTheme? "#000000":"#ffffff",
                                borderColor: isLightTheme? "#D9D9D9":"#424242",
                                boxShadow: "unset"
                            }}
                        >
                            Confirm Invoice
                        </FunctionalButton>
                    </div>
                )
            }
        }
    ];

    return table_columns;
};

export default TableColumns;
