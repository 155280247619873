
import { Row, Col } from 'antd';
import { useState, useEffect, useContext } from "react";
import table_column from "./TableColumn"
import DataTable from "../../../common/DataTable";
import CustomModal from "../../../common/CustomModal";
import CustomCard from "../../../common/CustomCard";
import FunctionalButton from "../../../common/FunctionalButton";
import useDownloadInvoice from "../../../../functions/Invoice/invoice_generation_func/downloadInvoice";
import useRegenerateInvoice from "../../../../functions/Invoice/invoice_generation_func/updateRenegerateInvoice";
import { IndexContext } from '../../../../context/IndexContext';

export default function ManualInvoicesTable({
    setSelectedRows,
    manualGeneratedInvoices,
    setRefresh
}) {
    const [visible, setVisible] = useState(false);
    const [visibleData, setVisibleData] = useState({});
    const { isLightTheme } = useContext(IndexContext);

    const {regenerateInvoice} = useRegenerateInvoice();
    const {downloadInvoice} = useDownloadInvoice();
    
    function reGeneratedInvoice(info){
        return new Promise((resolve, reject) => {
            regenerateInvoice(info).then(()=>{
                resolve()
                setRefresh(true)
            })
            .catch(()=>{
                reject()
            });
        });
    }

    useEffect(()=>{
        setSelectedRows([])
    },[])
    
    const columns = table_column({ setVisible, visible, setVisibleData, downloadInvoice, reGeneratedInvoice, isLightTheme });

    const handleCancel = () => {
        setVisible(false);
    };
  
    const [accountSummary, setAccountSummary] = useState()
    const [invoiceDetails, setInvoiceDetails] = useState()
    const [energyDetails, setEnergyDetails] = useState()
    const [pricingDetails, setPricingDetails] = useState()

    useEffect(()=>{
        setAccountSummary(visibleData.account_summary)
        setInvoiceDetails(visibleData.invoice_details)
        setEnergyDetails(visibleData.energy_details)
        setPricingDetails(visibleData.pricing_details)
    },[visibleData])

    return (
        <>
            <DataTable temp_table_height={440} pagination_style={{ margin:'10px' }} tableRowSelection={true} selection_row={setSelectedRows} table_column={columns} table_data={manualGeneratedInvoices}/>
            <CustomModal
                visible={visible}
                onCancel={handleCancel}
                title={
                    <h4 style={{ margin: '0px', fontWeight: '500' }}>{visibleData.invoice}</h4>
                }
                title_style={{
                    background: '#1F1F1F'
                }}
                modal_style={{
                    marginTop: ''
                }}
                content={
                    <CustomCard
                        title='Account Summary'
                        card_style={{ height: '100%' }}
                        title_style={{ background: '#303030', padding: '6px 16px', borderRadius: '0px', fontSize: '16px', fontWeight: '400' }}
                        children_style={{ padding: '3px 16px', background: '#141414' }}
                        children={
                            <Row>
                                {
                                    accountSummary != null ?
                                    accountSummary.map((each_value)=>{
                                        return (
                                            <Row style={{ width: '100%', padding: '3px 0px' }}>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <h3 style={{ margin: '0px', fontWeight: '400' }}>{each_value.title} :</h3>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                    <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{each_value.value}</h3>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                    :
                                    ''
                                }
                            </Row>
                        }
                        subtitles={[
                            {
                                title: 'Invoice Details',
                                children:
                                    <Row>
                                        {
                                            invoiceDetails != null ?
                                            invoiceDetails.map((each_value)=>{
                                                return (
                                                    <Row style={{ width: '100%', padding: '3px 0px' }}>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400' }}>{each_value.title} :</h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{each_value.value}</h3>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                            :
                                            ''
                                        }
                                    </Row>
                            },
                            {
                                title: 'Energy Details',
                                children:
                                    <Row>
                                       {
                                            energyDetails != null ?
                                            energyDetails.map((each_value)=>{
                                                return (
                                                    <Row style={{ width: '100%', padding: '3px 0px' }}>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400' }}>{each_value.title} :</h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{each_value.value}</h3>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                            :
                                            ''
                                        }
                                    </Row>
                            },
                            {
                                title: 'Pricing Details',
                                children:
                                    <Row>
                                       {
                                            pricingDetails != null ?
                                            pricingDetails.map((each_value)=>{
                                                return (
                                                    <Row style={{ width: '100%', padding: '3px 0px' }}>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400' }}>{each_value.title} :</h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                            <h3 style={{ margin: '0px', fontWeight: '400', textAlign: 'right' }}>{each_value.value} {each_value.unit}</h3>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                            :
                                            ''
                                        }
                                    </Row>
                            }
                        ]}
                    />
                }
                content_style={{
                    padding: '0px'
                }}
                footer_style={{
                    padding: '10px 10px',
                }}
                footer={
                    <div style={{ display: 'flex' }}>
                        <FunctionalButton
                            buttonClickFunction={handleCancel}
                            custom_button_style={{ marginRight: '10px', width: '100px', padding: '3px 5px', marginRight: '6px' }}
                        >
                            Close
                        </FunctionalButton>
                    </div>
                }
            />
        </>
    )
}

