import FunctionalButton from "../../../../common/FunctionalButton";

const TableColumns = ({ setVisibleNotifyData, setVisibleNotify, setMoveOutModal, setMoveOutData }) => {
  const moveOut = (info) => {
    setMoveOutModal(true);
    setMoveOutData(info);
  };

  const unpairingTenant = (info) => {
    setVisibleNotify(true)
    setVisibleNotifyData({
      'title': 'Unpairing Tenant Confirmation',
      'type': 'unpairing_tenant',
      'content': 
        <div style={{ margin: '0px' }}>
          <h3 style={{ margin: '0px', fontWeight:'400', paddingBottom:'10px', fontSize:'17px', borderBottom:'1px solid #303030'}}>Are you sure you want to cancel this tenant move out? This action may issues this tenant cannot generated the invoice.</h3>
          <h3 style={{ margin: '0px', fontWeight:'400', marginTop:'10px'}}><strong>Tennat Name: </strong> {info.tenant_name}</h3>
          <h3 style={{ margin: '0px', fontWeight:'400', marginTop:'10px'}}><strong>Lot Name: </strong> {info.lot_id}</h3>
          <h3 style={{ margin: '0px', fontWeight:'400', marginTop:'10px'}}><strong>Meter Name: </strong> {info.meter_id}</h3>
        </div>,
      'key': info.key
    })
  }

  const cancelMoveOut = (info) => {
    setVisibleNotify(true)
    setVisibleNotifyData({
      'title': 'Cancel Move Out Confirmation',
      'type': 'move_out_cancel',
      'content': 
        <div style={{ margin: '0px' }}>
          <h3 style={{ margin: '0px', fontWeight:'400', paddingBottom:'10px', fontSize:'17px', borderBottom:'1px solid #303030'}}>Are you sure you want to cancel this tenant move out?</h3>
          <h3 style={{ margin: '0px', fontWeight:'400', marginTop:'10px'}}><strong>Tennat Name: </strong> {info.tenant_name}</h3>
        </div>,
      'key': info.key
    })
  }

  const table_columns = [
    {
      title: "Lot ID",
      dataIndex: "lot_id",
      key: "lot_id",
    },
    {
      title: "Meter ID",
      dataIndex: "meter_id",
      key: "meter_id",
    },
    {
      title: "Tenant Name",
      dataIndex: "tenant_name",
      key: "tenant_name",
    },
    {
      title: "Rent Start Date",
      dataIndex: "tenant_rent_start_date",
      key: "tenant_rent_start_date",
    },
    {
      title: "Rent End Date",
      dataIndex: "tenant_rent_end_date",
      key: "tenant_rent_end_date",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (info, record) => {
        return (
          <div style={{display:'flex'}}>
            <FunctionalButton
              buttonClickFunction={() => unpairingTenant(record)}
              custom_button_style={{ width: "150px", marginRight:'10px'}}
              children="Unpairing Tenant"
            />
            {
              record.tenant_rent_end_date == '-'
              ?
              <FunctionalButton
                buttonClickFunction={() => moveOut(record)}
                custom_button_style={{ width: "150px" }}
                children="Move Out"
              />
              :
              <FunctionalButton
                buttonClickFunction={() => cancelMoveOut(record)}
                custom_button_style={{ width: "150px" }}
                children="Cancel Move Out"
              />
            }
          </div>
        );
      },
    },
  ];

  return table_columns;
};

export default TableColumns;
